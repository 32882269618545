import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";
import config from "../../aws-exports";
import API from "@aws-amplify/api";
import { makeStyles } from "@material-ui/core/styles";
import avatar1 from "../../assets/media/homepage.jpg";
import Slider from "react-slick";
import { Button, Grid, Paper, Box, InputBase, IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SendIcon from '@mui/icons-material/Send';
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import Person2Icon from "@mui/icons-material/Person2";
import PostFooterView from "../../components/SocialLearning/PostFooterView";
import PostHeaderView from "../../components/SocialLearning/PostHeaderView";
import SearchHeader from "../../components/SocialLearning/SearchHeader";
import Polling from "../../components/SocialLearning/Polling/Polling";
import Cookies from "js-cookie";
import { useHistory, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CardMedia, CircularProgress, Backdrop } from '@mui/material';
import { callAPI, } from "../../api/api";
import { ReactComponent as UserProfile } from "../../assets/svg/User_Profile.svg";
import { ReactComponent as UserPhoto } from "../../assets/svg/Photos.svg";
import { ReactComponent as UserDoc } from "../../assets/svg/Document (1).svg";
import { ReactComponent as UserPoll } from "../../assets/svg/Poll.svg";
import { ReactComponent as UserVideo } from "../../assets/svg/Video.svg";
import { ReactComponent as HotFalme } from "../../assets/svg/Hot.svg";
import { ReactComponent as MyPosts } from "../../assets/svg/My_Posts.svg";
import Swal from "sweetalert2";
import { Theme } from "../../aws-exports";
import { awsSignIn } from "../../redux/auth/authSlice";

function SocialLearning() {
  const history = useHistory();
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((themeStyle) => ({
    root: {
      
    },
    mainLayout: {
      margin: "0rem 12rem",
      "@media (max-width: 900px)": {
        margin: "0rem",
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    loaderSkeleton: {
      width: "500px",
      height: "5rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    loaderSkeletonSlider: {
      width: "100%",
      height: "45rem !important",
    },

    mainSlider: {
      margin: "3rem 0rem",
      height: "15rem",
      "& > button:before": {
        color: userDetails.themeDark === "dark" ? "white!important" : "gray!important",
        fontSize: "3rem",
      },
      "& > ul > li > button:before": {
        color: userDetails.themeDark === "dark" ? "white!important" : "gray!important",
        fontSize: "1rem",
      },
      "& > div > div": {
        margin: "unset",
        width: "100%!important",
        "& > div": {
          marginRight: "5rem!important",
        },
      },
    },
    postSlider: {
      marginBottom: "3rem",
    },
    centered: {
      position: "absolute",
      bottom: "20%",
      left: "5rem",
      color: "white",
      width: "23rem",
      display: "grid",
      gap: "0.5rem",
    },
    sliderData: {
      position: "relative",
    },
    buttonPrimary: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.7rem!important",
      textTransform: "capitalize!important",
    },
    sliderImage: {
      width: "25rem",
      filter: "brightness(0.8)",
      borderRadius: "1rem",
      position: "relative",
      left: "3rem",
    },
    actionButton: {
      backgroundColor: userDetails.themeDark === "dark" ? "#2c2e31!important" : "#EBEBEB!important",
      borderRadius: "2rem!important",
      boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
      margin: "1rem!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      color: userDetails.themeDark === "dark" ? "white!important" : "black !important",
      gap: "1rem",
      padding: "0.5rem 1rem!important",
      border: userDetails.themeDark === "dark" ? "1px solid gray!important" : "1px solid lightgray!important",
      "&:active": {
        color: "var(--color-button-background) !important",
        borderRadius: "1.5rem !important",
        fontSize: "1.5rem !important",
        backgroundColor: "aliceblue !important",
        gap: "1rem !important",
        margin: "1rem !important",
        boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3) !important",
      },
      "& > svg": {
        fontSize: "3.5rem",
        color: userDetails.themeDark === "dark" ? "white!important" : "black",
        "&:active": {
          color: "var(--color-button-background)!important",
        },
      },
    },
    activeButton: {
      color: userDetails.themeDark === "dark" ? "white!important" : "var(--color-button-background) !important",
      borderRadius: "1.5rem !important",
      fontSize: "1.5rem !important",
      backgroundColor: userDetails.themeDark === "dark" ? "#d0d0d094!important" : "white",
      gap: "1rem !important",
      margin: "1rem !important",
      boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3) !important",
      "& > svg": {
        fill: userDetails.themeDark === "dark" ? "white!important" : "var(--color-button-background) !important",
      },
    },
    actionButtonTag: {
      backgroundColor: "gray!important",
      borderRadius: "3rem!important",
      padding: "0px 10px!important",
      fontSize: "1rem!important",
      textTransform: "capitalize!important",
      color: "white!important",
      "&:active": {
        color: "var(--color-button-background)!important",
      },
    },
    actionButtonFollow: {
      borderRadius: "3rem!important",
      border: "2px solid!important",
      borderColor: "var(--color-button-background)!important",
      backgroundColor: "transparent!important",
      margin: "0!important",
      padding: "0px 10px!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      "&:active": {
        color: "var(--color-button-background)!important",
      },
    },
    footerActiveButton: {
      color: "var(--color-button-background) !important",
      borderRadius: "1.5rem !important",
      fontSize: "1.5rem !important",
      backgroundColor: "transparent!important",
      gap: "1rem !important",
      "& > svg": {
        fill: userDetails.themeDark === "dark" ? "white" : "var(--color-button-background)",
        width: "3rem"
      },
    },
    myPostButton: {
      display: "flex!important",
      justifyContent: "space-between!important",
      margin: "1.7rem 0rem!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      color:
        userDetails.themeDark === "dark"
          ? "white!important"
          : "black!important",
      width: "100%",
      "& > div > svg:first-child": {
        fontSize: "3rem",
        color:
          userDetails.themeDark === "dark"
            ? "white!important"
            : "black!important",
      },
      "& > svg": {
        fontSize: "3rem",
        color: "var(--color-button-background)!important",
      },
    },
    sliderImageFull: {
      width: "100%",
      objectFit: 'contain',
      height: '315px',
      cursor: "pointer",
    },
    firstTrend: {
      color: "black!important",
      padding: "1rem",
      backgroundColor: "#EBEBEB",
    },
    showMoreSection: {
      padding: "1rem",
      backgroundColor: userDetails.themeDark === "dark" ? "transparent" : "#EBEBEB",
      display: "flex",
      "& > a": {
        textDecoration: "none", color: "var(--color-button-background)!important",
      },
    },
    socialIcons: {
      fontSize: "2.5rem!important",
      color:
        userDetails.themeDark === "dark"
          ? "white!important"
          : "black!important",
    },
    leftPaperBottom: {
      width: "100%",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none!important"
          : "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
      margin: "1rem 0!important",
      padding: "2rem",
    },
    rightPaper: {
      width: "100%",
      margin: "1rem 0rem!important",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none!important"
          : "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
    },
    userMenuItems: {
      display: "flex",
      justifyContent: "space-between",
      margin: "2rem 0 0!important",
      flexWrap: "wrap",
    },
    userMenuButton: {
      fontSize: "1.4rem !important",
      textTransform: "capitalize !important",
      gap: "0.5rem",
      color: "gray",
    },
    secondGridResponsive: {
      padding: "1rem 0rem 1rem 1rem",
      "@media (max-width: 900px)": {
        padding: "1rem 1rem 1rem 0rem!important",
      },
    },
    backdrop: {
      zIndex: themeStyle.zIndex.drawer + 1,
      color: "#fff",
    },
    sendButton: {
      backgroundColor: "var(--color-button-background)",
      color: "white",
      borderRadius: "5px",
      fontSize: "2rem!important",
      padding: "4px",
      width: "5.5rem!important",
      height: "4rem!important",
    },
    actionButtonsSections: {
      display: "flex",
      gap: "3rem",
      padding: "1rem 2rem",
      width: "100%",
      flexWrap: "wrap",
      "& > button": {
        textTransform: "capitalize",
        fontSize: "1.4rem!important",
        gap: "10px!important",
        color: userDetails.themeDark === "dark" ? "white!important" : "gray!important",
      },
      "& > button > svg": {
        width: "3.5rem",
        height: "3.5rem",
      },
    },
    personIcon: {
      fontSize: "3rem!important",
      boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3) !important",
      borderRadius: "3rem",
      padding: "4px",
    },
    formStyle: {
      width: "80%",
      display: "grid",
      margin: "1rem 0",
      border: "2px solid #E7E7E7",
      borderRadius: "5px",
      "& > h4": {
        padding: "1rem 3rem",
        backgroundColor: "#F2F2F2",
      },
    },
    saveIconsSVG: {
      width: "3rem", height: "3rem",
      fill:
        userDetails.themeDark === "dark"
          ? "white "
          : "black",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({ mainButton: 'Hot' });
  const [setting2, setSetting2] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
  });
  const [mainLoader, setMainLoader] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  
  const fetchTimelineHotPosts = async () => {
    try {

      if (userDetails === 0) {
        window.location.href = config.aws_cloudfront_lurl;
      }
      const bodyParam = {
        queryStringParameters: {
          schema: config.schema,
          offset: offset,
          limit: 1000,
          ur_id: userDetails?.uData?.ur_id,
          oid: config.aws_org_id
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      };

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        `/gettimelinehotposts`,
        bodyParam
      );
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", response.Policy);
      Cookies.set("CloudFront-Signature", response.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);

      return response;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.data = err;
      return obj;
    }
  };
  const fetchTopTrendingToday = async () => {
    try {

      if (userDetails === 0) {
        window.location.href = config.aws_cloudfront_lurl;
      }
      const bodyParam = {
        body: {
          schema: config.schema,
          offset: offset,
          limit: 50
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      };

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        `/gettoptrendingtopics?schema=${config.schema}`,
        bodyParam
      );
      if (response.body.length < 3) {
        setting2.slidesToShow = 3;
        setSetting2(setting2);
      } else if (response.body.length > 3) {
        setting2.slidesToShow = 4;
        setSetting2(setting2);
      }
      return response;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.body = [];
      return obj;
    }
  };
  const fetchunfollowusers = async () => {
    try {
      if (userDetails === 0) {
        window.location.href = config.aws_cloudfront_lurl;
      }
      const bodyParam = {
        queryStringParameters: {
          schema: config.schema,
          offset: offset,
          limit: 2,
          ur_id: userDetails?.uData?.ur_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      };

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        `/getUnFollowUsers`,
        bodyParam
      );
      return response;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.body = [];
      return obj;
    }
  };
  const fetchDataOnButtonClick = async () => {
    try {
      let obj = {};
      obj.method = 'get';
      obj.apiName = `/gettimelinenewposts?ur_id=${userDetails?.uData?.ur_id}&schema=${config.schema}&offset=${offset}&limit=3&oid=${config.aws_org_id}`;
      obj.body = {};
      obj.body.offset = offset;
      obj.body.limit = 1000;
      const res = await callAPI(obj,userDetails);
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", res.Policy);
      Cookies.set("CloudFront-Signature", res.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", res.KeyPairId);
      return res;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.data = err;
      return obj;
    }
  }
  const callAPIFunction = async (ur_id) => {
    try {
      let obj = {};
      obj.method = 'post';
      obj.body = {};
      obj.body.ur_id = userDetails?.uData?.ur_id;
      obj.body.schema = config.schema;
      obj.body.follow_ur_id = ur_id;
      obj.apiName = `/createUserFollow`;
      const res = await callAPI(obj,userDetails);
      return res;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.message = err.message;
      return obj;
    }
  }
  const { isLoading, error, refetch: hotRefeth, data, isFetching } = useQuery(
    {
      queryKey: ['gettimelinehotposts', userDetails?.uData?.ur_id],
      queryFn: () => fetchTimelineHotPosts(),
      keepPreviousData: true,
    });
  const { isLoading: load, error: err, data: buttonClickData, refetch } = useQuery({
    queryKey: ['buttonClickData', userDetails?.uData?.ur_id],
    queryFn: fetchDataOnButtonClick,
  });


  const { isLoading: topicLoad, error: topicErr, data: topTrendingData, refetch: refetchTopTrending } = useQuery({
    queryKey: ['topTrending', userDetails?.uData?.ur_id],
    queryFn: fetchTopTrendingToday,
  });
  const { isLoading: unFollowUsersLoad, error: unFollowUsersErr, data: unFollowUsersData, refetch: refetchUnFollowUsers } = useQuery({
    queryKey: ['unfollowusers', userDetails?.uData?.ur_id],
    queryFn: fetchunfollowusers,
  });

  console.log("unFollowUsersData", unFollowUsersData);

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

  };
  const handleMainButton = async (val) => {
    setMainLoader(true);
    setValues(prevValues => ({
      ...prevValues, // Spread the previous state
      mainButton: val, // Update the age property
    }));
    setOffset(0);
    hotRefeth();
    refetch();
    setButtonClicked(val === 'New');
    setMainLoader(false);
  }
  const handleFollow = async (ur_id) => {
    setMainLoader(true);
    let res = await callAPIFunction(ur_id);
    Swal.fire({
      title: res.statusCode === 200 ? "Success" : "Error",
      text: res.message,
      icon: res.statusCode === 200 ? "success" : "error",
      confirmButtonColor: Theme.color.primary,
      confirmButtonText: "Ok",
    });
    refetchUnFollowUsers();
    setMainLoader(false);
  }

  return (
    <>
      <SearchHeader />
      <div className={classes.mainLayout}>
        {!topicLoad && topTrendingData && topTrendingData.body.length > 0 && <div className={classes.mainContainer}>
          <h3 style={{ fontWeight: "600", marginTop: "3rem", colour: "#727272" }}>
            <>Topics Trending Today</>
          </h3>
        </div>}
        {mainLoader ? (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
        {loading ? (
          <Skeleton className={classes.loaderSkeletonSlider} />
        ) : (
          <div className={classes.root}>
            {!topicLoad && topTrendingData && topTrendingData.body.length > 0 && <Slider className={classes.mainSlider} {...setting2}>
              {topTrendingData.body.map((item) => (
                <div className={classes.sliderData}>
                  <img className={classes.sliderImage} src={avatar1} alt="" />
                  <div className={classes.centered}>
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
            </Slider>
            }
            <Button variant="contained" className={classes.buttonPrimary}
              onClick={() => history.push("/social-learning/viewAllTopic")}>
              View all topics{" "}
              <ChevronRightIcon
                style={{ fontSize: "2.2rem", marginLeft: "0.6rem" }}
              />
            </Button>
            <div>

              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Grid container spacing={2} style={{ margin: "0", padding: "0" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    style={{ padding: "1rem 1rem 1rem 0rem" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          height: "auto",
                        },
                      }}
                    >
                      <Paper elevation={3} className={classes.leftPaperBottom}>
                        <Button style={{ margin: "0.5rem" }}
                          onClick={() => {
                            history.push("/social-learning/create-post");
                          }}
                        >
                          <UserProfile />
                        </Button>
                        <Button
                          onClick={() => {
                            history.push("/social-learning/create-post");
                          }}
                          style={{
                            borderRadius: "4px",
                            color: "gray",
                            fontWeight: "400",
                            width: "85%",
                            fontSize: "1.5rem",
                            textTransform: "capitalize",
                            justifyContent: "space-between",
                          }}
                        >
                          What's on your innovative mind?
                          <SendIcon className={classes.sendButton} />
                        </Button>
                        <div className={classes.actionButtonsSections}>
                          <Button onClick={() => {
                            history.push("/social-learning/create-post?Photos");
                          }} ><UserPhoto /><p>Photos</p></Button>
                          <Button onClick={() => {
                            history.push("/social-learning/create-post?Videos");
                          }}><UserVideo /><p>Videos</p></Button>
                          <Button onClick={() => {
                            history.push("/social-learning/create-post?Docs");
                          }}><UserDoc /><p>Docs</p></Button>
                          <Button onClick={() => {
                            history.push("/social-learning/create-post?Poll");
                          }}><UserPoll /><p>Poll</p></Button>
                        </div>
                      </Paper>

                      <Paper elevation={3} className={classes.leftPaperBottom}>
                        <Button className={`${classes.actionButton} ${values.mainButton === "Hot" ? classes.activeButton : ""}`}
                          onClick={() => handleMainButton('Hot')}>
                          <HotFalme style={{ width: "3.5rem", height: "3.5rem", }} /> Hot
                        </Button>
                        
                        <Button className={`${classes.actionButton} ${values.mainButton === "New" ? classes.activeButton : ""}`}
                          onClick={() => handleMainButton('New')}>
                          <ArrowCircleUpIcon /> New
                        </Button>
                        
                      </Paper>
                      {isLoading && <>
                        <Paper elevation={3} className={classes.leftPaperBottom}>
                          <Skeleton className={classes.loaderSkeletonSlider} />
                        </Paper>
                      </>}
                      {!isLoading && (buttonClicked ? buttonClickData.body : data.body).map((row, ind) => (
                        row.posttypeid === 3 ? (<Paper elevation={3} key={ind} className={classes.leftPaperBottom}>
                          <PostHeaderView row={row} classes={classes} ind={ind}
                            userDetails={userDetails} hotRefeth={hotRefeth} refetch={refetch} setMainLoader={setMainLoader} />
                          {row.json_attachment && <Slider className={classes.postSlider} {...settings1}>
                            {row.json_attachment?.map((item) => (
                              <div className={classes.sliderData}>
                                {item.filetype.includes('pdf') &&
                                  <CardMedia
                                    alt="GeeksforGeeks"
                                    component="iframe"
                                    width="100%"
                                    height="315px"
                                    title={item.filename}
                                    allowfullscreen
                                    src={item.ourl + "#navpanes=0&page=1&view=FitH,top"}
                                    className={classes.documentSection}
                                  />
                                }
                                 {item.filetype.includes('video')  &&
                                <video width="inherit" height="300" controls>
                                  <source src={item.ourl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                              }
                                {item.filetype.includes('image') &&
                                  <img
                                    className={classes.sliderImageFull}
                                    src={item.ourl}
                                    alt=""
                                    onClick={() => {
                                      let udata = { ...userDetails };
                                      udata.postDataByid = row;
                                      dispatch(awsSignIn(udata));
                                      window.location.href = "#/social-learning/InDetails";
                                    }}
                                  />
                                }
                              </div>
                            ))}
                          </Slider>}
                          <Paper
                            component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "4px",
                              boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
                              color: "gray",
                              fontSize: "1.5rem",
                            }}
                          >
                            <Button
                              onClick={() => {
                                let udata = { ...userDetails };
                                udata.postDataByid = row;
                                dispatch(awsSignIn(udata));
                                history.push("/social-learning/InDetails");
                              }}
                              style={{
                                borderRadius: "4px",
                                color: "gray",
                                fontWeight: "400",
                                fontSize: "1.5rem",
                                textTransform: "capitalize",
                                justifyContent: "left",
                                width: "100%"
                              }}
                            >
                              <IconButton sx={{ p: "10px" }} aria-label="menu">
                                <Person2Icon
                                  style={{ fontSize: "2.5rem", color: "gray" }}
                                />
                              </IconButton>
                              Write your comment...
                            </Button>

                          </Paper>
                          <PostFooterView row={row} classes={classes} userDetails={userDetails}
                            hotRefeth={hotRefeth} history={history} setMainLoader={setMainLoader} />
                        </Paper>) : row.posttypeid === 2 ? (<Paper elevation={3} className={classes.leftPaperBottom}>
                          <PostHeaderView row={row} classes={classes} ind={ind} userDetails={userDetails}
                            hotRefeth={hotRefeth} refetch={refetch} setMainLoader={setMainLoader} />
                          <div
                            style={{
                              display: "block",
                              margin: "2rem 0rem",
                              textDecoration: "none",
                            }}
                          >
                            <p style={{ marginBottom: "2rem", overflowWrap: "break-word" }}>
                              {row.content}
                            </p>
                          </div>
                          <Paper
                            component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "4px",
                              boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
                              color: "gray",
                              fontSize: "1.5rem",
                            }}
                          >
                            <Button
                              onClick={() => {
                                let udata = { ...userDetails };
                                udata.postDataByid = row;
                                dispatch(awsSignIn(udata));
                                history.push("/social-learning/InDetails");
                              }}
                              style={{
                                borderRadius: "4px",
                                color: "gray",
                                fontWeight: "400",
                                fontSize: "1.5rem",
                                textTransform: "capitalize",
                                justifyContent: "left",
                                width: "100%"
                              }}
                            >
                              <IconButton sx={{ p: "10px" }} aria-label="menu">
                                <Person2Icon
                                  style={{ fontSize: "2.5rem", color: "gray" }}
                                />
                              </IconButton>
                              Write your comment...
                            </Button>

                          </Paper>
                          <PostFooterView row={row} classes={classes} userDetails={userDetails}
                            hotRefeth={hotRefeth} history={history} setMainLoader={setMainLoader} />
                        </Paper>) : row.posttypeid === 1 ? (<Paper elevation={3} className={classes.leftPaperBottom}>
                          
                          <PostHeaderView row={row} classes={classes} ind={ind} userDetails={userDetails}
                            hotRefeth={hotRefeth} refetch={refetch} setMainLoader={setMainLoader} />
                          <p>{row.content}</p>
                          <div className={classes.formStyle}>
                         
                            <Polling row={row} ind={ind} userDetails={userDetails}
                              hotRefeth={hotRefeth} refetch={refetch} setMainLoader={setMainLoader} />
                          </div>
                          <Paper
                            component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "4px",
                              boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
                              color: "gray",
                              fontSize: "1.5rem",
                            }}
                          >
                            <Button
                              onClick={() => {
                                let udata = { ...userDetails };
                                udata.postDataByid = row;
                                dispatch(awsSignIn(udata));
                                history.push("/social-learning/InDetails");
                              }}
                              style={{
                                borderRadius: "4px",
                                color: "gray",
                                fontWeight: "400",
                                fontSize: "1.5rem",
                                textTransform: "capitalize",
                                justifyContent: "left",
                                width: "100%"
                              }}
                            >
                              <IconButton sx={{ p: "10px" }} aria-label="menu">
                                <Person2Icon
                                  style={{ fontSize: "2.5rem", color: "gray" }}
                                />
                              </IconButton>
                              Write your comment...
                            </Button>

                          </Paper>
                          <PostFooterView row={row} classes={classes} userDetails={userDetails}
                            hotRefeth={hotRefeth} history={history} setMainLoader={setMainLoader} />
                        </Paper>) : (<></>)

                      ))}

                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className={classes.secondGridResponsive}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          height: "auto",
                        },
                      }}
                    >
                      <Paper elevation={3} className={classes.rightPaper}>
                        <Button
                          className={classes.myPostButton}
                          onClick={() => history.push("/social-learning/MyPost")}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "1rem",
                              fontSize: "1.8rem",
                              fontWeight: "700",
                            }}
                          >
                            <MyPosts style={{ width: "3.5rem", height: "3.5rem", }} /> My Posts
                          </div>{" "}
                          <ChevronRightIcon />
                        </Button>
                      </Paper>
                     
                      {!unFollowUsersLoad && unFollowUsersData && unFollowUsersData.body.length > 0 && <Paper elevation={3} className={classes.rightPaper}>
                        <h4 style={{ padding: "1rem", borderBottom: "3px solid lightgray" }}>Follow People</h4>
                        {unFollowUsersData.body.map((item) => (
                          <div
                            style={{
                              padding: "1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderBottom: "3px solid lightgray"
                            }}
                          >
                            <Person2Icon className={classes.personIcon} />
                            <div style={{ width: "50%" }}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <h4>{item.first_name}</h4>
                              </div>
                              <p>{item.full_name}</p>
                            </div>
                            <Button className={classes.actionButtonFollow}
                              onClick={() => handleFollow(item.ur_id)}>
                              Follow
                            </Button>
                          </div>
                        ))}
                        <div className={classes.showMoreSection}>
                          <Link to="/social-learning/FollowPeople"> Show more </Link>
                          <ChevronRightIcon style={{ fontSize: "2.2rem", marginLeft: "0.4rem", color: "var(--color-button-background)" }} />
                        </div>
                      </Paper>}
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default SocialLearning;
