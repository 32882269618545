import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  CircularProgress,
  IconButton,
  Box,
} from "@material-ui/core";

//import useMediaQuery from "@material-ui/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
//import { StyledButtons } from "./RegisterForm/RegisterFrom";
import { Theme } from "../../aws-exports";
import downloadPdfSvg from "../../components/svg/downloadpdf.png";
import CloseIcon from "@material-ui/icons/Close";
//import axios from "axios";
//import { saveAs } from "file-saver";
import config from "../../aws-exports";

import "../../components/Styles/clearfix.css";
import ENABLEDCERT from "../../assets/ENABLEDCERT.png";

//import signature from "../../assets/signature.png";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import "./certificate.scss";
import moment from "moment";
import ENABLED from "../../assets/ENABLED.png";
import EXCELSOFT from "../../assets/EXCELSOFT.png";
import NSECORPCERT from "../../assets/NSECORPCERT.png";
import AXIATA from "../../assets/AXIATA.png";
import ENSIGN from "../../assets/ENSIGN.png";
import EXSIGN from "../../assets/EXSIGN.png";
import AXSIGN from "../../assets/AXSIGN.png";
import { makeStyles } from "@material-ui/core/styles";
//import * as htmlToImage from "html-to-image";
//mport Pdf from "react-to-pdf";
import { API, loadingBar } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactToPdf from "react-to-pdf";
import "../ProfilePopUp/ProfilePopUp.scss";
import NSECertificate from "../../assets/NSC Sample.png";
import FeedbackPopUp from "./FeedbackPopUp";
import { LinkedinShareButton, LinkedinIcon } from "react-share";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const options = {
  orientation: "landscape",
};

//import { Box } from "@material-ui/system";

export default function CertificatePopUp({ setOpen, open, sData, ...rest }) {
  const theme = useTheme();
  const ref = React.useRef();
  //const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      padding: "15px",
      borderRadius: "10px",
      width: "auto",
      height: "auto",
      overflowX: "hidden",
      overflowY: "auto",
      margin: "1.5rem",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#272C38 !important" : "white",
    },
    closeButton: {
      border: "none",
      background: "transparent",
      color: userDetails.themeDark === "dark" ? "white" : "black",
    },
    generatingCertificate: {
      display: "flex",
      width: "850px",
      height: "300px",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      color: userDetails.themeDark === "dark" ? "white" : "black",
    },
  }));
  const classes = useStyles();
  const content = React.useRef();
  const certificateDiv = React.useRef();
  const [name, setName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    rest.modalPopUpClose(false);
  };
  // React.useEffect(() => {
  //   const getUserDetails = async () => {
  //     setLoading(true);
  //     const bodyParam = {
  //       body: {
  //         emailid: userDetails.emailid,
  //       },
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     };

  //     try {
  //       const response = await API.post(
  //         config.aws_cloud_logic_custom_name,
  //         "/getUserDetails",
  //         bodyParam
  //       );
  //       if (response) {
  //         const res = response?.body[0];
  //         let name = res?.first_name;
  //         if (res?.last_name !== undefined && res?.last_name !== null) {
  //           name = res?.first_name + " " + res?.last_name;
  //         }
  //         setName(name);
  //         setLoading(false);
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  //   getUserDetails();
  // }, [userDetails.email]);

  async function downloadcode() {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#certpdf"), {
      allowTaint: true,
      useCORS: true,
      logging: true,
    });
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight + 200);
    pdf.save(
      sData?.ttitle?.length >= 90
        ? sData?.ttitle.substring(0, 90)
        : sData?.ttitle + ".pdf"
    );
  }

  function capitalize(word) {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    return capitalized;
  }

  return (
    <div className={classes.mainContainer}>
      <div style={{ float: "right", display: "flex" }}>
        <button onClick={rest.handleModalClose} className={classes.closeButton}>
          <CloseIcon fontSize="large" style={{ fontSize: "2.4rem" }} />
        </button>
      </div>
      {config.aws_org_id !== "AXIATA" &&
        config.aws_org_id !== "NSECORP" &&
        config.aws_org_id !== "ENABLED" && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    height: "3rem",
                    width: "3rem",
                    marginRight: "2rem",
                    cursor: "pointer",
                    marginTop: "8px",
                  }}
                  onClick={() => downloadcode()}
                >
                  <img src={downloadPdfSvg} alt="download" height="100%" />
                </button>
              </div>
              <div>
                <CloseIcon onClick={handleClose} style={{ fontSize: "35px" }} />
              </div>
            </div>
            <div className="certcontainer" id="certpdf" ref={certificateDiv}>
              <div style={{ border: "1px solid #000", padding: "3% 20%" }}>
                <div className="img-c">
                  <img
                    src={config.aws_org_id === "ENABLED" ? ENABLED : EXCELSOFT}
                    alt="logo"
                    height="100"
                  />
                </div>
                <div className="org-container">
                  {/* <div class="img-c">
                    <img src={`${
                        config.aws_cloudfront_url
                      }${config.aws_org_id.toLocaleLowerCase()}-resources/images/org-images/${
                        config.aws_org_id
                      }.png`} alt={config.aws_org_id} height="50" width="auto" />
                  </div> */}
                  <p>Certificate awarded to</p>
                </div>
                <h1 className="name">
                  {userDetails?.uData?.last_name === undefined ||
                  userDetails?.uData?.last_name === null
                    ? userDetails?.uData?.first_name
                    : userDetails?.uData?.first_name +
                      " " +
                      userDetails?.uData?.last_name}
                </h1>
                <p className="complete">for successfully completing</p>
                <div className="title-container">
                  <h2 className="title">{sData?.ttitle}</h2>
                  {/* <h2 className="title">
                    {sData?.stype === 1 ? "(Live Webinar)" : null}
                  </h2> */}
                </div>

                <p className="date">
                  {/* {sData?.date_list
                    ? moment(new Date(sData?.date_list[0]?.date).combineStartTime).format(
                        "DD MMM YYYY"
                      )
                    : moment(new Date(sData?.startdate)).format("DD MMM YYYY")} */}
                  {moment(sData?.comp_date).format("DD MMM YYYY")}
                </p>
                <div className="img-c">
                  {/* <img src={config.aws_org_id==='ENABLED' ? ENSIGN : config.aws_org_id==='EXCELSOFT' ? EXSIGN : AXSIGN} alt="signature" height="130" /> */}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  color: Theme.color.primary,
                  borderColor: Theme.color.primary,
                  textTransform: "unset",
                  "&:hover": {
                    color: Theme.color.primary,
                    borderColor: Theme.color.primary,
                    textTransform: "unset",
                  },
                }}
                variant="outlined"
                onClick={() => downloadcode()}
              >
                Download
              </Button>
            </div>
          </>
        )}
      {config.aws_org_id === "AXIATA" && (
        <>
          <div
            className="course-certificate"
            ref={ref}
            id={"#certpdf" + sData.tid}
          >
            <div className="course-certificate_container">
              <h1 className="course-certificate_container_name">
                {userDetails?.uData?.last_name === undefined ||
                userDetails?.uData?.last_name === null
                  ? userDetails?.uData?.first_name
                  : userDetails?.uData?.first_name +
                    " " +
                    userDetails?.uData?.last_name}
              </h1>
            </div>

            <div className="course-certificate_details">
              <div className="course-certificate_details_top">
                <p className="course-certificate_details_top-head">
                  has completed the course
                </p>
                <p className="course-certificate_details_top-title">
                  {sData?.ttitle}
                </p>
              </div>

              <div className="course-certificate_details_down">
                <p className="course-certificate_details_down-date">
                  Date : {moment(sData?.compdate).format("DD/MM/YYYY")}{" "}
                </p>
                <p className="course-certificate_details_down-grade">
                  Completion Progress : 100%
                </p>
              </div>
            </div>
          </div>

          <div className="certificate_actions">
            <ReactToPdf
              targetRef={ref}
              filename="certificate.pdf"
              options={options}
              x={8}
              y={8}
              scale={1}
            >
              {({ toPdf }) => (
                <button className="certificate_actions-btn" onClick={toPdf}>
                  Download
                </button>
              )}
            </ReactToPdf>
          </div>
        </>
      )}

      {config.aws_org_id === "NSECORP" && (
        <>
          {rest.certLoad && (
            <Box className={classes.generatingCertificate}>
              Generating Certificate...
            </Box>
          )}
          {!rest.certLoad && (
            <>
              <div
                style={{
                  fontFamily: "sans-serif",
                  color: "#282460",
                  // overflowY: "hidden",
                  // overflowX: "auto",
                  // position: "relative",
                }}
                ref={ref}
              >
                <LinkedinShareButton
                  style={{ marginTop: "-10px", float: "right" }}
                  /*  title="My title"
                  summary="Summarryyyy"
                  source={`${config.DOMAIN}`} */
                  url={`https://${
                    config.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/certificate/${
                    sData?.tid
                  }/${userDetails?.uData?.ur_id}_certificate.png`}
                  className={classes.shareButton}
                >
                  <LightTooltip title="Share on linkedin">
                    <IconButton>
                      <ShareIcon fontSize="large" />
                    </IconButton>
                  </LightTooltip>{" "}
                </LinkedinShareButton>
                <img
                  src={`https://${
                    config.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/certificate/${
                    sData?.tid
                  }/${userDetails?.uData?.ur_id}_certificate.png`}
                  alt="ONDC Certificate"
                  className="ONDCCertificate"
                />
              </div>
              <div className="certificate_actions">
                {(rest.courseDetails?.feedback === "true" ||
                  rest.courseDetails?.feedback === true) && (
                  <button
                    onClick={rest.handleOpenFeedback}
                    className="certificate_actions-btn"
                  >
                    {`${userDetails?.languagejson?.Course_View
                      ?.Provide_Feedback || "Provide Feedback!"}`}
                  </button>
                )}

                <a
                  download={`${sData?.ttitle}.png`}
                  href={`https://${
                    config.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/certificate/${
                    sData?.tid
                  }/${userDetails?.uData?.ur_id}_certificate.png`}
                  style={{ border: "0", margin: "0", padding: "0" }}
                >
                  <button className="certificate_actions-btn">{`${userDetails
                    ?.languagejson?.Course_View?.Download_Button ||
                    "Download"}`}</button>
                </a>
              </div>
            </>
          )}
        </>
      )}

      {(config.aws_org_id.indexOf("NSEKAR") !== -1 ||
        config.aws_org_id === "ENABLED") && (
        <div
          style={{
            fontFamily: "sans-serif",
            color: "#282460",
            overflowY: "hidden",
            overflowX: "auto",
            position: "relative",
          }}
          ref={ref}
        >
          <img src={ENABLEDCERT} alt="SSFL Certificate" width="850px" />
          <div
            style={{
              position: "absolute",
              top: "26rem",
              left: "3.5rem",
              width: "78rem",
              textAlign: "center",
            }}
          >
            <p
              style={{
                fontSize: "3rem",
                margin: "0.5rem 0rem 0rem",
                fontFamily: "Lato",
                color: "#2591ce",
                fontWeight: "600",
              }}
            >
              {userDetails?.uData?.last_name === undefined ||
              userDetails?.uData?.last_name === null
                ? userDetails?.uData?.first_name
                : userDetails?.uData?.first_name +
                  " " +
                  userDetails?.uData?.last_name}
            </p>
            <p
              style={{
                fontSize: "1.5rem",
                margin: "1rem 0rem 0rem",
                fontFamily: "Lato",
                color: "#3D347E",
                fontWeight: "500",
              }}
            >
              On {moment(sData?.compdate).format("DD/MM/YYYY")}
            </p>
          </div>
          <div
            style={{
              position: "absolute",
              top: "40rem",
              left: "4rem",
              width: "77rem",
              textAlign: "center",
              lineHeight: "2rem",
            }}
          >
            <p
              style={{
                fontSize: "2rem",
                fontFamily: "Lato",
                color: "#2591ce",
                fontWeight: "600",
              }}
            >
              {sData?.ttitle}
            </p>
            <div
              style={{
                width: "fit-content",
                position: "absolute",
                top: "12rem",
                marginLeft: "1.5rem",
              }}
            >
              <img
                alt="Certificate"
                style={{ width: "250px" }}
                src={
                  userDetails.themeDark === "dark"
                    ? `https://${
                        config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-light.png`
                    : `https://${
                        config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/${
                        config.aws_org_id
                      }.png`
                }
              />
            </div>
          </div>
          <div className="certificate_actions">
            <ReactToPdf
              targetRef={ref}
              filename="certificate.pdf"
              options={options}
              x={8}
              y={8}
              scale={1}
            >
              {({ toPdf }) => (
                <button className="certificate_actions-btn" onClick={toPdf}>
                  Download
                </button>
              )}
            </ReactToPdf>
          </div>{" "}
        </div>
      )}
      <FeedbackPopUp
        courseDetails={rest.courseDetails}
        handleOpenFeedback={rest.handleOpenFeedback}
        openFeedback={rest.openFeedback}
        setOpenFeedback={rest.setOpenFeedback}
        CheckFeedbackSubmitted={rest.CheckFeedbackSubmitted}
        feedbackSubmitted={rest.feedbackSubmitted}
      />
    </div>
  );
}
