import React from "react";
import "./CardLarge.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import config, { Theme } from "../../../aws-exports";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import StarRoundedIcon from "@material-ui/icons/StarRounded";

import Modules from "../../../assets/media/modules.png";
import { makeStyles } from "@material-ui/styles";

import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";

import CardContent from "@material-ui/core/CardContent";
import Tippy from "@tippyjs/react";
import { StyledCard } from "../ContentCard/ContentCard.styles";
import { Skeleton } from "@material-ui/lab";
import {
  Bookmark,
  BookmarkBorderOutlined,
  StarRounded,
} from "@material-ui/icons";
import { CardActions } from "@material-ui/core";
import ProgressBar from "../../ProgressBar/progressBar";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
const defaultTippyProps = {
  arrow: false,
  interactive: true,
  interactiveBorder: 10,
  placement: "top-start",
  delay: [0, 0],
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,
  theme: "light",
  distance: 24,
  inertia: true,
};

const useStyles = makeStyles({
  buttonBookmark: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    display: "flex",
    "&:focus": {
      border: "2px solid black",
    },
    "& > svg": {
      fontSize: "2.5rem",
    },
  },
});

const CardLarge = ({
  title,
  tid,
  heading,
  comp_date,
  sub_date,
  count,
  cobj,
  tobj,
  ...rest
}) => {
  const classes = useStyles();
  const userDetails = useSelector(authData);
  let progress = (cobj / tobj) * 100;
  let src = `${
    config.aws_cloudfront_url
  }${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${tid}.png`;

  // let src1 = `${
  //   config.aws_cloudfront_url
  // }${config.aws_org_id.toLowerCase()}-resources/images/program-images/${pid}.png`;

  const status = () => {
    if (sub_date !== undefined) {
      if (!sub_date || sub_date === null) {
        return `${userDetails?.languagejson?.Home_Page?.Not_Started ||
          "Not Started"}`;
      } else if (sub_date && (!comp_date || comp_date === null)) {
        return `${userDetails?.languagejson?.Home_Page?.In_Progress ||
          "In Progress"}`;
      } else if (sub_date && comp_date) {
        return `${userDetails?.languagejson?.Home_Page?.Completed ||
          "Completed"}`;
      }
    }
  };

  const statusColor = () => {
    if (sub_date !== undefined) {
      if (!sub_date || sub_date === null) {
        return "#D83109";
      } else if (sub_date && (!comp_date || comp_date === null)) {
        return "#B16105";
      } else if (sub_date && comp_date) {
        return "#008500";
      }
    }
  };

  return (
    <StyledCard>
      <div className="img-container" onClick={rest.handleLocation}>
        {src ? (
          <img
            /* src={src} */ style={{
              backgroundImage: `url('${
                config.aws_cloudfront_url
              }${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${tid}.png')`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "white",
            }}
            alt=""
          />
        ) : (
          <Skeleton variant="rect" width={210} height={118} />
        )}
        <div
          className="statusLable"
          // style={{backgroundColor: (comp_date !== null && comp_date !== undefined) ? '#00A300' : ((sub_date !== null && sub_date !== undefined)  ? "#FFAA33" : '#FF5733')}}>
          style={{
            backgroundColor: statusColor(),
          }}
        >
          <span
            className="rating"
            style={{
              margin: "0.2rem",
              color: "white",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {status()}
          </span>
        </div>
        {config.ratingFeature && (
          <div
            className="img-container--star-container"
            title="star-rating"
            aria-label="star rating block"
            aria-required="true"
          >
            <StarRounded
              className="star"
              title="star-icon"
              aria-label="star icon"
            />
            <span
              className="rating"
              style={{ margin: "4px", fontWeight: "600" }}
            >
              {rest?.star ? rest?.star.toFixed(1) : "0.0"}
            </span>
          </div>
        )}
      </div>

      <CardContent className="card-content" onClick={rest.handleLocation}>
        <Tippy content={title} {...defaultTippyProps}>
          <div className="para-container">
            <p>{title}</p>
          </div>
        </Tippy>
        {config.aws_org_id === "SPANDANA"
          ? cobj !== undefined && <ProgressBar progress={progress} />
          : null}
      </CardContent>
      <CardActions className="card-actions">
        <div className="card-actions--footer">
          {!rest.bookmarkPage && (
            <div className="card-actions--footer-time">
              {/* <img src={Modules} alt="Modules" width="33px" /> */}
              <LayersOutlinedIcon style={{ width: "24px", height: "24px" }} />
              <p>
                {count}{" "}
                {`${userDetails?.languagejson?.Course_View?.Modules ||
                  "Modules"}`}
              </p>
            </div>
          )}
          {rest?.bookmarkPage && (
            <div className="bookmark_date-container">
              <p className="bookmark_date-text">{`${userDetails?.languagejson
                ?.Home_Page?.Bookmark_Date || "Bookmark Date"}`}</p>
              <span>:</span>
              <p className="bookmark_date-date">{rest.date}</p>
            </div>
          )}
          {rest?.bookmarkPage && (
            <button
              className={classes.buttonBookmark}
              onClick={rest.onClick}
              title={`${userDetails?.languagejson?.Home_Page?.bookmarked ||
                "bookmarked"}`}
            >
              <Bookmark className="bookmark-icon fill" />
            </button>
          )}
          {rest?.bookmark && !rest?.bookmarkPage && (
            <button
              className={classes.buttonBookmark}
              onClick={() => rest.handleBookmark(0, tid, rest.index)}
              title={`${userDetails?.languagejson?.Home_Page?.bookmarked ||
                "bookmarked"}`}
            >
              <Bookmark className="bookmark-icon fill" />
            </button>
          )}
          {!rest?.bookmark && !rest?.bookmarkPage && (
            <button
              className={classes.buttonBookmark}
              onClick={() => rest.handleBookmark(1, tid, rest.index)}
              title={`${userDetails?.languagejson?.Home_Page?.Bookmark ||
                "Bookmark"}`}
            >
              <BookmarkBorderOutlined className="bookmark-icon" />
            </button>
          )}
        </div>
      </CardActions>
    </StyledCard>
  );
};
export default CardLarge;
