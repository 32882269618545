import React, { useEffect, useState, useRef } from "react";
import "./FormativeQuiz.scss";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import config from "../../aws-exports";
import { useQueryParams } from "../../Hooks/useQueryParams";
import styled from "styled-components";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { ReactComponent as Confetti } from "../../../src/assets/svg/Confetti.svg";
import { ReactComponent as ReverseConfetti } from "../../../src/assets/svg/ReverseConfetti.svg";
import { useHistory } from "react-router-dom";
const StyledScoreContainer = styled.div`
  border-radius: 3%;
  background-color: #ffffff;
  width: 46rem;
  margin: auto;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  flex-direction: column;
    padding: 2rem;
  .show-score-header {
    width: 170px;
    height: 160px;
    background-color: ${({ primary }) => (primary ? "#cdf5d5" : "#eacccc")};
    border-radius: 4%;
    align-items: center;
    text-align: center;
    margin-top: 3rem;
    justify-content: center;
  }
    @media only screen and (max-width: 500px) {
      width: 30rem;
    }
      @media only screen and (max-width: 370px) {
      width: 25rem;
    }
`;
export function formatTime(time) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
}
const FormativeQuiz = ({
  question,
  timer,
  setTimer,
  setQuestion,
  setCurrentQuestion,
  currentQuestion,
  loading,
  setLoading,
  startQuiz,
  courseDetails,
  qpage,
  isRunning,
  setIsRunning,
  open,
  handleModalClose,
  ...rest
}) => {
  const [unAnsweredPopup, setUnAnsweredPopup] = useState(false);
  const userDetails = useSelector(authData);
  const gotScore = useRef(rest?.curObject?.score ?? []);
  const history = useHistory();
  useEffect(() => {
    let interval;
    const handleBeforeUnload = () => {
      let questionsJSON = {};
      questionsJSON.question = question;
      questionsJSON.cutoff = rest.cutOff;
      questionsJSON.attempt = rest?.curObject?.attempt;
      questionsJSON.qtype = rest?.curObject?.qtype;
      questionsJSON.oduration = rest?.curObject?.oduration;
      questionsJSON.timer = rest?.curObject?.timer;
      questionsJSON.quiz_length = rest?.curObject?.quiz_length;
      questionsJSON.currentQuestion = currentQuestion;
      let obj = {};
      obj.json = questionsJSON;
      const data = {
        tid: courseDetails.tid,
        ur_id: userDetails?.uData?.ur_id,
        obj_id: rest.curObject.oid,
        quiz_json: obj,
        time: timer,
        oid: config.aws_org_id,
        schema: config.schema,
      };
      if (rest?.curObject?.timer) {
        const success = sendBeaconRequest("/savetimer", data);
      }
    };
    const handleUnload = () => {
      clearInterval(interval);
      let questionsJSON = {};
      questionsJSON.question = question;
      questionsJSON.cutoff = rest.cutOff;
      questionsJSON.attempt = rest?.curObject?.attempt;
      questionsJSON.qtype = rest?.curObject?.qtype;
      questionsJSON.oduration = rest?.curObject?.oduration;
      questionsJSON.timer = rest?.curObject?.timer;
      questionsJSON.quiz_length = rest?.curObject?.quiz_length;
      questionsJSON.currentQuestion = currentQuestion;
      let obj = {};
      obj.json = questionsJSON;
      const data = {
        tid: courseDetails.tid,
        ur_id: userDetails?.uData?.ur_id,
        obj_id: rest.curObject.oid,
        quiz_json: obj,
        time: timer,
        oid: config.aws_org_id,
        schema: config.schema,
      };
      if (rest?.curObject?.timer) {
        const success = sendBeaconRequest("/savetimer", data);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);
    if (isRunning && open && rest?.curObject?.timer) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            saveTimerInDatabase(0);
            saveQuiz();
            setIsRunning(false); // Pause the timer when it completes
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
      clearInterval(interval);
    };
  }, [isRunning, setIsRunning, timer, open, rest?.curObject?.timer]);
  async function saveTimerInDatabase(updatedTimer) {
    let questionsJSON = {};
    questionsJSON.question = question;
    questionsJSON.cutoff = rest.cutOff;
    questionsJSON.attempt = rest?.curObject?.attempt;
    questionsJSON.qtype = rest?.curObject?.qtype;
    questionsJSON.oduration = rest?.curObject?.oduration;
    questionsJSON.timer = rest?.curObject?.timer;
    questionsJSON.quiz_length = rest?.curObject?.quiz_length;
    questionsJSON.currentQuestion = currentQuestion;
    let obj = {};
    obj.json = questionsJSON;
    const jsonString = JSON.stringify({
      tid: courseDetails.tid,
      ur_id: userDetails?.uData?.ur_id,
      obj_id: rest.curObject.oid,
      quiz_json: obj,
      time: updatedTimer,
      oid: config.aws_org_id,
      schema: config.schema,
    });
    const utf8String = unescape(encodeURIComponent(jsonString));
    const base64EncodedString = btoa(utf8String);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/savetimer",
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }
  function sendBeaconRequest(url, data) {
    const jsonData = JSON.stringify(data);
    // Send the Beacon request
    const success = navigator.sendBeacon(
      `${config.aws_cloud_logic_custom_endpoint}${url}`,
      jsonData
    );
    return success;
  }
  function saveQuiz() {
    let score = 0;
    score = question.filter((item) => item.answeredOption).length;
    rest.setScore(score);
    if (scoreAchieved(score)) {
      rest.setShowScore(true);
      rest.setMarkComplete(true);
      if (rest.curObject.op !== 2) {
        gotScore.current.push(score);
        let questionsJSON = {};
        questionsJSON.question = question;
        questionsJSON.cutoff = rest.cutOff;
        questionsJSON.attempt = rest?.curObject?.attempt;
        questionsJSON.qtype = rest?.curObject?.qtype;
        questionsJSON.oduration = rest?.curObject?.oduration;
        questionsJSON.timer = rest?.curObject?.timer;
        questionsJSON.quiz_length = rest?.curObject?.quiz_length;
        questionsJSON.currentQuestion = currentQuestion;
        let obj = {};
        obj.json = questionsJSON;
        let CourseQuiz = { ...courseDetails };
        CourseQuiz.nuggets[rest.curNugIndex].objects[rest.curObjIndex].score =
          gotScore.current;
        CourseQuiz.nuggets[rest.curNugIndex].objects[
          rest.curObjIndex
        ].quiz_json = obj;
        rest.setCourseDetails(CourseQuiz);
        rest.syncUserProgresswhilequiz(score, undefined, questionsJSON);
      }
    } else {
      gotScore.current.push(score);
      let questionsJSON = {};
      questionsJSON.question = question;
      questionsJSON.cutoff = rest.cutOff;
      questionsJSON.attempt = rest?.curObject?.attempt;
      questionsJSON.qtype = rest?.curObject?.qtype;
      questionsJSON.oduration = rest?.curObject?.oduration;
      questionsJSON.timer = rest?.curObject?.timer;
      questionsJSON.quiz_length = rest?.curObject?.quiz_length;
      let obj = {};
      obj.json = questionsJSON;
      let CourseQuiz = { ...courseDetails };
      CourseQuiz.nuggets[rest.curNugIndex].objects[rest.curObjIndex].score =
        gotScore.current;
      CourseQuiz.nuggets[rest.curNugIndex].objects[
        rest.curObjIndex
      ].quiz_json = obj;
      rest.setCourseDetails(CourseQuiz);
      if (rest.curObject.op !== 2) {
        if (rest.curObject?.score?.length === rest.curObject?.attempt) {
          rest.syncUserProgresswhilequiz(score, undefined, questionsJSON);
        } else {
          handleScoreSubmit(score, 1, questionsJSON);
        }
      }
      rest.setMarkComplete(true);
    }
    rest.setShowScore(true);
    rest.setTimerPalyPause(false);
  }
  const query = useQueryParams();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 150,
    width: 350,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  };
  const handleAnswerClick = (idx) => {
    const currentAnsquestion = [...question];
    currentAnsquestion[currentQuestion].iopts.map((item) => {
      item.youAnswered = false;
      return item;
    });
    currentAnsquestion[currentQuestion].iopts[idx].youAnswered = true;
    if (currentAnsquestion[currentQuestion].iopts[idx]?.youAnswered === true) {
      delete currentAnsquestion[currentQuestion].skip;
    }
    currentAnsquestion[currentQuestion].youAnswered = true;
    if (currentAnsquestion[currentQuestion].iopts[idx].correct) {
      currentAnsquestion[currentQuestion].answeredOption = true;
    } else {
      currentAnsquestion[currentQuestion].answeredOption = false;
    }
    setQuestion(currentAnsquestion);
  };
  const handleNextQuestion = () => {
    setQuestion((prevQues) => {
      let yourAns = prevQues[currentQuestion]?.youAnswered;
      if (yourAns === undefined || yourAns == null) {
        prevQues[currentQuestion].skip = true;
      }
      return prevQues;
    });
    if (currentQuestion !== question?.length - 1)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion + 1);
  };
  const handlePreviousQuestion = () => {
    if (currentQuestion !== 0)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion - 1);
  };
  const handleNextQuestionAns = () => {
    if (currentQuestion !== question?.length - 1)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion + 1);
  };
  const handlePreviousQuestionAns = () => {
    if (currentQuestion !== 0)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion - 1);
  };
  const gotoQuestion = (idx) => {
    setCurrentQuestion(idx);
  };
  const handleSubmitQuiz = (question) => {
    if (
      question.filter((e) => e?.youAnswered === undefined || false).length > 0
    ) {
      //newly added
      setUnAnsweredPopup(true);
    } else {
      saveQuiz();
    }
  };
  async function handleScoreSubmit(score, op, questionsJSON) {
    let obj = {};
    obj.json = questionsJSON;
    setLoading(true);
    const jsonString = JSON.stringify({
      emailid: userDetails.email,
      pid: courseDetails.cid,
      key: courseDetails.tid,
      email: userDetails.username,
      oid: config.aws_org_id,
      uname: userDetails.name,
      tenant: userDetails.locale,
      op: rest.curObject?.score?.length === rest.curObject?.attempt ? 2 : op,
      score,
      quizJSON: obj,
      obj_id: rest.curObject.oid,
      obtime: (new Date().getTime() - rest.startTime) / 1000,
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
    });
    const utf8String = unescape(encodeURIComponent(jsonString));
    const base64EncodedString = btoa(utf8String);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  const scoreAchieved = (score) => {
    if (
      (score /
        (question.length ? question.length : rest?.curObject?.quiz_length)) *
      100 >=
      parseInt(rest?.cutOff)
    )
      return true;
    return false;
  };
  function AnsAch() {
    if (rest?.score && rest.curObject?.score?.length > 0) {
      for (let i = 0; i <= rest.curObject.score.length; i++) {
        if (scoreAchieved(parseInt(rest.curObject.score[i]))) return true;
        else return false;
      }
    }
  }
  const handleReTry = () => {
    const questions = [...question].sort(() => Math.random() - 0.5);
    const resetArray = [];
    questions.forEach((element) => {
      const obj = { ...element };
      delete obj?.answeredOption;
      delete obj?.youAnswered;
      obj.iopts.forEach((item) => {
        delete item?.youAnswered;
      });
      resetArray.push(obj);
    });
    setQuestion(resetArray);
    setCurrentQuestion(0);
    let CourseQuiz = { ...courseDetails };
    delete CourseQuiz.nuggets[rest.curNugIndex].objects[rest.curObjIndex].rtime;
    rest.setCourseDetails(CourseQuiz);
    setTimer(rest?.curObject?.oduration);
    setIsRunning(true);
    rest.setScore(0);
    rest.setShowScore(false);
  };
  return (
    <React.Fragment>
      {!loading && startQuiz && !rest.showScore && (
        <div className="quiz_container">
          <div className="quiz_wrapper">
            {rest?.curObject?.timer && (
              <span style={{ fontWeight: "bolder", alignSelf: "end" }}>
                {formatTime(timer)}
              </span>
            )}{" "}
            <div className="quiz_wrapper_ques_ans">
              <div className="quiz_wrapper_ques_ans--question">
                <div>
                  {currentQuestion + 1}/{question?.length}
                </div>
                <div>
                  <p>{question[currentQuestion]?.istem}</p>
                </div>
              </div>
              {question[currentQuestion]?.imageurl && (
                <div className="quiz_wrapper_ques_ans_img-container">
                  <img
                    src={`https://${config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${query.get(
                        "id"
                      )}/${question[currentQuestion]?.imageurl}`}
                    alt=""
                    style={{ width: "30rem" }}
                  />
                </div>
              )}
              <div className="quiz_wrapper_ques_ans--answers">
                {question[currentQuestion]?.iopts.map((item, index) => (
                  <div
                    key={index}
                    style={{ cursor: "pointer" }}
                    className={`ans-btn ${item?.youAnswered && !item.wrong ? "selected-answer" : ""
                      }
                    ${item?.youAnswered && item.wrong ? "error" : ""}
                    ${question[currentQuestion]?.checkAnswer && item.correct
                        ? "ans_green"
                        : ""
                      }
                    `}
                    onClick={() => handleAnswerClick(index)}
                  >
                    {item.content}
                  </div>
                ))}
              </div>
            </div>
            <div className="quiz_wrapper_grow" />
            <div className="quiz_wrapper--actions">
              <button
                className="btn btn-outline primary"
                onClick={handlePreviousQuestion}
                disabled={currentQuestion === 0 ? true : false}
              >
                {`${userDetails?.languagejson?.Course_View?.Previous ||
                  "Previous"}`}
              </button>
              <div className="left-actions">
                {currentQuestion !== question?.length - 1 &&
                  question[currentQuestion]?.youAnswered && (
                    <button
                      className="btn btn-contained primary"
                      onClick={handleNextQuestion}
                      disabled={
                        currentQuestion === question?.length - 1 ? true : false
                      }
                    >
                      {`${userDetails?.languagejson?.Course_View?.Next ||
                        "Next"}`}
                    </button>
                  )}
                {currentQuestion !== question?.length - 1 &&
                  !question[currentQuestion]?.next &&
                  !question[currentQuestion]?.youAnswered && (
                    <button
                      className="btn btn-contained primary"
                      onClick={handleNextQuestion}
                      disabled={
                        currentQuestion === question?.length - 1 ? true : false
                      }
                    >
                      {`${userDetails?.languagejson?.Course_View?.Skip ||
                        "Skip"}`}
                    </button>
                  )}
                {currentQuestion === question?.length - 1 && (
                  <button
                    className="btn btn-contained primary"
                    onClick={() => {
                      if (qpage !== undefined && qpage === "object") {
                        rest.setStartQuiz(false);
                        setCurrentQuestion(0);
                      } else {
                        handleSubmitQuiz(question);
                        setCurrentQuestion(0);
                      }
                    }}
                  >
                    {`${userDetails?.languagejson?.Course_View?.Submit ||
                      "Submit"}`}
                  </button>
                )}
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="quiz_container--footer">
            {question?.map((item, index) => (
              <button
                key={item.iid}
                style={{ cursor: "pointer" }}
                className={`btn contained ${item.skip ? "border" : ""} ${item?.youAnswered ? "green" : ""
                  }`}
                onClick={() => gotoQuestion(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      )}
      {rest.showScore && (
        <>
          {scoreAchieved(rest.score) && (
            <div
              style={{
                marginTop: "4rem",
                marginBottom: "4rem",
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <Confetti style={{ height: "60", width: "60" }} />{" "}
              <h1 className="mainHeading">Congratulations!!!</h1>
              <ReverseConfetti style={{ height: "60", width: "60" }} />
            </div>
          )}
          <StyledScoreContainer primary={scoreAchieved(rest.score)}>
            <div className="show-score-header">
              <h1 style={{ marginTop: "2rem" }}>
                {rest?.score}/
                {question.length
                  ? question.length
                  : rest?.curObject?.quiz_length}
              </h1>
              <h4
                style={{
                  marginBottom: "1rem",
                  marginTop: "1rem",
                  color: "black",
                }}
              >
                Your Score
              </h4>
              <button
                className="button-3"
                style={{
                  backgroundColor: !scoreAchieved(rest?.score)
                    ? "#e38888"
                    : "#1d8417",
                }}
              >
                {!scoreAchieved(rest?.score) ? "Fail" : "Pass"}
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3rem",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li style={{ color: "#007bff" }}>
                    &#8226;{" "}
                    {Math.round(
                      (rest?.score /
                        (question.length
                          ? question.length
                          : rest?.curObject?.quiz_length)) *
                      100
                    )}
                    %
                  </li>
                </ul>
                <div>Total Percentage</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "5rem",
                }}
              >
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li style={{ color: "#000000" }}>
                    &#8226;{" "}
                    {question.length
                      ? question.length
                      : rest?.curObject?.quiz_length}
                  </li>
                </ul>
                <div>Total Questions</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3rem",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li style={{ color: "#00ff00" }}>&#8226; {rest?.score}</li>
                </ul>
                <div>Correct Answers</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "8rem",
                }}
              >
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li style={{ color: "#ff0000" }}>
                    &#8226;{" "}
                    {(question.length
                      ? question.length
                      : rest?.curObject?.quiz_length) - rest?.score}
                  </li>
                </ul>
                <div>Wrong Answers</div>
              </div>
            </div>
          </StyledScoreContainer>
          <div className="allButtons">
            {!scoreAchieved(rest.score) &&
              courseDetails?.nuggets[rest.curNugIndex].objects[rest.curObjIndex]
                ?.score?.length <
              courseDetails.nuggets[rest.curNugIndex].objects[
                rest.curObjIndex
              ]?.attempt && (
                <>
                  <div style={{ margin: "1rem 0" }}>
                    <button className="button-3" onClick={handleModalClose}>
                      Cancel
                    </button>
                  </div>
                  <div style={{ margin: "1rem 0" }}>
                    <button className="button-3" onClick={handleReTry}>
                      {config.aws_org_id === 'EXCELEARN' ? 'Retake Test' : `${userDetails?.languagejson?.Course_View?.Retake_exam ||
                        "Retake exam"}`}
                    </button>
                  </div>
                </>
              )}
            {rest.curObject?.score !== undefined &&
              rest.curObject?.score !== null
              ? rest.curObject?.score?.length >= rest.curObject?.attempt &&
              !rest.showAnswers && (
                <div>
                  <div style={{ margin: "1rem 0" }}>
                    <button
                      className="button-3"
                      onClick={() => {
                        rest.setShowAnswers(true);
                        setCurrentQuestion(0);
                        rest.setShowScore(false);
                        rest.setStartQuiz(false);
                      }}
                    >
                      {`${userDetails?.languagejson?.Course_View
                        ?.Show_Answers || "Show Answers"}`}
                    </button>
                    <button
                      className="button-3"
                      onClick={() => {
                        history.push("/home");
                      }}>
                      Go To HomePage
                    </button>
                  </div>
                </div>
              )
              : null}
            {config.aws_org_id === 'EXCELEARN' ? (rest.curObject?.score?.length === rest.curObject?.attempt && scoreAchieved(rest.score)) && (
              <div style={{ margin: "1rem 0" }}>
                <button
                  className="button-3"
                  onClick={() => {
                    gotScore.current = [];
                    if (
                      courseDetails.nuggets.at(-1).objects.at(-1).oid ===
                      rest?.curObject?.oid
                    ) {
                      setCurrentQuestion(0);
                      return rest.nextcourse();
                    }
                    setCurrentQuestion(0);
                    rest.setShowScore(false);
                    rest.nextObject();
                  }}
                >
                  {courseDetails.nuggets.at(-1).objects.at(-1).oid ===
                    rest?.curObject?.oid
                    ? "Complete Course"
                    : `${userDetails?.languagejson?.Course_View?.Next ||
                    "Next"}`}
                </button>
              </div>
            ):(rest.curObject?.score?.length === rest.curObject?.attempt) && (
              <div style={{ margin: "1rem 0" }}>
                <button
                  className="button-3"
                  onClick={() => {
                    gotScore.current = [];
                    if (
                      courseDetails.nuggets.at(-1).objects.at(-1).oid ===
                      rest?.curObject?.oid
                    ) {
                      setCurrentQuestion(0);
                      return rest.nextcourse();
                    }
                    setCurrentQuestion(0);
                    rest.setShowScore(false);
                    rest.nextObject();
                  }}
                >
                  {courseDetails.nuggets.at(-1).objects.at(-1).oid ===
                    rest?.curObject?.oid
                    ? "Complete Course"
                    : `${userDetails?.languagejson?.Course_View?.Next ||
                    "Next"}`}
                </button>
              </div>
            )}
            {scoreAchieved(rest.score) &&
              rest.curObject?.score?.length !== rest.curObject?.attempt && (
                <>
                  <div className="allButtons">
                    <button
                      className="button-3"
                      onClick={() => {
                        gotScore.current = [];
                        if (
                          courseDetails.nuggets.at(-1).objects.at(-1).oid ===
                          rest?.curObject?.oid
                        ) {
                          return rest.nextcourse();
                        }
                        rest.nextObject();
                      }}
                    >
                      {courseDetails.nuggets.at(-1).objects.at(-1).oid ===
                        courseDetails.nuggets[rest.curNugIndex].objects[
                          rest.curObjIndex
                        ].oid
                        ? "Complete Course"
                        : `${userDetails?.languagejson?.Course_View?.Next ||
                        "Next"}`}
                    </button>
                    <button
                      className="button-3"
                      onClick={() => {
                        rest.setShowAnswers(true);
                        setCurrentQuestion(0);
                        rest.setShowScore(false);
                        rest.setStartQuiz(false);
                      }}
                    >
                      {`${userDetails?.languagejson?.Course_View?.Show_Answers ||
                        "Show Answers"}`}
                    </button>
                    <button
                      className="button-3"
                      onClick={() => {
                        history.push("/home");
                      }}>
                      Go To HomePage
                    </button>
                  </div>
                </>
              )}
          </div>
        </>
      )}
      {rest.showAnswers && rest.curObject?.quiz_json !== null && (
        <div>
          <div className="quiz_container">
            <div className="quiz_wrapper">
              <div className="quiz_wrapper_ques_ans">
                <div className="quiz_wrapper_ques_ans--question">
                  <div>
                    Q {currentQuestion + 1}/
                    {
                      courseDetails.nuggets[rest.curNugIndex].objects[
                        rest.curObjIndex
                      ]?.quiz_json?.json?.question?.length
                    }
                  </div>
                  <div>
                    <p>
                      {
                        courseDetails.nuggets[rest.curNugIndex].objects[
                          rest.curObjIndex
                        ]?.quiz_json?.json?.question[currentQuestion]?.istem
                      }
                    </p>
                  </div>
                </div>
                {courseDetails.nuggets[rest.curNugIndex].objects[
                  rest.curObjIndex
                ]?.quiz_json?.json?.question[currentQuestion]?.imageurl && (
                    <div className="quiz_wrapper_ques_ans_img-container">
                      <img
                        src={`https://${config.DOMAIN
                          }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${query.get(
                            "id"
                          )}/${courseDetails.nuggets[rest.curNugIndex].objects[
                            rest.curObjIndex
                          ]?.quiz_json?.json?.question[currentQuestion]?.imageurl
                          }`}
                        alt=""
                        style={{ width: "30rem" }}
                      />
                    </div>
                  )}
                <div className="quiz_wrapper_ques_ans--answers">
                  {courseDetails.nuggets[rest.curNugIndex].objects[
                    rest.curObjIndex
                  ]?.quiz_json?.json?.question[currentQuestion]?.iopts.map(
                    (item, index) => (
                      <>
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          className={`ans-btn 
                          ${item?.correct ? "ans_green" : ""}
                          ${item?.youAnswered && item?.correct
                              ? "ans_green"
                              : ""
                            }
                          ${item?.youAnswered && !item?.correct ? "error" : ""}
                          `}
                        >
                          {item?.content}
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="quiz_wrapper_grow" />
              <div className="quiz_wrapper--actions">
                <button
                  className="btn btn-outline primary"
                  onClick={handlePreviousQuestionAns}
                  disabled={currentQuestion === 0 ? true : false}
                >
                  {`${userDetails?.languagejson?.Course_View?.Previous ||
                    "Previous"}`}
                </button>
                <div className="left-actions">
                  {currentQuestion !==
                    courseDetails.nuggets[rest.curNugIndex].objects[
                      rest.curObjIndex
                    ]?.quiz_json?.json?.question?.length -
                    1 && (
                      <button
                        className="btn btn-contained primary"
                        onClick={handleNextQuestionAns}
                        disabled={
                          currentQuestion ===
                            courseDetails.nuggets[rest.curNugIndex].objects[
                              rest.curObjIndex
                            ]?.quiz_json?.json?.question?.length -
                            1
                            ? true
                            : false
                        }
                      >
                        {`${userDetails?.languagejson?.Course_View?.Next ||
                          "Next"}`}
                      </button>
                    )}
                  {rest.showAnswers && (
                    <button
                      className="btn btn-contained primary"
                      onClick={() => {
                        rest.setShowAnswers(false);
                        setCurrentQuestion(0);
                        rest.score && rest.setShowScore(true);
                      }}
                    >
                      {`${userDetails?.languagejson?.Course_View
                        ?.Close_Answers || "Close Answers"}`}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <hr className="divider" />
          </div>
        </div>
      )}
      <Modal open={unAnsweredPopup}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {`${userDetails?.languagejson?.Course_View?.un_answered ||
              "You have some unanswered questions"}`}
          </Typography>
          <button
            className="okButton"
            onClick={() => setUnAnsweredPopup(false)}
          >
            {`${userDetails?.languagejson?.Course_View?.ok || "Ok"}`}
          </button>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
export default FormativeQuiz;
