import React, { useState, useEffect } from 'react';
import "./PollingCSS.css";
import { callAPI, } from "../../../api/api";
import config from "../../../aws-exports";

const Polling = ({ row, ind, userDetails, refetch, hotRefeth, setMainLoader }) => {
    
    const selected = row?.json_attachment?.findIndex(item => item.userselectedpoll !== undefined && item.userselectedpol === true); 
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(selected !== -1 ? selected : null);
    const markAnswer = async (index,item) => {
        try {
            setMainLoader(true);
          setSelectedAnswerIndex(index); 
          let obj = {};
          obj.method = 'post';
          obj.apiName = `/createPollvotes`;
          obj.body = {};
          obj.body.ur_id = userDetails?.uData?.ur_id;
          obj.body.schema = config.schema;
          obj.body.oid = config.aws_org_id;
          obj.body.postid = item.postid;
          obj.body.attachmentid = item.id;
          await callAPI(obj);
          hotRefeth();
          refetch();
          setMainLoader(false);
        } catch (err) {
    
        }
      }
    useEffect(() => {
        showResults();
    }, [selectedAnswerIndex]);
 
    const showResults = () => {
        let indvar = `.opntionanswer${ind}`;
            const answers = document.querySelectorAll(indvar);
            let userselectedpoll = 0; 
            for (let i = 0; i < row?.json_attachment?.length; i++) { 
                if(row.json_attachment[i].userselectedpoll){
                    userselectedpoll = 1;
                    for (const answer of answers) {
                        answer.style.cursor = "not-allowed";
                        answer.style["pointer-events"] = "none";
                    }
                    break;
                } 
            }
        for (let i = 0; i < row?.json_attachment?.length; i++) {
            let percentage = 0;   
            if(userselectedpoll){
                setSelectedAnswerIndex(i);
              
                percentage = Math.round(
                    ((row.json_attachment[i].pollcount || 0) * 100) / ((row.poll_count && row.poll_count !== null) ? (Number(row.poll_count)) : 1)
                ); 
                answers[i].querySelector(".percentage_bar").style.width = percentage + "%";
                answers[i].querySelector(".percentage_value").innerText = percentage + "%";
            }  
        }
    };

    return (
        <div className="poll">
            {/* <div className="question">
                <h2>{poll.question}</h2>
            </div> */}
            <div className="answers">
                {row && row.json_attachment?.map((answer, index) => (
                    <div className={`answer opntionanswer${ind} ${index === selectedAnswerIndex ? 'selected' : ''}`} 
                    onClick={() => markAnswer(index, answer)} key={index}>
                        {answer.pollquestion}
                        <span className="percentage_bar"></span>
                        <span className="percentage_value"></span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Polling;
