import React, { useState, useEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { useHistory, Link, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import config, { Theme } from "../../aws-exports";

import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { motion, AnimatePresence } from "framer-motion";
import noThingIsHere from "../../assets/Nothing here yet.gif";

import { Box, makeStyles, Breadcrumbs, Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//React router
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import { Skeleton } from "@material-ui/lab";
import ExploreSort from "./ExploreSort/ExploreSort";
import ExploreFilter from "./ExploreFilter/ExploreFilter";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(3),
    height: "600px",
    width: "1100px",
    backgroundColor: "white",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: "12px",
  },
  mainGrid: {
    marginTop: "2rem",
    boxShadow: Theme.boxShadow,
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "5px",
    "& > div": {
      display: "flex",
    },
    "@media (max-width: 430px)": {
      marginTop: 0,
      padding: "1rem",
      "& > div > div": {
        margin: "0.8rem",
      },
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function ExploreCourseView() {
  const userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [ctype, setCtypeF] = useState("");
  const [cadProductF, setCAdProductF] = useState("");
  const [cmrToolsF, setCMrToolsF] = useState("");
  const [cdurF, setCDurF] = useState("");

  const [cstype, setCStypeF] = useState("CON");
  const [cstypeV, setCStypeFV] = useState("Newest");
  const query = useQuery();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [courseData, setCourseData] = useState([]);
  const [oCourseData, setOCourseData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [proGm, setProGm] = useState({});
  const [errorD, setErrorD] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [objectType, setObjectType] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [initialCourseData, setInitialCourseData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryType, setCategoryType] = useState([]);

  const [progressType, setProgressType] = useState([]);
  useEffect(() => {
    if (!userDetails || userDetails === 0) {
      let obj = {};
      obj.type = "program";
      obj.pid = query.get("id");
      if (query.get("from") !== undefined) {
        obj.from = 0;
      }
      localStorage.setItem("deeplink", JSON.stringify(obj));
      setErrorD(1);
      if (config.aws_org_id == "AXIATA") {
        window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=Cognito User Pool"`;
      } else {
        window.location.href = config.aws_cloudfront_lurl;
      }
    } else {
      const storedSelectedOptions =
        JSON.parse(localStorage.getItem("selectedOptions")) || [];
      const storedCategoryType =
        JSON.parse(localStorage.getItem("categoryType")) || [];
      const storedProgressType =
        JSON.parse(localStorage.getItem("progressType")) || [];

      setSelectedOptions(storedSelectedOptions);
      setCategoryType(storedCategoryType);
      setProgressType(storedProgressType);
      getCategoryData({
        storedSelectedOptions,
        storedCategoryType,
        storedProgressType,
      });
      if (userDetails.curExploreProgramData !== undefined) {
      } else {
        let ctp =
          userDetails.filter === undefined ||
          userDetails.filter.setCStypeF === undefined
            ? "CON"
            : userDetails.filter.setCStypeF;

        setCStypeF(ctp);
        sortFun(ctp, userDetails.curExploreProgramData.courses);
        commansetsort(ctp);
        if (
          userDetails.location.from === "Search" &&
          (userDetails.filter === undefined ||
            userDetails.filter.setCStypeF !== "AZ")
        ) {
          setCStypeFV("Select");
        }
      }
    }
  }, []);

  async function getCategoryData(param) {
    setIsLoading(true);
    const jsonData = {
      limit: 50,
      page: 1,
      type: ["courses"],
      schema: config.schema,
      tid: userDetails?.curExploreProgramData?.TOPICID,
      oid: config.aws_org_id,
      tenant: userDetails?.uData?.oid,
      admin: false,
      ur_id: userDetails?.uData?.ur_id,
      groups: [16],
      user_lang: userDetails?.uData?.lang,
      progtypes:
        param.funcCall === "clear"
          ? []
          : (param.funcCall !== "apply"
            ? param.storedProgressType.length !== 0
            : null)
          ? param.storedProgressType
          : progressType,
      objtypes:
        param.funcCall === "clear"
          ? []
          : (param.funcCall !== "apply"
            ? param.storedSelectedOptions.length !== 0
            : null)
          ? param.storedSelectedOptions
          : selectedOptions,
      dur: null,
    };

    // Convert JSON to a string and encode it
    const jsonString = encodeURIComponent(JSON.stringify(jsonData));

    // Append the encoded string to the base URL
    const baseUrl = `/getCategoryDetails`; // Replace with your actual URL
    const fullUrl = `${baseUrl}?queryjson=${jsonString}`;

    try {
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        fullUrl,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken
          },
        }
      );

      if (response !== undefined) {
        let { objects, courses } = response;
        setInitialData(objects);

        let temp = [];
        let OCourse = [];
        for (let i = 0; i < courses?.length; i++) {
          if (
            courses[i].pid != undefined &&
            courses[i].pid !== 0 &&
            courses[i].pid.replace(/\s/g, "").length != 0
          ) {
            temp.push(courses[i]);
          } else {
            OCourse.push(courses[i]);
          }
        }

        OCourse?.sort((a, b) => b["con"] - a["con"]);
        setCourseData(OCourse);
        setOCourseData(OCourse);
        setInitialCourseData(OCourse);
        setCourses(OCourse);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleLocation = (TID, TN, pro) => {
    let breadcrumb = { ...userDetails };
    if (userDetails !== undefined) {
      if (
        userDetails.location !== undefined &&
        Object.keys(userDetails.location).length == 0
      ) {
        breadcrumb.location = {
          from: "Home",
          courseName: TN,
          programName: pro.pname,
          programId: pro.pid,
        };
        if (query.get("from") === 0 || query.get("from") === "0") {
          breadcrumb.location.pFrom = `/ExploreCourseView?from=0&id=${pro.pid}`;
        } else {
          breadcrumb.location.pFrom = `/ExploreCourseView?id=${pro.pid}`;
        }
      } else {
        breadcrumb.location = {
          from: breadcrumb.location.from,
          topicName: breadcrumb.location.topicName,
          topicId: breadcrumb.location.topicId,
          programName: breadcrumb.location.programName,
          programId: breadcrumb.location.programId,
          courseName: TN,
          viewAllRoute: "/ExploreCourseViews",
          viewAll: breadcrumb.location.viewAll,
          viewAllId: breadcrumb.location.viewAllId,
          pFrom: breadcrumb.location.pFrom,
        };
      }
    }

    dispatch(awsSignIn(breadcrumb));
    history.push(`course?id=${TID}`);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };

  const contentTypeChange = (event) => {
    setCtypeF(event.target.value);
    let val = event.target.value;
    filter(val, cadProductF, cmrToolsF, cdurF);
  };

  const AdHandleChange = (event) => {
    let val = event.target.value;

    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setCStypeF = cstype;
    if (cadProductF == event.target.value) {
      setCAdProductF("");
      val = "";
      filterstoredata.filter.setCAdProductF = "";
    } else {
      setCAdProductF(event.target.value);
      filterstoredata.filter.setCAdProductF = event.target.value;
    }
    cmnsetfilter(filterstoredata);
    dispatch(awsSignIn(filterstoredata));
    filter("", val, cmrToolsF, cdurF);
  };
  async function cmnsetfilter(filterstoredata) {
    if (userDetails.filter !== undefined) {
      filterstoredata.filter.setDurF = userDetails.filter.setDurF;
      filterstoredata.filter.setMrToolsF = userDetails.filter.setMrToolsF;
      filterstoredata.filter.setStypeF = userDetails.filter.setStypeF;
      filterstoredata.filter.setCtypeF = userDetails.filter.setCtypeF;
      filterstoredata.filter.setPStypeF = userDetails.filter.setPStypeF;
      filterstoredata.filter.setAdProductF = userDetails.filter.setAdProductF;
      filterstoredata.filter.odata = userDetails.filter.odata;
      filterstoredata.filter.cdata = userDetails.filter.cdata;
    }
  }

  const ToolsHandleChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setCStypeFV = cstypeV;
    if (cmrToolsF == event.target.value) {
      setCMrToolsF("");
      val = "";
      filterstoredata.filter.setCMrToolsF = "";
    } else {
      setCMrToolsF(event.target.value);
      filterstoredata.filter.setCMrToolsF = event.target.value;
    }
    cmnsetfilter(filterstoredata);
    dispatch(awsSignIn(filterstoredata));
    filter("", cadProductF, val, cdurF);
  };

  const DurHandleChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setCStypeFV = cstypeV;
    if (cdurF == event.target.value) {
      setCDurF("");
      val = "";
      filterstoredata.filter.setCDurF = "";
    } else {
      setCDurF(event.target.value);
      filterstoredata.filter.setCDurF = event.target.value;
    }
    cmnsetfilter(filterstoredata);
    dispatch(awsSignIn(filterstoredata));
    filter("", cadProductF, cmrToolsF, val);
  };

  function filter(otype, adprod, mrtool, dur) {
    let data =
      userDetails.curExploreProgramData.courses !== undefined
        ? userDetails.curExploreProgramData.courses
        : [...oCourseData];

    let filteredData = [];

    filteredData = data.filter(function(e) {
      let obj = { ...e };

      obj.adproducts = e.adproducts != undefined ? e.adproducts : [];
      obj.mrtools = e.mrtools != undefined ? e.mrtools : [];

      obj.tduration = e.tduration != undefined ? e.tduration : 0;
      let res = false;
      let o = false;
      let a = false;
      let m = false;
      let d = false;

      if (adprod != "") {
        if (obj.adproducts != undefined) {
          for (let i = 0; i < obj.adproducts.length; i++) {
            if (adprod == obj.adproducts[i]) {
              a = true;
              break;
            }
          }
        }
      }

      if (mrtool != "") {
        if (obj.mrtools != undefined) {
          for (let i = 0; i < obj.mrtools.length; i++) {
            if (mrtool == obj.mrtools[i]) {
              m = true;
              break;
            }
          }
        }
      }

      if (dur != "") {
        if (dur == "5") {
          if (obj.tduration < 300) {
            d = true;
          }
        } else if (dur == "15") {
          if (obj.tduration < 900) {
            d = true;
          }
        } else if (dur == "30") {
          if (obj.tduration < 1800) {
            d = true;
          }
        } else if (dur == "30_60") {
          if (obj.tduration >= 1800 && obj.tduration <= 3600) {
            d = true;
          }
        } else if (dur == "60") {
          if (obj.tduration > 3600) {
            d = true;
          }
        }
      }

      // All Value
      if (otype != "" && adprod != "" && mrtool != "" && dur != "") {
        if (o && a && m && d) {
          res = true;
        }
      }

      // otype adprod mrtool
      if (otype != "" && adprod != "" && mrtool != "" && dur == "") {
        if (o && a && m) {
          res = true;
        }
      }

      // otype adprod  dur
      if (otype != "" && adprod != "" && mrtool == "" && dur != "") {
        if (o && a && d) {
          res = true;
        }
      }

      // otype  mrtool dur
      if (otype != "" && adprod == "" && mrtool != "" && dur != "") {
        if (o && m && d) {
          res = true;
        }
      }

      //  adprod mrtool dur
      if (otype == "" && adprod != "" && mrtool != "" && dur != "") {
        if (a && m && d) {
          res = true;
        }
      }

      // otype adprod
      if (otype != "" && adprod != "" && mrtool == "" && dur == "") {
        if (o && a) {
          res = true;
        }
      }

      //  adprod mrtool
      if (otype == "" && adprod != "" && mrtool != "" && dur == "") {
        if (a && m) {
          res = true;
        }
      }

      //   mrtool dur
      if (otype == "" && adprod == "" && mrtool != "" && dur != "") {
        if (m && d) {
          res = true;
        }
      }

      // otype  dur
      if (otype != "" && adprod == "" && mrtool == "" && dur != "") {
        if (o && d) {
          res = true;
        }
      }

      // otype
      if (otype != "" && adprod == "" && mrtool == "" && dur == "") {
        if (o) {
          res = true;
        }
      }

      // adprod
      if (otype == "" && adprod != "" && mrtool == "" && dur == "") {
        if (a) {
          res = true;
        }
      }

      // mrtool
      if (otype == "" && adprod == "" && mrtool != "" && dur == "") {
        if (m) {
          res = true;
        }
      }

      // dur
      if (otype == "" && adprod == "" && mrtool == "" && dur != "") {
        if (d) {
          res = true;
        }
      }
      if (adprod == "" && mrtool == "" && dur == "") {
        res = true;
      }

      return res;
    });

    setCourses(filteredData);
    setCourseData(filteredData);
    let valstype = cstype;
    if (
      userDetails.filter !== undefined &&
      userDetails.filter.setCStypeF !== undefined
    ) {
      valstype = userDetails.filter.setCStypeF;
    }
    sortFun(valstype, filteredData);

    setOpenSnackbar(true);
  }

  function clear() {
    setCategoryType([]);
    setSelectedOptions([]);
    setProgressType([]);
    setCourses(oCourseData);
    setCtypeF("");
    setCAdProductF("");
    setCMrToolsF("");
    setCDurF("");
    setCStypeF(cstype);
    setCStypeFV(cstypeV);
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));

    sortFun(cstype, oCourseData);
    getCategoryData({ funcCall: "clear" });
  }

  const sortTypeChange = (event) => {
    setCStypeF(event.target.value);
    let val = event.target.value;

    sortFun(val);
    commansetsort(val);
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setCStypeF = event.target.value;

    if (
      userDetails.curExploreProgramData !== undefined &&
      userDetails.curExploreProgramData.courses
    ) {
      filterstoredata.filter.cdata = userDetails.curExploreProgramData.courses;
    }
    dispatch(awsSignIn(filterstoredata));
  };
  const commansetsort = (val) => {
    if (val == "TV") {
      setCStypeFV("Most Viewed");
    }
    if (val == "STAR") {
      setCStypeFV("Rating");
    }
    if (val == "BKMD") {
      setCStypeFV("Most Bookmarked");
    }
    if (val == "SHRD") {
      setCStypeFV("Most Shared");
    }
    if (val == "CON") {
      setCStypeFV("Newest");
    }
    if (val == "sqno") {
      setCStypeFV("Sequence");
    }
    if (val == "AZ") {
      setCStypeFV("A-Z");
    }
  };
  function sortFun(val, obdata) {
    let data = obdata === undefined ? [...courses] : [...obdata];
    if (val == "STAR") {
      val = "starAvg";
    }

    if (val == "AZ") {
      data.sort((a, b) => {
        if (a.tn < b.tn) {
          return -1;
        }
        if (a.tn > b.tn) {
          return 1;
        }

        return 0;
      });
    } else if (val == "TV") {
      data.sort((a, b) => {
        return b.sortdt.tv - a.sortdt.tv;
      });
    } else if (val == "BKMD") {
      data.sort((a, b) => {
        return b?.sortdt?.bookmark - a?.sortdt?.bookmark;
      });
    } else if (val == "SHRD") {
      data.sort((a, b) => {
        return b.sortdt.share - a.sortdt.share;
      });
    } else if (val == "STAR") {
      data.sort((a, b) => {
        return b.sortdt.rate - a.sortdt.rate;
      });
    } else if (val == "CON") {
      data.sort((a, b) => {
        return b["con"] - a["con"];
      });
    } else {
      data.sort((a, b) => b[val] - a[val]); //.reverse();
    }

    setCourses(data);
  }

  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        style={{ fontSize: "1.6rem" }}
      />
    );
  }

  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div>
      {userDetails != 0 && (
        <>
          {userDetails.location !== undefined && errorD == 0 ? (
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  fontSize="large"
                  style={{ fill: "var(--color-primary)" }}
                />
              }
              aria-label="breadcrumb"
              style={{ marginBottom: "3rem" }}
            >
              <Link
                color="inherit"
                to="/home"
                style={{
                  color: "#777",
                  fontSize: "1.5rem",
                  textDecoration: "none",
                  cursor: "context-menu",
                }}
              >
                {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
              </Link>
              <Link
                color="inherit"
                to="/ExploreCourseView"
                style={{
                  color: "#777",
                  fontSize: "1.5rem",
                  textDecoration: "none",
                }}
              >
                {proGm.pname}
              </Link>
            </Breadcrumbs>
          ) : null}
          {userDetails.location.from !== undefined &&
            (userDetails.location.from === "Home" ||
              userDetails.location.from === "Explore" ||
              userDetails.location.from === "Search") && (
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="large"
                    style={{ fill: "var(--color-primary)" }}
                  />
                }
                aria-label="breadcrumb"
                style={{ marginBottom: "3rem" }}
              >
                <Link
                  color="inherit"
                  to={userDetails.location.from === "Home" ? "/home" : "#"}
                  style={{
                    color: `${
                      userDetails.location.from === "Home" ? "#1974b5" : "#777"
                    }`,
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: `${
                      userDetails.location.from === "Home"
                        ? "pointer"
                        : "context-menu"
                    }`,
                  }}
                >
                  {userDetails.location.from}
                </Link>
                {userDetails.location.topicName && (
                  <Link
                    style={{
                      color: "#1974b5",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                    }}
                    color="inherit"
                    to={`/explore/${userDetails.location.topicName.replaceAll(
                      /\s/g,
                      "-"
                    )}/${userDetails.location.topicId}`}
                  >
                    {userDetails.location.topicName}
                  </Link>
                )}
                {userDetails.location.viewAll &&
                  userDetails.location.programName && (
                    <Link
                      to="/ExploreViewAll"
                      style={{
                        color: "#1974b5",
                        fontSize: "1.5rem",
                        textDecoration: "none",
                      }}
                      color="inherit"
                    >
                      View all
                    </Link>
                  )}
                {userDetails.location.viewAll &&
                  userDetails.location.viewAllId && (
                    <Link
                      to="/ExploreViewAll"
                      style={{
                        color: "#777",
                        fontSize: "1.5rem",
                        textDecoration: "none",
                      }}
                      color="inherit"
                    >
                      View all
                    </Link>
                  )}
                {userDetails.location.programName && (
                  <Link
                    color="inherit"
                    to="/ExploreCourseView"
                    style={{
                      color: "#777",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                    }}
                  >
                    {userDetails.location.programName}
                  </Link>
                )}
              </Breadcrumbs>
            )}

          <div className="flex-between" style={{ justifyContent: "flex-end" }}>
            <div className="flex-container" style={{ width: "100%" }}>
              <h3 style={{ width: "100%", fontWeight: "600" }}>Courses</h3>
              <strong
                style={{
                  float: "right",
                  paddingRight: "4.0rem",
                  cursor: "pointer",
                  display: "flex",
                }}
              ></strong>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ width: "100%" }}>
                {" "}
                <ExploreSort
                  sortTypeChange={sortTypeChange}
                  stype={cstype}
                  sorttp={userDetails.location.from}
                />
              </span>
              <span>
                {
                  <ExploreFilter
                    handleClick={handleClick}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    contentTypeChange={contentTypeChange}
                    AdHandleChange={AdHandleChange}
                    adProductF={cadProductF}
                    DurHandleChange={DurHandleChange}
                    open={open}
                    ctype={ctype}
                    clear={clear}
                    ToolsHandleChange={ToolsHandleChange}
                    mrToolsF={cmrToolsF}
                    durF={cdurF}
                    type="course"
                    objectType={objectType}
                    initialCourseData={initialCourseData}
                    initialData={initialData}
                    setInitialData={setInitialData}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    setProgressType={setProgressType}
                    progressType={progressType}
                    getCategoryData={getCategoryData}
                    categoryType={categoryType}
                    setCategoryType={setCategoryType}
                  />
                }
              </span>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                marginTop: "4rem",
              }}
            >
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
            </div>
          ) : (
            <Grid container spacing={3} className={classes.mainGrid}>
              {courses.map(
                (
                  {
                    ttitle,
                    tid,
                    vtid,
                    tnuggets,
                    tn,
                    nugcnt,
                    sub_date,
                    comp_date,
                    tobj,
                    cobj,
                  },
                  idx
                ) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={idx + "sdlkfd"}
                    onClick={() => handleLocation(tid, tn, proGm)}
                  >
                    <CardLarge
                      tid={tid}
                      title={tn}
                      count={nugcnt}
                      sub_date={sub_date}
                      comp_date={comp_date}
                      tobj={tobj}
                      cobj={cobj}
                    />
                  </Grid>
                )
              )}
            </Grid>
          )}

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "2.5rem",
            }}
          >
            {courses.length === 0 ? (
              <div
                style={{
                  height: "75vh",
                  width: "100vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "grey",
                  fontFamily: `myfontregular`,
                }}
              >
                <AnimatePresence>
                  <motion.div
                    className="slick-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 1, ease: "easeInOut" },
                    }}
                  >
                    <img
                      src={noThingIsHere}
                      alt="Nothing is here yet"
                      title="Nothing is here yet"
                      style={{ width: "30rem" }}
                    />
                  </motion.div>
                </AnimatePresence>
              </div>
            ) : null}
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={handleCloseA}
            message="Copied the link..!"
          >
            <Alert onClose={handleCloseA} severity="success">
              {courseData.length} Results
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
}

export default ExploreCourseView;
