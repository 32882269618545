import React from "react";
import { StyledCard } from "./LearningPathCard.styles";
import { ReactComponent as LPIcon } from "../../../assets/svg/LP_icon.svg";
import {
  Bookmark,
  BookmarkBorderOutlined,
  StarRounded,
} from "@material-ui/icons";
import { CardContent } from "@material-ui/core";

export default function ContentCard({ otype, title, dur, tid, ...rest }) {
  const divColorCodes = ['#E2D2FE!important','#FDE1AB!important', '#CCF0BF!important', '#FFD27E!important', '#BAE5F4!important', '#EECFCF!important'];

  return (
    <StyledCard style={{ color: "black" }} color={divColorCodes[rest.index % divColorCodes.length]}>
      <CardContent
        className="card-content"        
        onClick={() => {
          const color = divColorCodes[rest.index % divColorCodes.length];
          rest.handleLocation(color);
        }}
        style={{
          height: "18rem",
          justifyContent: "start",
        }}
      >
        <LPIcon />
        <div className="card-content--header">
          <div className="card-content--header-star-container">
            <StarRounded className="star" />
            <span className="rating" style={{ margin: "4px" }}>
              {rest?.star ? rest.star.toFixed(1) : "0.0"}
            </span>
          </div>
        </div>
        <div className="para-container">
          <p>{title}</p>
        </div>
      </CardContent>
      <div className="card-actions">
        <div className="card-actions--footer">
          {rest.points !== undefined && (
            <div className="card-actions--footer-time"></div>
          )}

          {rest?.bookmarkPage && (
            <div className="bookmark_date-container">
              <p className="bookmark_date-text">Bookmark Date</p>
              <span>:</span>
              <p className="bookmark_date-date">{rest.date}</p>
            </div>
          )}
          {rest?.bookmarkPage && (
            <Bookmark className="bookmark-icon fill" onClick={rest.onClick} />
          )}
          {rest?.bookmark && !rest?.bookmarkPage && (
            <Bookmark
              className="bookmark-icon fill"
              onClick={() => rest.handleBookmark(1, tid, rest.index)}
            />
          )}
          {!rest?.bookmark && !rest?.bookmarkPage && (
            <BookmarkBorderOutlined
              style={{ color: "black" }}
              className="bookmark-icon"
              onClick={() => rest.handleBookmark(0, tid, rest.index)}
            />
          )}
        </div>
      </div>
    </StyledCard>
  );
}
