import React, { useCallback } from "react";
//React router
import { Link, useHistory } from "react-router-dom";

// Swiper
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { breakpoints } from "../utils/Breakpoints";
import "swiper/components/navigation/navigation.scss";
import noThingIsHere from "../../../assets/nothing-here-icon.png";
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper";

// import { cardData } from '../../Data';
import CardLarge from "../../Cards/CardLarge/CardLarge";

import { API } from "aws-amplify";
import {  useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice"; 
import "../Carousel.scss"; 
import ContentCard from "../../Cards/ContentCard/ContentCard";
import Loading from "../../../components/Loading/Loading";

import config from "../../../aws-exports";
import { listBookMark } from "../../../api/api";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual]);
const BookmarkContentCarousel = ({ heading, cardData, loading, ...rest }) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const sliderRef = React.useRef(null);
  
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  // const [list, setList] = useState([]);
  const { isLoading, error, data: list } = useQuery({
    queryKey: ["/listBookmark"],
    queryFn: () => listBookMark(userDetails),
  });

  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
  }, []);
 
  const history = useHistory();
  
  /*  let src1 = `${config.aws_cloudfront_url}marketron-resources/images/program-images/${pid}.png`; */

  async function deleteBookmark(tid, type) {

    const badydata = {ur_id: userDetails.uData.ur_id,
      schema: config.schema};
  
    if (type === 1) {
      badydata.tid = tid;
    } else {
      badydata.objid = tid;
    }
    badydata.bookmark = false;

    const jsonString = JSON.stringify(badydata);
    const base64EncodedString = btoa(jsonString);
    
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: userDetails?.accessToken	
                  }
    };

    let book = list.filter((prev) => prev.tid !== tid);
    // setList(book);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        type === 1 ? "/updateTopicReport" : "/updateContentReport",
        bodyParam
      );

      // const requestBody = bodyParam;
      //   const jsonString = JSON.stringify(requestBody);
      //   const base64EncodedString = btoa(jsonString);
      //   const reportInfo = `/updateTopicReport?json=${encodeURIComponent(
      //     base64EncodedString
      //   )}`;
      //   const response = await API.post(
      //     config.aws_cloud_logic_custom_name,
      //     reportInfo,
      //     {
      //       headers: {
      //             "Content-Type": "application/json",
      //             Accept: "application/json",
      //             Authorization: userDetails?.accessToken	
      //           },
      //     }
      //   );
    } catch (err) {
      console.error(err);
    }
  }
 
  const handleLocation = (data, btype, tid) => {
    let breadcrumb = { ...userDetails };
    let value = data[0];
    if (btype === 1) {
      breadcrumb.location = {
        from: breadcrumb.location === undefined ? "Bookmarks" : "Bookmarks",
        courseName: value.title,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`course?id=${tid}`);
    } else {
      breadcrumb.location = {
        from: breadcrumb.location === undefined ? "Bookmarks" : "Bookmarks",
        contentName: value.title,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`content?id=${tid}`);
    }
  };

  return (
    <div
      className="carousel-container"
      role="main"
      aria-label="My bookmarked course section"
    >
      <div className="heading-container">
        <h2 className="carousel-heading">{heading}</h2>
        {!loading && cardData?.length >= 10 ? (
          <Link
            to="viewallcontent"
            style={{
              marginRight: "2rem",
              fontSize: "1.7rem",
              fontWeight: "600",
            }}
            className="viewAllActive"
            // onClick={handleViewAll}
          >
            {`${userDetails?.languagejson?.Home_Page?.View_all || "View all"}`}
          </Link>
        ) : (
          ""
        )}
      </div>
      <div className="swiper_wrapper">
        {!loading && cardData && cardData?.length > 0 && (
          <Swiper
            ref={sliderRef}
            breakpoints={breakpoints}
            spaceBetween={8}
            slidesPerGroup={4}
            slidesPerView={4}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onInit={() => {}}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: false }}
            className="slide-container"
          >
            {cardData.map(
              (
                {
                  title,
                  vtid,
                  tid,
                  otype,
                  ttitle,
                  type,
                  pname,
                  objid,
                  dur,
                  noofnuggets,
                  pcount,
                  starAvg,
                  ref_type,
                  oname,
                  sortdt,
                  dtls,
                  bk,
                  ravg,
                  tname,
                  bookmarkPage,
                  bookmark_date,
                  star,
                  sub_date,
                  comp_date,
                },
                idx
              ) => (
                <SwiperSlide
                  key={"ddlJ" + idx}
                  className="slider-container"
                  tabIndex={1}
                >
                  {type === 1 && (
                    <CardLarge
                      bookmarkPage={true}
                      tid={tid}
                      date={bookmark_date}
                      title={tname[0]?.title}
                      star={star}
                      onClick={() => deleteBookmark(tid, type)}
                      handleLocation={() => handleLocation(tname, type, tid)}
                      sub_date={sub_date}
                      comp_date={comp_date}
                    />
                  )}
                  {type === 2 && (
                    <ContentCard
                      bookmarkPage={true}
                      date={bookmark_date}
                      otype={tname[0]?.otype}
                      title={tname[0]?.title}
                      star={star}
                      onClick={() => deleteBookmark(tid, type)}
                      handleLocation={() => handleLocation(tname, type, tid)}
                    />
                  )}
                </SwiperSlide>
              )
            )}
          </Swiper>
        )}
        <span
          id="swiper-right"
          className={`arrow arrow-right ${
            cardData?.length === 0 ? "hide" : ""
          }`}
          onClick={handleNext}
        ></span>
        <span
          id="swiper-left"
          className={`arrow arrow-left ${cardData?.length === 0 ? "hide" : ""}`}
          onClick={handlePrev}
        ></span>
        <div className="flex-loading">
          {loading && (
            <React.Fragment>
              {[...Array(4).fill(0)].map((_, index) => (
                <Loading />
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
      {cardData?.length === 0 && (
        <div
          className="slick-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{
            opacity: 0,
            transition: { duration: 1, ease: "easeInOut" },
          }}
        >
          <img
            src={noThingIsHere}
            alt="Nothing is here yet"
            title="Nothing is here yet"
          />
          {/* <h3>
                    No {props.viewtype === "course" ? "Course" : "Content"} Data
                  </h3> */}
        </div>
      )}
    </div>
  );
};

export default React.memo(BookmarkContentCarousel);
