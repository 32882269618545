import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import config from "../../aws-exports";
import CardSmall from "../../components/Cards/CardSmall/CardSmall";

const useStyles = makeStyles(() => ({
  "@media (max-width: 770px)": {
    MobileView: {
      "& > div > div > span": {
        display: "none!important",
      },
      "& > div > div > div > div": {
        display: "block",
        "@media (min-width: 770px)": {
          flexWrap: "wrap",
          gap: "1rem",
        },
        "& > div": {
          margin: "1.5rem 0rem",
          marginRight: "0!important",
          width: "auto!important",
        },
      },
    },
  },
  MobileView: {
    "& > div > div > div > div": {
      "@media (min-width: 770px)": {
        flexWrap: "wrap",
        gap: "2rem",
        justifyContent: "center",
      },
    },
    "& > div > div > span": {
      "@media (min-width: 770px)": {
        display: "none!important",
      },
    },
  },
}));

export default function ExploreTopics() {
  const classes = useStyles();
  let userDetails = useSelector(authData);

  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  return (
    <div>
      <div className={classes.MobileView}>
        <CardSmall
          heading={`${userDetails?.languagejson?.Home_Page?.All_Topics ||
            "All Topics"}`}
        />
      </div>
    </div>
  );
}
