import React, { useRef, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import {
  Fade,
  Modal,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import config from "../../aws-exports";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import downloadPdfSvg from "../../components/svg/downloadpdf.png";
import moment from "moment";
import ENABLEDCERT from "../../assets/ENABLEDCERT.png";

import "../../components/Cards/CardSmall/CardSmall.scss";
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactToPdf from "react-to-pdf";
import "./ProfilePopUp.scss";
import { LinkedinShareButton } from "react-share";
import ShareIcon from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const options = {
  orientation: "landscape",
};
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "2rem 1rem",
    width: "60%",
    borderRadius: ".8rem",
    outline: "none",
    "@media (max-width: 770px)": {
      width: "90%",
    },
    "@media (max-width: 370px)": {
      width: "95%",
    },
  },
  certificateSliderSection: {
    position: "relative",
    width: "100%",
  },
  ONDCCertificate: {
    width: "850px",
    "@media (max-width: 1024px)": {
      width: "100%",
    },
  },
  shareButton: {
    padding: "0.9rem 1rem!important",
    display: "flex",
    color: "#0274B3!important",
    alignItems: "center",
    gap: "1rem",

    float: "right",
  },
}));
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
export default function ProfilePopUp({
  profileDetails,
  open,
  setOpen,
  certificateList,
  sindex,
}) {
  const swiperRef = useRef(null);
   const ref = useRef();
  const certificateDiv = React.useRef();
  const classes = useStyles();
  let userDetails = useSelector(authData);
  useEffect(() => { }, []);


  const handleClose = () => {
    setOpen(false);
  };


  async function downloadcode(sData, idx) {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#certpdf" + idx), {
      allowTaint: true,
      useCORS: true,
      logging: true,
    });
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight + 200);
    pdf.save(
      sData?.ttitle?.length >= 90
        ? sData?.ttitle.substring(0, 90)
        : sData?.ttitle + ".pdf"
    );
  }

  return (
    <div>
      <>
        {config.aws_org_id !== "AXIATA" && config.aws_org_id !== "NSECORP" && config.aws_org_id !== "NSEKAR" && (
          <>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <Swiper
                    ref={swiperRef}
                    spaceBetween={30}
                    initialSlide={sindex}
                    pagination={{ clickable: true }}
                    navigation={true}
                    className="slider-container"
                  >
                    {certificateList.map((item, idx) => (
                      <SwiperSlide
                        key={idx}
                        className="slider-container"
                        tabIndex={1}
                      >
                        <div
                          style={{ textAlign: "right", paddingRight: "20px" }}
                        >
                          <button
                            style={{
                              background: "transparent",
                              border: "none",
                              height: "3rem",
                              width: "3rem",
                              marginRight: "2rem",
                              cursor: "pointer",
                              marginTop: "8px",
                            }}
                            onClick={() => downloadcode(item, idx)}
                          >
                            <img
                              src={downloadPdfSvg}
                              alt="download"
                              height="100%"
                            />
                          </button>
                          <button
                            onClick={handleClose}
                            style={{
                              border: "none",
                              background: "transparent",
                              cursor: "pointer",
                            }}
                          >
                            <CloseIcon fontSize="large" />
                          </button>
                        </div>
                        <div
                          className={classes.certificateSliderSection}
                          id={"certpdf" + idx}
                          ref={certificateDiv}
                        >
                          <div
                            style={{
                              fontFamily: "sans-serif",
                              color: "#282460",
                              overflowY: "hidden",
                              overflowX: "auto",
                              position: "relative",
                            }}
                          >
                            <img
                              src={ENABLEDCERT}
                              alt="SSFL Certificate"
                              width="850px"
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "26rem",
                                left: "3.5rem",
                                width: "78rem",
                                textAlign: "center",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "3rem",
                                  margin: "0.5rem 0rem 0rem",
                                  fontFamily: "Lato",
                                  color: "#2591ce",
                                  fontWeight: "600",
                                }}
                              >
                                {userDetails.name}
                              </p>
                              <p
                                style={{
                                  fontSize: "1.5rem",
                                  margin: "1rem 0rem 0rem",
                                  fontFamily: "Lato",
                                  color: "#3D347E",
                                  fontWeight: "500",
                                }}
                              >
                                On{" "}
                                {moment(item?.compdate).format("DD MMM YYYY")}
                              </p>
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                top: "40rem",
                                left: "4rem",
                                width: "77rem",
                                textAlign: "center",
                                lineHeight: "2rem",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "2rem",
                                  fontFamily: "Lato",
                                  color: "#2591ce",
                                  fontWeight: "600",
                                }}
                              >
                                {item?.ttitle}
                              </p>
                              <div
                                style={{
                                  width: "fit-content",
                                  position: "absolute",
                                  top: "12rem",
                                  marginLeft: "1.5rem",
                                }}
                              >
                                <img
                                  alt="Certificate"
                                  style={{ width: "250px" }}
                                  src={
                                    userDetails.themeDark === "dark"
                                      ? `https://${config.DOMAIN
                                      }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-light.png`
                                      : `https://${config.DOMAIN
                                      }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/${config.aws_org_id
                                      }.png`
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Fade>
            </Modal>
          </>
        )}
      </>

      {config.aws_org_id === "AXIATA" && (
        <Dialog open={open} maxWidth="xl" scroll="body" onClose={handleClose}>
          <DialogContent className="dialog-container">
            <>
              <div
                className="course-certificate"
                ref={ref}
                id={"#certpdf" + profileDetails.tid}
              >
                <div className="course-certificate_container">
                  <h1 className="course-certificate_container_name">
                    {profileDetails.name}
                  </h1>
                </div>

                <div className="course-certificate_details">
                  <div className="course-certificate_details_top">
                    <p className="course-certificate_details_top-head">
                      has completed the course
                    </p>
                    <p className="course-certificate_details_top-title">
                      {profileDetails?.ttitle}
                    </p>
                  </div>

                  <div className="course-certificate_details_down">
                    <p className="course-certificate_details_down-date">
                      Date :{" "}
                      {moment(profileDetails?.compdate).format("DD/MM/YYYY")}{" "}
                    </p>
                    <p className="course-certificate_details_down-grade">
                      Completion Progress : 100%
                    </p>
                  </div>
                </div>
              </div>

              <div className="certificate_actions">
                <ReactToPdf
                  targetRef={ref}
                  filename="certificate.pdf"
                  options={options}
                  x={8}
                  y={8}
                  scale={1}
                >
                  {({ toPdf }) => (
                    <button className="certificate_actions-btn" onClick={toPdf}>
                      Download
                    </button>
                  )}
                </ReactToPdf>
              </div>
            </>
          </DialogContent>
        </Dialog>
      )}

      {config.aws_org_id === "NSECORP" && (
        <Dialog open={open} maxWidth="xl" scroll="body" onClose={handleClose}>
          <DialogContent className="dialog-container">
            <>
              <div style={{ display: "grid" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "end",
                    gap: "12px",
                  }}
                >
                  <a
                    download={`${profileDetails?.ttitle}.png`}
                    href={`https://${config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/certificate/${profileDetails?.tid
                      }/${userDetails?.uData?.ur_id}_certificate.png`}
                    style={{
                      border: "0",
                      margin: "0",
                      padding: "0",
                      float: "right",
                    }}
                  >
                    <DownloadIcon fontSize="large" />
                  </a>

                  <LinkedinShareButton
                    url={`https://${config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/certificate/${profileDetails?.tid
                      }/${userDetails?.uData?.ur_id}_certificate.png`}
                    className={classes.shareButton}
                  >
                    <LightTooltip title="Share on linkedin">
                      <IconButton>
                        <ShareIcon fontSize="large" />
                      </IconButton>
                    </LightTooltip>{" "}
                  </LinkedinShareButton>
                </div>
                <div
                  style={{
                    fontFamily: "sans-serif",
                    color: "#282460",
                    overflowY: "hidden",
                    overflowX: "auto",
                    position: "relative",
                  }}
                  ref={ref}
                >
                  <img
                    src={`https://${config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/certificate/${profileDetails?.tid
                      }/${userDetails?.uData?.ur_id}_certificate.png`}
                    alt="ONDC Certificate"
                    className={classes.ONDCCertificate}
                  />
                </div>
              </div>
            </>
          </DialogContent>
        </Dialog>
      )}

      {config.aws_org_id === "NSEKAR" && (
        <Dialog open={open} maxWidth="xl" scroll="body" onClose={handleClose}>
          <DialogContent className="dialog-container">
            <>
              <div style={{ display: "grid" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "end",
                    gap: "12px",
                  }}
                >
                  <a
                    download={`${profileDetails?.ttitle}.png`}
                    href={`https://${config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/certificate/${profileDetails?.tid
                      }/${userDetails?.uData?.ur_id}_certificate.png`}
                    style={{
                      border: "0",
                      margin: "0",
                      padding: "0",
                      float: "right",
                    }}
                  >
                    <DownloadIcon fontSize="large" />
                  </a>

                  <LinkedinShareButton
                    url={`https://${config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/certificate/${profileDetails?.tid
                      }/${userDetails?.uData?.ur_id}_certificate.png`}
                    className={classes.shareButton}
                  >
                    <LightTooltip title="Share on linkedin">
                      <IconButton>
                        <ShareIcon fontSize="large" />
                      </IconButton>
                    </LightTooltip>{" "}
                  </LinkedinShareButton>
                </div>
                <div
                  style={{
                    fontFamily: "sans-serif",
                    color: "#282460",
                    overflowY: "hidden",
                    overflowX: "auto",
                    position: "relative",
                  }}
                  ref={ref}
                >
                  <img
                    src={`https://${config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/certificate/${profileDetails?.tid
                      }/${userDetails?.uData?.ur_id}_certificate.png`}
                    alt="ONDC Certificate"
                    className={classes.ONDCCertificate}
                  />
                </div>
              </div>
            </>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
