import React, { useEffect, useCallback, useState } from "react";
import "./CardSmall.scss";
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { breakpoints2 } from "../../Carousel/utils/Breakpoints";
import "swiper/components/navigation/navigation.scss";

import { useHistory, useLocation } from "react-router-dom";
import config from "../../../aws-exports";

import { setCategoryData } from "../../../redux/CategoryData/CategoryData";

import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../Loading/Loading";
import { getCategories } from "../../../api/api";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";


const CardSmall = ({ heading }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userDetails = useSelector(authData);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const sliderRef = React.useRef(null);
 
  const { isLoading, error, data } = useQuery({
    queryKey: ["getCategories", config.aws_org_id],
    queryFn: () => getCategories(userDetails),
  });

  useEffect(() => {
    if (data) {
      dispatch(setCategoryData(data));
    }
  }, [JSON.stringify(data)]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);


  const handleLocation = (name, id) => {
    const breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from:
        location.pathname.split("/").indexOf("home") > -1 ? "Home" : "Explore",
      topicName: name,
      topicId: id,
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`explore/${name.replace(/\s/g, "-")}/${id}`);
  };

  if (error) return "An error has occurred: " + error.message;


  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1.2rem",
    },
  }));


  return (
    <>
      {!isLoading && data.length > 0 && (
        <div className="carousel-container">
          <div>
            <h2 className="carousel-heading">{heading}</h2>
          </div>
          <div className="swiper_wrapper">
            {!isLoading && data.length > 0 && (
              <Swiper
                ref={sliderRef}
                breakpoints={breakpoints2}
                spaceBetween={178}
                slidesPerGroup={4}
                slidesPerView={4}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: false }}
                className="slide-container"
              >
                {data?.map(({ name, id, ct_img, tp_count, ct_count, lp_count }, idx) => (


                  <SwiperSlide
                    key={idx}
                    className="slider-container"
                    tabIndex={1}
                    onClick={() => handleLocation(name, id)}
                  >
                    <BootstrapTooltip
                            title=
                            {
                              <div>
                                <h3>{name}</h3>
                                <div>Courses: {tp_count} </div>
                                <div>Collaterals: {ct_count}</div>
                                <div>Learning Paths:{lp_count} </div>
                              </div>
                            }
                          >
                    <div className="card-sm">
                      <div
                        style={{
                          backgroundImage: `linear-gradient(to right bottom, rgba(0,0,0,.4), rgba(0,0,0,.4)), url(${config.aws_cloudfront_url
                            }${config.aws_org_id.toLowerCase()}-resources/images/category-images/${ct_img})`,
                        }}
                        className="back-img"
                      >
                        <div className="card-img-overlay text-white d-flex justify-content-center align-items-end">
                          <h3>{name}</h3> 
                         
                        </div>
                      </div>
                    </div>
                    </BootstrapTooltip>
                  </SwiperSlide>

                ))}
              </Swiper>
            )}
            <span className="arrow arrow-right" onClick={handleNext}></span>
            <span className="arrow arrow-left" onClick={handlePrev}></span>

            <div className="flex-loading">
              {isLoading && (
                <React.Fragment>
                  {[...Array(4).fill(0)].map((_, index) => (
                    <Loading key={index} />
                  ))}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(CardSmall);
