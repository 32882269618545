import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import config from "../../aws-exports";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { Button, Grid, Skeleton, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactUsImg from "../../assets/Contact us.png";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const MyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: "1.2rem",
  },
}));

function ContactUs(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const useStyles = makeStyles((theme) => ({
    headerStyle: {
      textAlign: "left",
      padding: "2rem 3rem",
      borderRadius: "5px",
      "@media (max-width: 600px)": {
        padding: "0",
      },
    },
    paperIconSection: {
      padding: "1rem",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      display: "flex",
      width: "fit-content",
      margin: "2rem auto",
    },
    allTopicSkeleton: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "2rem",
      margin: "4rem 10px 0",
    },
  }));
  const classes = useStyles();
  let userDetails = useSelector(authData);
  useEffect(() => {
    getContactUs();
  }, []);

  async function getContactUs() {
    setLoading(true);
    // const bodyParam = {
    //   body: {
    //     oid: config.aws_org_id,
    //     schema: config.schema,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };

    // try {
    //   const response = await API.post(
    //     config.aws_cloud_logic_custom_name,
    //     "/getContactUs",
    //     bodyParam
    //   );

    try {
      const requestBody = {
        oid: config.aws_org_id,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getContactUs?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	    Accept: "application/json",	
      Authorization: userDetails?.accessToken	
          },
        }
      );
      //const { body } = response;
      setData(response);
      setLoading(false);
    } catch (err) {
      throw err;
    }
  }

  return (
    <div style={{ margin: "3rem 1rem" }}>
      {loading ? (
        <Typography component="h1">
          <Grid container spacing={2} style={{ margin: "0", padding: "0" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.headerStyle}
            >
              <Skeleton
                style={{ width: "20rem", height: "3rem" }}
                animation="wave"
                variant="rectangular"
              />
              <Skeleton
                style={{ width: "100%", height: "4rem", margin: "3rem 0rem" }}
                animation="wave"
                variant="rectangular"
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={"3rem"}
                  height={"3rem"}
                  style={{}}
                />
                <Skeleton
                  style={{ width: "25rem", height: "3rem" }}
                  animation="wave"
                  variant="rectangular"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={"3rem"}
                  height={"3rem"}
                  style={{}}
                />
                <Skeleton
                  style={{ width: "25rem", height: "3rem" }}
                  animation="wave"
                  variant="rectangular"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={"3rem"}
                  height={"3rem"}
                  style={{}}
                />
                <Skeleton
                  style={{ width: "25rem", height: "3rem" }}
                  animation="wave"
                  variant="rectangular"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={"3rem"}
                  height={"3rem"}
                  style={{}}
                />
                <Skeleton
                  style={{ width: "25rem", height: "3rem" }}
                  animation="wave"
                  variant="rectangular"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={"3rem"}
                  height={"3rem"}
                  style={{}}
                />
                <Skeleton
                  style={{ width: "25rem", height: "3rem" }}
                  animation="wave"
                  variant="rectangular"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ padding: "1rem" }}
            >
              <div className={classes.headerStyle}>
                <Skeleton variant="circular" width={300} height={300} />
              </div>
            </Grid>
          </Grid>
        </Typography>
      ) : (
        <Grid container spacing={2} style={{ margin: "0", padding: "0" }}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: "1rem" }}>
            <div className={classes.headerStyle}>
              <h1>{`${userDetails?.languagejson?.header?.contact_us ||
                "Contact us"}`}</h1>
              <p style={{ fontSize: "1.7rem", margin: "2rem 0rem" }}>
                If you have any questions, contact us via Email or phone call.
                We will be very happy to help you!
              </p>
              <div style={{ display: "grid", gap: "3rem" }}>
                {data?.phoneno.length > 0 && (
                  <div style={{ display: "flex", gap: "2rem" }}>
                    <MyTooltip title="Phone Number">
                      <PhoneIcon
                        style={{
                          fontSize: "2.2rem",
                          color: "var(--color-primary)",
                        }}
                      />
                    </MyTooltip>
                    <b style={{display: "flex", flexWrap: "wrap", gap: "1rem",}}>
                      {data?.phoneno?.map((number) => {
                        return <div>{number}.</div>;
                      })}
                    </b>
                  </div>
                )}
                {data?.mailid.length > 0 && (
                  <div style={{ display: "flex", gap: "2rem" }}>
                    <MyTooltip title="Email ID">
                      <EmailIcon
                        style={{
                          fontSize: "2.2rem",
                          color: "var(--color-primary)",
                        }}
                      />
                    </MyTooltip>
                    <div>
                      {data?.mailid.map((mail, index) => (
                        <p key={index}>
                          <a
                            href={`mailto:${mail}`}
                            title={`mailto:${mail}`}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <b>
                              <span>{mail}</span>
                            </b>
                          </a>
                        </p>
                      ))}
                    </div>
                  </div>
                )}
                {data?.website !== "" && (
                  <div style={{ display: "flex", gap: "2rem" }}>
                    <MyTooltip title="Our Website">
                      <LanguageIcon
                        style={{
                          fontSize: "2.2rem",
                          color: "var(--color-primary)",
                        }}
                      />
                    </MyTooltip>
                    <b>{data?.website}</b>
                  </div>
                )}
                {(data?.timings?.fromDate !== "" ||
                  data?.timings?.toDate !== "") && (
                  <div style={{ display: "flex", gap: "2rem" }}>
                    <MyTooltip title="Our Timing">
                      <AccessTimeFilledIcon
                        style={{
                          fontSize: "2.2rem",
                          color: "var(--color-primary)",
                        }}
                      />
                    </MyTooltip>
                    <div style={{display: "flex", flexWrap: "wrap", gap: "1rem",}}>
                      {(data?.timings?.fromDate !== "" ||
                        data?.timings?.toDate !== "") && (
                        <b>
                          {data?.timings?.fromDate} - {data?.timings?.toDate}
                        </b>
                      )}
                      {(data?.timings?.fromTime !== null ||
                        data?.timings?.totime !== null) && (
                        <b>
                          {new Date(data?.timings?.fromTime).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}{" "}
                          -{" "}
                          {new Date(data?.timings?.totime).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                        </b>
                      )}
                    </div>
                  </div>
                )}
                {data?.address !== "" && (
                  <div style={{ display: "flex", gap: "2rem" }}>
                    <MyTooltip title="Our Location">
                      <LocationOnIcon
                        style={{
                          fontSize: "2.2rem",
                          color: "var(--color-primary)",
                        }}
                      />
                    </MyTooltip>
                    <b>{data?.address}.</b>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: "1rem" }}>
            <div
              className={classes.headerStyle}
              style={{ textAlign: "center" }}
            >
              <img src={ContactUsImg} alt="Contact Us" width={"80%"} />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default ContactUs;
