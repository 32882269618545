import React, { useState} from 'react';
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Box, Button, Popover  } from "@mui/material";
import { callAPI, } from "../../api/api";
import config from "../../aws-exports";
//import Swal from "sweetalert2";
//import { Theme } from "../../aws-exports";
import { ReactComponent as SaveSVG } from "../../assets/svg/Save.svg";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import {  useDispatch } from "react-redux";
import { awsSignIn } from "../../redux/auth/authSlice";

const PostFooterView = ({ row, classes, hotRefeth, userDetails, history, page, setMainLoader  }) => {
  const dispatch = useDispatch();
  const callAPIFunction = async (buttonType) => {
    setMainLoader(true);
    try {
      let obj = {};
      obj.method = 'post';
      obj.body = {};
      obj.body.ur_id = userDetails?.uData?.ur_id;
      obj.body.schema = config.schema;
      obj.body.postid = row.postid;
      if (buttonType === 'like') {
        obj.apiName = `/like`;
        obj.body.like = !row.userlikedpost; 
      } else if (!row.usersavedpost && buttonType === 'save') {
        obj.apiName = `/userSavedPost`;
      } else if (row.usersavedpost && buttonType === 'save') {
        obj.apiName = `/deleteUserSavedPost`;
        obj.method = 'del';
      }
      console.log(obj,"objobj");
      const res = await callAPI(obj);
      hotRefeth();
      
      return res;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.data = err;
      
      return obj;
    }
  }
  const handleButtonClick = async (buttonType) => {
    // Define the common logic for button click here
    let res;
    switch (buttonType) {
      case 'like':
        // Handle like button click
        res = await callAPIFunction(buttonType);
        // Swal.fire({
        //   title: res.statusCode === 200 ? "Success" : "Error",
        //   text: res.message,
        //   icon: res.statusCode === 200 ? "success" : "error",
        //   confirmButtonColor: Theme.color.primary,
        //   confirmButtonText: "Ok",
        // })
        setMainLoader(false);
        console.log('Like button clicked');
        break;
      case 'comment':
        // Handle comment button click 
        if(page === undefined){
          let udata = { ...userDetails };
          udata.postDataByid = row;
          dispatch(awsSignIn(udata));
          //history.push("/social-learning/InDetails");
          window.location.href = "#/social-learning/InDetails";
        } 
        break;
      case 'share':
        // Handle share button click
        console.log('Share button clicked');
        break;
      case 'save':
        // Handle save button click
        console.log( "11");
        res = await callAPIFunction(buttonType);
        // Swal.fire({
        //   title: res.statusCode === 200 ? "Success" : "Error",
        //   text: res.message,
        //   icon: res.statusCode === 200 ? "success" : "error",
        //   confirmButtonColor: Theme.color.primary,
        //   confirmButtonText: "Ok",
        // })
        setMainLoader(false);
        console.log('Save button clicked');
        break;
      case 'more':
        // Handle more button click
        console.log('More button clicked'); 
        break;
      default:
        break;
    }
  };


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.userMenuItems} style={{ margin: '2rem 0rem 0rem' }}>
      <Button
       style={{color:'black'}}
        className={`${classes.userMenuButton} ${row?.userlikedpost ? classes.footerActiveButton : ""}`}
        onClick={() => {
          !row.userlikedpost && row.ur_id !== userDetails?.uData?.ur_id && handleButtonClick('like');
        }}
      >
        <FavoriteBorderOutlinedIcon className={classes.socialIcons} />
        {row?.likes || 0}
      </Button>
      <Button
       style={{color:'black'}}
        className={classes.userMenuButton}
        onClick={() =>  handleButtonClick('comment')}
      >
        <ChatBubbleOutlineOutlinedIcon className={classes.socialIcons} />
         {row?.comments_count || (row?.json_comments?.length || 0)} comm
      </Button>
      <Button
       style={{color:'black'}}
        className={classes.userMenuButton}
        onClick={() => handleButtonClick('share')}
      >
        <ShareOutlinedIcon className={classes.socialIcons} />
        Share
      </Button>
      <Button
        style={{color:'black'}}
        className={`${classes.userMenuButton} ${row?.usersavedpost ? classes.footerActiveButton : ""}`}
        onClick={() => {  !row?.usersavedpost && handleButtonClick('save')}}
      >
        <SaveSVG className={classes.saveIconsSVG} />
       { row?.usersavedpost ? "Saved" :"Save"}
      </Button>
      <Button onClick={handleClick}>
        <MoreHorizOutlinedIcon className={classes.socialIcons} />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={1}>
          <Button className={classes.userMenuButton}><FlagOutlinedIcon fontSize='large' />Report to</Button>
        </Box>
      </Popover>
    </div>
  );
};

export default PostFooterView;