import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Slick from "../../components/Carousel/Slick";
import { SwiperSlide } from "swiper/react";
import { useHistory } from "react-router-dom";
//React router

import { setViewAllArrData } from "../../redux/HomeData/HomeDataSlice";
import "../Explore/Explore.scss";
import LearningPathCard from "../../components/Cards/LearningPathCard/LearningPathCard";

function LearningPaths({ page, learningData, isLoading, ...rest }) {

  const [learningsData, setLearningData]= useState(learningData);
  const userDetails = useSelector(authData);
  const [open, setOpen] = useState(false);
  const [adProductF, setAdProductF] = useState("");
  const [mrToolsF, setMrToolsF] = useState("");
  const [durF, setdurF] = useState("");
  const [ctype, setctypeF] = useState("");
  const [stype, setStypeF] = useState("CON");
  const [stypeV, setStypeFV] = useState("Newest");
  const [openS, setOpenS] = useState(false);
  const [anchorElS, setAnchorElS] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
   const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const show = () => setvisible(!visible);
  const hide = () => setvisible(false);
  const[themecolor, setColors]= useState("");
 
  const addAndRemoveBookmarkContent = async (val, id, idx) => {};
  
  const handlelearningLocation = (lpid, lname, color) => {
    const breadcrumbs = { ...userDetails };
    breadcrumbs.location = {
      from: page,
      topicName: breadcrumbs.location.topicName,
      topicId: breadcrumbs.location.topicId,
      lname: lname,
      lpid: lpid,
    };
    breadcrumbs.learning = {
      lname: lname,
      lpid: lpid,
    };
    breadcrumbs.learningPath = true;
    dispatch(awsSignIn(breadcrumbs));
    setColors(color);  
    localStorage.setItem("themecolor", color);

    history.push({
      pathname: `/learning`,
      state: { themecolor: color },
    });
      
  };


  function ViewAll(){
    dispatch(setViewAllArrData(learningsData));
    history.push(`/viewallpath`)
     }


  return (
    <div>
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
              }}
        >
          <div>
            {!isLoading && learningData?.length === 0 ? (
              <></>
            ) : (
              <>
                <Slick
                  heading={`${userDetails?.languagejson?.Home_Page
                    ?.Learning_path || "Learning paths"}`}
                  anchorEl={anchorEl}
                  adProductF={adProductF}
                  open={open}
                  ctype={ctype}
                  mrToolsF={mrToolsF}
                  durF={durF}
                  filter="true"
                  viewtype="Learning"
                  anchorElS={anchorElS}
                  stypeV={stypeV}
                  openS={openS}
                  stype={stype}
                  visible={visible}
                  show={show}
                  hide={hide}
                  userDetails={userDetails?.location?.from}
                  data={learningData}
                  oCData={learningData}
                  loading={isLoading}
                  viewall={ViewAll}
                  classDefine={rest.classDefine}
                >
                  {learningData.map(
                    ({ lp_id, lname, rate, bookmark, points }, idx) => {
                      return (
                        <SwiperSlide key={idx} className="slider-container">
                          <LearningPathCard
                            key={lp_id + "lpid"}
                            star={rate}
                            title={lname}
                            bookmark={bookmark}
                            tid={lp_id}
                            handleBookmark={addAndRemoveBookmarkContent}
                            handleLocation={(color) => handlelearningLocation(lp_id, lname, color)} 
                
                             index={idx}
                            points={points}
                            dur={123}
                          />
                        </SwiperSlide>
                      );
                    }
                  )}
                </Slick>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}

export default LearningPaths;
