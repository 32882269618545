 import React, { useState, useEffect } from "react";

import { API} from "aws-amplify";

import { awsSignIn } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";

 import { CircularProgress } from "@mui/material";

import { useHistory, useLocation } from "react-router-dom";
 
import { useForm } from "../../Hooks/useForm";
 import { useDispatch } from "react-redux";

import config from "../../aws-exports";

 import { useQueryParams } from "../../Hooks/useQueryParams";
 
import Cookies from "js-cookie";
import axios from "axios";
import AWS from "aws-sdk";
import jwt_decode from "jwt-decode";
import { StorageHelper } from "@aws-amplify/core";
import { Backdrop, makeStyles } from "@material-ui/core";
 
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: "500px",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  
}));
const LoginExcelLearn = () => {
  const userDetails = useSelector(authData);
  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();
  const dispatch = useDispatch();
  const [login, setLogin] = useState(true);
  const storage = new StorageHelper().getStorage();
  let code = "";
  const classes = useStyles();
  const {
    values, 
  } = useForm({
    showPassword: false,
    username: location?.state?.username || "",
  });
  useEffect(() => { 
    setLogin(true);
    checkUserLogin();
  }, []);

 
  async function checkUserLogin() {
    let accToken = Cookies.get("access_token");
    var idToken = Cookies.get("id_token");

    if (
      accToken === undefined ||
      idToken === undefined ||
      accToken === null ||
      idToken === null ||
      accToken === "" ||
      idToken === ""
    ) {
      
      if (
        (query.get("code") === undefined || query.get("code") === null) &&
        (new URLSearchParams(window.location.search).get("code") ===
          undefined ||
          new URLSearchParams(window.location.search).get("code") === null)
      ) { 
        pageload();
      } else {
        code =
          query.get("code") === undefined || query.get("code") === null
            ? new URLSearchParams(window.location.search).get("code")
            : query.get("code");
        console.log("afterlogin",code)
        axios({
          method: "POST",
          url: config.auth_url + "oauth2/token",
          params: {
            grant_type: "authorization_code",
            client_id: config.aws_user_pools_web_client_id,
            //'redirect_uri': 'http://localhost:3000',
            redirect_uri: config.aws_cloudfront_lurl,
            code: code,
          },
          data: {},
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
          .then((resp) => {
            var decoded = jwt_decode(resp.data.id_token);
            console.log("decoded",decoded)
            fetchuser(resp, decoded);
          })
          .catch((error) => {
            pageload();
          });
      }
    } else {
      var decoded = jwt_decode(idToken);
      if (!AWS.config.region) {
        AWS.config.update({
          region: "ap-south-1",
        });
      }

       AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.aws_cognito_identity_pool_id,
        Logins: {
          [`cognito-idp.${"ap-south-1"}.amazonaws.com/${
            config.aws_user_pools_id
          }`]: idToken,
        },
      });

      var resp = {};
      resp.data = {};
      resp.data.id_token = idToken;
      resp.data.access_token = accToken;
      
      fetchuser(resp, decoded);
    }
  }
  async function fetchuser(resp, decoded) {
    AWS.config.region = "ap-south-1";

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.aws_cognito_identity_pool_id,
      Logins: {
        [`cognito-idp.${"ap-south-1"}.amazonaws.com/${
          config.aws_user_pools_id
        }`]: resp.data.id_token,
      },
    });
    Cookies.set("access_token", resp.data["access_token"]);
    Cookies.set("id_token", resp.data["id_token"]);
    const idTokenData = decodePayload(resp.data["id_token"]);
    const accessTokenData = decodePayload(resp.data["access_token"]);
   let userdata = {};
    userdata.id = AWS.config.credentials.identityId;
    storage.setItem(
      "CognitoIdentityServiceProvider." +
        config.aws_user_pools_web_client_id +
        ".LastAuthUser",
      idTokenData["cognito:username"]
    );
    storage.setItem(
      "CognitoIdentityServiceProvider." +
        config.aws_user_pools_web_client_id +
        "." +
        idTokenData["cognito:username"] +
        ".idToken",
      resp.data["id_token"]
    );
    storage.setItem(
      "CognitoIdentityServiceProvider." +
        config.aws_user_pools_web_client_id +
        "." +
        idTokenData["cognito:username"] +
        ".accessToken",
      resp.data["access_token"]
    );
    storage.setItem(
      "CognitoIdentityServiceProvider." +
        config.aws_user_pools_web_client_id +
        "." +
        idTokenData["cognito:username"] +
        ".refreshToken",
      resp.data["refresh_token"]
    );
    storage.setItem(
      "CognitoIdentityServiceProvider." +
        config.aws_user_pools_web_client_id +
        "." +
        idTokenData["cognito:username"] +
        ".clockDrift",
      "" + calculateClockDrift(accessTokenData["iat"], idTokenData["iat"]) + ""
    );
 
    storeData(decoded, userdata);
    
  }
 
  async function storeData(decoded, userdata) {
    userdata.cid = config.aws_user_pools_web_client_id;
    userdata.emailid = decoded.email;
    userdata.uid = config.aws_user_pools_id;
    userdata.email = decoded.email;
    userdata.location = {};
    userdata.themeDark = "light";
    userdata.location.from = "/home";
    if (!decoded.locale) {
      userdata.locale = "Default Team";
    } 
    userdata.schema = config.schema;

    await getUserDetails(userdata);
  }
  function pageload() {
    Cookies.remove("CloudFront-Policy");
    Cookies.remove("CloudFront-Key-Pair-Id");
    Cookies.remove("CloudFront-Signature");
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("id_token");
    localStorage.clear();
    sessionStorage.clear();
    //token
    // window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=enhanzed-axiata"`;
    //window.location.href = `${config.auth_url}logout?client_id=${config.aws_user_pools_web_client_id}&logout_uri=${config.aws_cloudfront_lurl}`;
 
      window.location.href = `${config.auth_url}login?client_id=${config.aws_user_pools_web_client_id}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${config.aws_cloudfront_lurl}`;
    
    //window.location.href = `${config.auth_url}login?client_id=${config.aws_user_pools_web_client_id}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${config.aws_cloudfront_lurl}`;
  }
  const decodePayload = (jwtToken) => {
    const payload = jwtToken.split(".")[1];
    try {
      return JSON.parse(Buffer.from(payload, "base64").toString("utf8"));
    } catch (err) {
      return {};
    }
  };
 
  const [btnLoading, setBtnLoading] = useState(false);
  
  const getUserDetails = async (userdata) => {
 
    try {
     
      const requestBody = userdata;
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getUserDetails?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
           Accept: "application/json",	
           Authorization: userDetails?.accessToken
          },
        }
      );
    
      if (response) {
        userdata.uData = response.body[0];
        userdata.locale = response.body[0].oid;
        if (response.body[0] !== undefined) {
          let authkey = localStorage.getItem("accessToken"); 
          const tokenrequestBody = {
            client_key: userdata.emailid,
            client_secret: config.secretKeyAfter, 
            schema: config.schema
          };
          const tokenjsonString = JSON.stringify(tokenrequestBody);
          const tokenbase64EncodedString = btoa(tokenjsonString);
          const tokenreportInfo = `/getUserToken?json=${encodeURIComponent(
            tokenbase64EncodedString
          )}`;
          const tokenresponse = await API.get(
            config.aws_cloud_logic_custom_name,
            tokenreportInfo,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: authkey,
              },
            }
          );
          userdata.accessToken = tokenresponse.accessToken;
          userdata.refreshToken = tokenresponse.refreshToken;
          userdata.beforeAuth = authkey;

          localStorage.setItem(
            "after_logIn_accessToken",
            tokenresponse?.accessToken
          );
          analyticsWebApp(userdata);
        } else {
          pageload();
        }
      } else {
        pageload();
      }
    } catch (err) {
      pageload();
    }
  };
  async function analyticsWebApp(userDetails) {
    const bodydata = {
      oid: config.aws_org_id,
        eventtype: "AuthenticatedViaCognito",
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        email: userDetails.username,
        name: userDetails.name,
        emailid: userDetails.email,
        tenant: userDetails.locale,
        ur_id: userDetails.uData?.ur_id,
        schema: config.schema
    }
    bodydata.emailid = userDetails.emailid;
    const jsonString = JSON.stringify(bodydata);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      }
    };
   
    

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
      );

   
      setBtnLoading(false);
      dispatch(awsSignIn(userDetails));
      // var obj = JSON.parse(localStorage.getItem("deeplink"));

      let id = query.get("id");
      if (location.pathname === "/course" && id) {
        history.push(`/course?id=${id}`);
      } else if (location.pathname === "/content" && id) {
        history.push(`/content?id=${id}`);
      } else {
        history.push("/home");
      }
      // localStorage.removeItem("deeplink");
      // if (obj !== undefined && obj.type == "course") {
      //   history.push(`/course?id=${obj.tid}`);
      // } else if (obj !== undefined && obj.type == "content") {
      //   history.push(`/content?id=${obj.tid}`);
      // } else {
      //   history.push("/home");
      // }
    } catch (err) {
      history.push("/home");
      // bodyParam.body.err = err;
      // const response2 = await API.post(
      //   config.aws_cloud_logic_custom_name,
      //   "/zTestSes",
      //   bodyParam
      // );
    }

    // window.location.href = homeurl;
  }
  const calculateClockDrift = (iatAccessToken, iatIdToken) => {
    const now = Math.floor(new Date() / 1000);
    const iat = Math.min(iatAccessToken, iatIdToken);
    return now - iat;
  };
  return (
    <React.Fragment>
       
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
       
    </React.Fragment>
  );
};

export default LoginExcelLearn;
