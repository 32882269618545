import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BoltIcon from "@mui/icons-material/Bolt";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CampaignIcon from "@mui/icons-material/Campaign";
import LeaderboardIcon from "../../assets/svg/LeaderboardPrimary.svg";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CoinsSVG from "../../assets/svg/bi_coin.svg";
import RankSVG from "../../assets/svg/rank_man.svg";
import Skeleton from "@mui/material/Skeleton";
import LevelProgressBar from "../../pages/LearnerProgress/LearnerProgressbar";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";

import "./GizmosCSS.css";
import {
  Button,
  IconButton,
  DialogContent,
  Dialog,
  Typography,
  styled,
} from "@mui/material";
import { API } from "aws-amplify";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { getUserCertificate, getBanners } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import config from "../../aws-exports";
import ReactPlayer from "react-player";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Gizmos({banners, bannerLoad}) {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    mainDiv: {
      marginBottom: "2.5rem",
      gap: "2rem",
      display: "grid",
      gridTemplateColumns: "30% auto 23% 20%",
      "@media (max-width: 1460px)": {
        gridTemplateColumns: "auto auto",
      },
      "@media (max-width: 600px)": {
        gridTemplateColumns: "auto",
      },
    },
    LeaderboardIconStyle: {
      width: "2.5rem",
      margin: "auto",
    },
    subBlock: {
      border: "1px solid",
      borderColor: userDetails.themeDark === "dark" ? "transparent" : "#9FCEEA",
      background:
        userDetails.themeDark === "dark"
          ? "linear-gradient(180deg, #39404D , #272C38)"
          : "linear-gradient(180deg, white , #EAF7FF)",
      padding: "1rem",
      textDecoration: "none",
      borderRadius: "5px",
      color: userDetails.themeDark === "dark" ? "white" : "black",
    },
    subBlockHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > div:first-child": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        "& > div": {
          padding: "1rem",
          borderRadius: "3rem",
          boxShadow: "0px 2px 5px 0px rgba(102, 102, 102, 0.3)",
          height: "4.5rem",
          background: "linear-gradient(180deg, white , #EAF7FF)",
        },
        "& > p": {
          fontSize: "2rem",
        },
      },
    },
    navigationIcon: {
      padding: "4px",
      borderRadius: "3rem",
      height: "4rem",
      backgroundColor: "#E8F3F9",
    },
    leaderboardBodyStyle: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      background: "linear-gradient(180deg, #3DABE9 , var(--color-primary))",
      height: "11.5rem",
      margin: "1rem 0rem 0rem",
      borderRadius: "5px",
      textAlign: "center",
      alignItems: "center",
      "& > div:first-child": {
        borderRight: "2px solid #8CB6CE",
      },
      "& > div": {
        display: "grid",
        gap: "1rem",
        color: "white",
        "& > p": {
          fontSize: "1.1rem",
        },
      },
    },
    sliderDialogStyle: {
      "& > div": {
        background: "#0000008a",
        "& > div": {
          background: "transparent",
          boxShadow: "none",
          padding: "2rem",
          margin: "0 1rem",
        },
      },
    },
    imgSlider: {
      display: "flex",
      justifyContent: "center",
      gap: "1rem",
      alignItems: "center",
    },
    indicatorContainer: {
      position: "absolute",
      bottom: "40px",
      display: "flex",
      gap: "5px",
    },
    indicator: {
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "lightgray",
      cursor: "pointer",
    },
    imgCounter: {
      position: "absolute",
      top: 0,
      left: "50%",
      fontSize: "2rem",
      color: "white",
      backgroundColor: "#717171",
      borderRadius: "2rem",
      padding: "0px 10px",
    },
    title: {
      fontSize: "3rem",
      color: "lightgray",
      textAlign: "center",
      position: "relative",
      bottom: "4rem",
      backgroundColor: "#717171cc",
      height: "8rem",
      padding: "2rem",
      borderTopLeftRadius: "1rem",
      borderTopRightRadius: "1rem",
      width: "60%",
      margin: "auto",
    },
    circleImageContainer: {
      width: "11rem",
      height: "11rem",
      borderRadius: "50%",
      overflow: "hidden",
      border: "6px solid transparent",
      background: "linear-gradient(to top, #ff3366, #33ccff) border-box",
      "& > div": {
        border: "3px solid white",
        height: "100%",
        overflow: "hidden",
        borderRadius: "50%",
      },
    },
    circleImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    totalImagesStyle: {
      padding: "0.2rem 1.3rem",
      borderRadius: "3rem",
      height: "2.5rem",
      backgroundColor: userDetails.themeDark === "dark" ? "#EAF7FF" : "#323232",
      color: userDetails.themeDark === "dark" ? "black" : "white",
    },
    shareButton: {
      padding: "0.9rem 1rem!important",
      display: "flex",
      color: "#0274B3!important",
      alignItems: "center",
      gap: "1rem",
  
      float: "right",
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [userRank, setUserRank] = React.useState({});
  const [images, setImages] = React.useState([]);
  const [levelReach, setLevelReach] = useState(0);
  const [pointsToReach, setPointsToReach] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [maxLevel, setMaxLevel] = useState(true);
  const [levelsArray, setLevelsArray] = useState([]);
  const [curLevel, setCurLevel] = useState(0);
  
  const { data: certificates, isLoading: spinCert } = useQuery({
    queryKey: ["cert"],
    queryFn: () => getUserCertificate(userDetails),
  });
  const { isLoading: leaderload, data } = useQuery(["getmdata"], async () => {
    const requestBody = {
      ur_id: userDetails?.uData?.ur_id,
      tenant: userDetails?.uData?.oid,
      schema:config?.schema
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getLeaderboardData?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      }
    );
    return response;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getLearnerProgress();
  }, []);
  async function getLearnerProgress() {
    const jsonData = {
      schema: config.schema,
      oid: config.aws_org_id,
      ur_id: userDetails?.uData?.ur_id,
    };
    // Convert JSON to a string and encode it
    const jsonString = encodeURIComponent(JSON.stringify(jsonData));
    // Append the encoded string to the base URL
    const baseUrl = `/getLearnerProgress`; // Replace with your actual URL
    const fullUrl = `${baseUrl}?queryjson=${jsonString}`;
    try {
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        fullUrl,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken
          },
        }
      );
      if (response) {
        setCurrentLevel(response?.currentLevel[0].levels);
        setLevelsArray(response?.levels);
        setCurLevel(response?.currentLevel[0]?.levels);
        const levelToReach = response?.currentLevel[0]?.levels + 1;
        const pointsToReach =
        response?.levels[levelToReach - 1]?.lpoints - response?.currentLevel[0]?.points;
        setPointsToReach(pointsToReach);
        setLevelReach(levelToReach);
        if (
          response?.currentLevel[0]?.levels ===
          response?.levels[response?.levels.length - 1].level
        ) {
          setMaxLevel(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  const [currentIndex, setCurrentIndex] = useState(0);
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  useEffect(() => {
    if (banners) {
 
      setImages(banners);
    }
  }, [banners]);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const ImageSlider = ({ images }) => {
    return (
      <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className={classes.imgSlider}>
          <Button onClick={prevSlide}>
            <ArrowBackIosNewIcon
              style={{ fontSize: "4rem", color: "lightgray" }}
            />
          </Button>
          <div>
         

          {images[currentIndex]?.contenttype === 1 ?
          <div style={{ display: "grid" }}>
        
            <img
              src={`${
                config.aws_cloudfront_url
              }${config.aws_org_id.toLowerCase()}-resources/images/bnr-images/${
                images[currentIndex]?.filename
              }`}
              alt={`Slide ${currentIndex + 1}`}
              style={{ width: "60%", margin: "auto" }}
            />
          </div> :
        
           <ReactPlayer
              
              url={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/images/bnr-images/${
                  images[currentIndex]?.filename
                }`}
              width="100%"
              height="80%"
              style={{ marginTop: "3rem" }}
              controls={true}
              playing={true}
          />
              }
          </div>
          <Button onClick={nextSlide}>
            <ArrowForwardIosIcon
              style={{ fontSize: "4rem", color: "lightgray" }}
            />
          </Button>
        </div>
      
        <p className={classes.imgCounter}>
          {currentIndex + 1} / {images.length}
        </p>
        <div className={classes.indicatorContainer}>
          {images.map((_, index) => (
            <div
              key={index}
              className={classes.indicator}
              style={{
                backgroundColor:
                  index === currentIndex
                    ? "var(--color-button-background)"
                    : "lightgray",
              }}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
   
      </div>
      <div style={{display:"flex",justifyContent:"end"}}> <a
                        download={`Posters.png`}
                        href={`https://${
                          config.DOMAIN
                        }/${config.aws_org_id.toLowerCase()}-resources/images/bnr-images/${
                          images[currentIndex]?.filename
                        }`}
                        style={{
                            border: "0",
                            margin: "0",

                            float: "right",
                            textDecoration: "none",
                            margin: "auto 1rem",



                            border: "1px solid",
                            borderRadius: "8px",
                            backgroundColor: "black",
                            color: "white",
                            padding: "5px",
                            height: "6rem",
                            alignItems: "center",
                            display: "flex",
                            fontSize:"medium"
                        }}
                    >
                        <DownloadIcon fontSize="large" />
                        Download Now

                    </a></div>
      </div>
      
    );
  };

  useEffect(() => {
    if (data) {
      const rankuser = data.iusers?.filter(
        (user) => userDetails.uData.ur_id === user.ur_id
      );
      if (rankuser) {
        setUserRank(rankuser[0]);
      }
    }
  }, [data?.iusers]);
  return (
    <>
      <div className={classes.mainDiv}>
        {/* Learner Progress Block  */}
        <Link className={classes.subBlock} to={`/learnerProgress`}>
          <div className={classes.subBlockHeader}>
            <div>
              <div>
                <BoltIcon
                  style={{ fontSize: "2.5rem", color: "var(--color-primary)" }}
                />
              </div>
              <p>{`${userDetails?.languagejson?.LearnerProgress
                ?.Learner_Progress || "Learner Progress"}`}</p>
            </div>
            <div className={classes.navigationIcon}>
              <NavigateNextIcon
                style={{ fontSize: "3.2rem", color: "var(--color-primary)" }}
              />
            </div>
          </div>
          <div
            style={{
              margin: "3.5rem 1rem 1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Level {currentLevel}</h3>
            <p style={{ fontSize: "1.3rem" }}>
              {maxLevel && (
                <p style={{ fontSize: "1.3rem" }}>
                  {pointsToReach} points to reach Level {levelReach}
                </p>
              )}
              {!maxLevel && (
                <p style={{ fontSize: "1.3rem" }}>maximum level reached</p>
              )}
            </p>
          </div>
          <LevelProgressBar curLevel={curLevel} levelsArray={levelsArray} />
        </Link>
        {/* Announcements Block  */}
        {images && (
          <div className={classes.subBlock}>
            <div className={classes.subBlockHeader}>
              <div>
                <div>
                  <CampaignIcon
                    style={{
                      fontSize: "2.5rem",
                      color: "var(--color-primary)",
                    }}
                  />
                </div>
                <p>{`${userDetails?.languagejson?.LearnerProgress
                ?.Announcements || "Announcements"}`}</p>
              </div>
              <div className={classes.totalImagesStyle}>{images.length}</div>
            </div>
            <div>
              <div className={classes.imgSlider}>
                <Button onClick={prevSlide}>
                  <ArrowBackIosNewIcon
                    style={{
                      fontSize: "3.5rem",
                      color: currentIndex === 0 ? "lightgray" : "black",
                    }}
                  />
                </Button>
                <Button onClick={handleClickOpen}>
                  <div className={classes.circleImageContainer}>
                    <div>
                      <img
                        src={`https://${
                          config.DOMAIN
                        }/${config.aws_org_id.toLowerCase()}-resources/images/bnr-images/${
                          images[currentIndex]?.filename
                        }`}
                        alt={`Slide ${currentIndex + 1}`}
                        style={{ width: "20rem", margin: "auto" }}
                        className={classes.circleImage}
                      />
                    </div>
                  </div>
                </Button>
                <Button onClick={nextSlide}>
                  <ArrowForwardIosIcon
                    style={{
                      fontSize: "3.5rem",
                      color:
                        currentIndex === images.length - 1
                          ? "lightgray"
                          : "black",
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
        )}
        {/* Leaderboard Block  */}
        <Link
          className={classes.subBlock}
          style={{ padding: "5px" }}
          to={`/leaderboard`}
        >
          <div
            className={classes.subBlockHeader}
            style={{ padding: "2px 5px" }}
          >
            <div>
              <div>
                <img
                  src={LeaderboardIcon}
                  alt="Gold Badge"
                  title="Gold Badge"
                  className={classes.LeaderboardIconStyle}
                />
              </div>
              <p>{`${userDetails?.languagejson?.header?.Leaderboard ||
                "Leaderboard"}`}</p>
            </div>
            <div className={classes.navigationIcon}>
              <NavigateNextIcon
                style={{ fontSize: "3.2rem", color: "var(--color-primary)" }}
              />
            </div>
          </div>
          <div className={classes.leaderboardBodyStyle}>
            <div>
              <img
                src={CoinsSVG}
                alt="Coin Badge"
                title="Coin Badge"
                className={classes.LeaderboardIconStyle}
              />
              <p>{`${userDetails?.languagejson?.Leaderboard?.coins || "Coins"}`}</p>
              {leaderload ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              ) : (
                <h4>{userRank?.points}</h4>
              )}
            </div>
            <div>
              <img
                src={RankSVG}
                alt="Rank Badge"
                title="Rank Badge"
                className={classes.LeaderboardIconStyle}
              />
              <p>{`${userDetails?.languagejson?.Leaderboard?.Rank || "Rank"}`}</p>
              {leaderload ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              ) : (
                <h4>{userRank?.rank}</h4>
              )}
            </div>
          </div>
        </Link>
        <Link className={classes.subBlock} to={{ pathname: '/profile', state: { key: 'giz' } }}>
          <div className={classes.subBlockHeader}>
            <div>
              <div>
                <WorkspacePremiumIcon
                  style={{ fontSize: "2.5rem", color: "var(--color-primary)" }}
                />
              </div>
              <p>{`${userDetails?.languagejson?.My_Profile?.Certificates ||
                "Certificates"}`}</p>
            </div>
            <div className={classes.navigationIcon}>
              <NavigateNextIcon
                style={{ fontSize: "3.2rem", color: "var(--color-primary)" }}
              />
            </div>
          </div>
          <div style={{ margin: "3.5rem 1rem" }}>
            {spinCert ? (
              <h3>0</h3>
            ) : (
              <h3>{certificates?.length}</h3>
            )}
            <p>{`${userDetails?.languagejson?.LearnerProgress?.total_certificates ||
                "Total certificates"}`}</p>
          </div>
        </Link>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        className={classes.sliderDialogStyle}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelIcon style={{ fontSize: "4rem", color: "lightgray" }} />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <ImageSlider images={images} />
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
export default Gizmos;
