import { makeStyles, Box } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Button from "@material-ui/core/Button";
import ReactFlagsSelect from "react-flags-select";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
//import Header from "../../components/Header/Header";
import config from "../../../aws-exports";
import { API } from "aws-amplify";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import Grid from "@mui/material/Grid";

import { ReactComponent as Edit } from "../../../assets/edit_black_24dp.svg";
import Skeleton from "@mui/material/Skeleton";

import ChangePassword from "./ChangePassword";
import { Autocomplete, TextField } from "@mui/material";
import User from "./MyProfile.module.scss";

import Modal from "@mui/material/Modal";
import { set } from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserDetails, profileUpdate, getPincode } from "../../../api/api";
import { useForm } from "../../../Hooks/useForm";

// import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Window } from "@mui/icons-material";

const EditProfile = ({ handleClosePopup, getUserDetailsFetch }) => {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    mainField: {
      display: "flex",
      alignContent: "center",
      margin: "auto",
      gap: "20px",
      alignItems: "center",
      "@media (max-width: 500px)": {
        display: "block",
        width: "100%",
        gap: "5px",
        textAlign: "left",
      },
    },
    myProfileSection: {
      padding: "30px",
      margin: "1rem 1rem 3rem 1rem",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#272C38 !important" : "#fff",
      borderRadius: "8px",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "inset 1px 5px 5px 1px rgb(56 63 81) !important"
          : "0px 0px 10px 0px rgba(0,0,0,0.2)",
      fontFamily: `myfontregular`,
      "@media (max-width: 430px)": {
        padding: "1.5rem",
        margin: "0",
      },
      "& > div > div": {
        "@media (max-width: 500px)": {
          display: "block",
        },
      },
    },
    myProfileSectionImages: {
      "@media (max-width: 430px)": {
        padding: "1rem!important",
      },
    },
    EditAVTButton: {
      fontSize: "1.4rem",
      fontWeight: "600",
      fontFamily: `myfontregular`,
      // padding: "16px 10px",
      width: "fit-content",
      color: "var(--color-primary)",
      border: "2px solid var(--color-primary)",
      backgroundColor: "#fff!important",
      // height: "27px",
      textTransform: "none",
      "@media (max-width: 550px)": {
        width: "auto",
      },
    },
    mainHeader: {
      paddingTop: "7px",
      fontSize: "2.5rem",
      fontWeight: "700",
      "@media (max-width: 430px)": {
        marginLeft: "0rem",
      },
    },
    profileActionSection: {
      display: "flex",
      gap: "50px",
      marginLeft: "10px",

      // "@media (max-width: 1000px)": {
      //   width: "60%",
      //   display: "block",
      //   gap: "0px",
      //   marginBottom: "2rem",
      // },
      "@media (max-width: 500px)": {
        width: "100%",
        margin: "2rem 0rem",
        gap: "20px",
      },
    },
    inputLabel: {
      fontSize: "16px",
      fontWeight: "600",
      alignItems: "center",
      // display: "flex",
      marginTop: "2px",
      width: "13rem",
      textAlign: "right",
    },
    inputField: {
      border: "1px solid",
      borderColor:
        userDetails.themeDark === "dark" ? "#565d6e !important" : "#c9c9c9",
      borderRadius: "5px",
      width: "100%",
      background:
        userDetails.themeDark === "dark" ? "#565d6e !important" : "#fff",
      height: "38px",
      fontSize: "15px",
      paddingLeft: "18px",
      margin: "15px 0px 2px 0px",
      "@media (max-width: 500px)": {
        width: "100%",
        margin: "15px 0px 5px",
      },
    },
    inputFieldCountry: {
      borderRadius: "5px",
      width: "60%",
      height: "30px",
      margin: "15px 0px 15px 0px",
      padding: "0px",
      "@media (max-width: 500px)": {
        width: "100%",
        margin: "2rem 0rem",
      },
      "& > button": {
        backgroundColor:
          userDetails.themeDark === "dark" ? "#353944 !important" : "#fff",
        color: userDetails.themeDark === "dark" ? "#fff !important" : "#000",
        borderColor:
          userDetails.themeDark === "dark" ? "#565d6e !important" : "#c9c9c9",
        // backgroundColor: "white",
      },
      "& > ul > li": {
        color: "black",
      },
    },
    dialogContainer: {
      "& > div > div": {
        backgroundColor:
          userDetails.themeDark === "dark" ? "#272C38 !important" : "#fff",
        color: userDetails.themeDark === "dark" ? "#fff !important" : "#000",
      },
    },
    dialogHeader: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "2px solid #d9d9d9",
      paddingRight: "10px!important",
    },
    dialogCloseIcon: {
      width: "2.5rem!important",
      height: "2.5rem!important",
      cursor: "pointer",
    },
    buttonSection: {
      display: "flex!important",
      gap: "2rem",
      justifyContent: "center",
      marginTop: "3rem",
      // "@media (min-width: 1250px)": {
      //   width: "80%",
      // },
    },
    pincodeStyle: {
      borderRadius: "5px",
      width: "100%",
      background:
        userDetails.themeDark === "dark" ? "#565d6e !important" : "#fff",
      // height: "30px",
      marginBottom: "20px",
      fontSize: "15px",
      fontWeight: "200px",
    },
    ONDCEditFieldResponsive: {
      maxWidth: "50%!important",
      flexBasis: "50%!important",
      "@media (max-width: 770px)": {
        maxWidth: "100%!important",
        flexBasis: "100%!important",
      },
    },
    selectFieldONDC: {
      margin: "15px 0px 15px 10px",
      width: "60%",
      "@media (max-width: 500px)": {
        width: "100%",
        margin: "2rem 0rem",
      },
    },
    inputFieldContainer: {
      width: "60%",
      height: "7rem",
      "@media (max-width: 500px)": {
        width: "100%",
        margin: "0rem 0rem 1rem",
      },
    },
    inputFieldContainerPassword: {
      width: "60%",
      height: "7rem",
      "@media (max-width: 500px)": {
        width: "100%",
        margin: "0rem 0rem 1rem",
        height: "5rem",
      },
    },
    editIconStyle: {
      cursor: "pointer",
      backgroundColor: "#ffffff",
      padding: "2px",
      borderRadius: "4px",
      display: "flex",
      width: "fit-content",
      "@media (max-width: 500px)": {
        margin: "0rem 0rem 3rem",
      },
    },
  }));
  const classes = useStyles();
  const queryClient = useQueryClient();
  const location = useLocation();
  const dispatch = useDispatch();

  const [avtid, setAvtid] = useState(null);
  const [editAvatar, setEditAvatar] = useState(false);
  const [country, setCountry] = useState("IN");
  const [finalAvt, setFinalAvt] = useState(null);
  const [firstNameEr, setFirstNameEr] = useState("");
  const [lastNameEr, setLastNameEr] = useState("");
  const [pnoEr, setPnoEr] = useState("");
  const [open, setOpen] = useState(false);
  const [addressDetailsShow, setAddressDetailshow] = useState(
    config.profileSet
  );
  const [pincodeData, setPincodeData] = useState(null);
  const [gst, setGst] = useState("");
  const [addr, setAddr] = useState({ addr1: "", addr2: "" });
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("");
  const [state_name, setStateName] = useState("");
  const [district, setDistrict] = useState("");
  const [addrOneErr, setAddrOneErr] = useState("");
  const [addrTwoErr, setAddrTwoErr] = useState("");
  const [pincodeErr, setPincodeErr] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [personalPhone, setPersonalPhone] = useState("");
  const [companyEmailEr, setCompanyEmailEr] = useState("");
  const [companyNameEr, setCompanyNameEr] = useState("");
  const [workEmailEr, setWorkEmailEr] = useState("");
  const [dobEr, setDobEr] = useState("");
  const [workPhoneEr, setWorkPhoneEr] = useState("");
  const [employeeID, setEmployeeID] = useState("");
  const [dontAllowEdit, setDontAllowEdit] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const inputFieldCountry= {
  //   borderRadius: "5px",
  //   width: "60%",
  //   height: "30px",
  //   margin: "15px 0px 15px 10px",
  //   padding: "0px",
  //   "@media (max-width: 500px)": {
  //     width: "100%",
  //     margin: "2rem 0rem",
  //   },
  //   "& > button": {
  //     // backgroundColor: userDetails.themeDark === "dark"? "#000000 !important" : "#fff",
  //     // color: userDetails.themeDark === "dark"? "#fff !important" : "#000",
  //     backgroundColor: "white",
  //   },
  //   "& > ul > li": {
  //     color: "black",
  //   },
  // }

  const { handleChange, values, setValues } = useForm({});

  const history = useHistory();

  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);

  /* useEffect(() => {
    if (addressDetails) {
      getPincode();
    }
  }, []); */

  useEffect(() => {
    checkProfileUpdate();
  }, []);

  const { data: user, isLoading: spin } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserDetails(userDetails),
  });
  const { data: pincodes, isLoading: isLoadingPin } = useQuery({
    queryKey: ["pincode"],
    queryFn: () => getPincode(userDetails),
  });
  /*  const { data: addressData, isLoading: isLoadingAddress } = useQuery({
    queryKey: ["address"],
    queryFn: () => getPincode(userDetails),
  }); */

  const { mutate } = useMutation({
    mutationFn: (user) => {
      return { ...user };
    },
    onSuccess: (user) => {
      queryClient.setQueryData(["user"], user);
    },
  });
  const { mutate: updateProfile, isLoading } = useMutation({
    mutationFn: (data) => profileUpdate(data,userDetails),
    onSuccess: (response) => {
      swal({
        title: `${userDetails?.languagejson?.Edit_Profile?.Success ||
          "Success"}`,
        text: `${userDetails?.languagejson?.Edit_Profile
          ?.updated_successfully || "Profile updated successfully"}`,
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          if (location.pathname.split("/").indexOf("home") > -1) {
            getUserDetailsFetch();
            handleClosePopup();
          } else {
            history.push({ pathname: "/profile" });
          }
        }
      });
    },
    onError: () => {
      swal({
        title: "Oooops!",
        text: "Something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
    },
  });
  useEffect(() => {
    if (user) {
      setValues({
        ...values,
        firstName: user?.first_name || "",
        lastName: user?.last_name || "",
        pno: user?.cno || "",
      });

      setCountry(user?.address || country);

      if (addressDetailsShow) {
        let obj = {};
        obj.pincode = user?.pincode || "";
        obj.state_name = user?.state_name || "";
        obj.district_name = user?.district_name || "";
        let addr = {};
        addr.addr1 = user?.addressone || "";
        addr.addr2 = user?.addresstwo || "";
        setPincodeData(obj);
        setDistrict(user?.district_name || "");
        setStateName(user?.state_name || "");

        if (user.dob === undefined || user.dob === null) {
          setDob(null);
        } else {
          setDob(new Date(user?.dob || null).toISOString().split("T")[0]);
        }

        setAddr(addr);
        setGst(user?.gstcode || "");
        setGender(user?.gen?.toString() || "0");
        setCompanyEmail(user?.company_emailid || "");
        setCompanyName(user?.company_name || "");
        setWorkEmail(user?.work_emailid || "");
        setWorkPhone(user?.work_phoneno || "");
        setPersonalPhone(user?.cno || "");
        setEmployeeID(user?.employeeid || "");
      }
    }
  }, [user?.firstName, user?.last_name, user?.cno, user?.address]);

  useEffect(() => {
    if (pincodes) {
      const selectedOption = pincodes.find(
        (option) => option.pincode === pincodeData
      );
    }
  }, [pincodeData]);

  const handleSelectChange = (event, value) => {
    setPincodeData(value);
    /* setStateName(value.state_name);
    setDistrict(value.district_name); */
  };

  function handledob(e) {
    setDob(e.target.value);
  }

  async function setProfileTrue() {

    const jsonString = JSON.stringify({oid: config.aws_org_id,
      schema: config.schema,
      ur_id: userDetails.uData?.ur_id});
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/setProfileTrue",
        bodyParam
      );
      handleClosePopup();
    } catch (error) {
      return false;
    }
  }

  async function checkProfileUpdate() {
    const jsonString = JSON.stringify({oid: config.aws_org_id,
      schema: config.schema,
      ur_id: userDetails.uData?.ur_id,});
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/checkProfileUpdate",
        bodyParam
      );
      if (response.body[0].profile_update === false) {
        setDontAllowEdit(true);
      }
    } catch (error) {
      return false;
    }
  }

  function handleCancel() {
    if (location.pathname.split("/").indexOf("home") > -1) {
      setProfileTrue();
    } else {
      history.push({
        pathname: "/profile",
      });
    }
  }

  function maxDate() {
    var today = new Date();

    // Format the date as YYYY-MM-DD
    var formattedDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today
        .getDate()
        .toString()
        .padStart(2, "0");
    return formattedDate;
  }

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  function onsubmit() {
    console.log(values)
    setAddrOneErr("");
    setAddrTwoErr("");
    setPincodeErr("");
    setCompanyEmailEr("");
    setWorkEmailEr("");
    setWorkPhoneEr("");
    setCompanyNameEr("");
    setPnoEr("");
    setDobEr("");
    let formIsValid = true;
    const { firstName, lastName, pno } = values;
    if (
      firstName === "" ||
      firstName === null ||
      (firstName !== undefined && firstName.trim() === "")
    ) {
      formIsValid = false;
      setFirstNameEr(
        `${userDetails?.languagejson?.Edit_Profile?.First_Name_err ||
          "Please enter first name"}`
      );
      return;
    }

    if (
      lastName === "" ||
      lastName === null ||
      (lastName !== undefined && lastName.trim() === "")
    ) {
      formIsValid = false;
      setLastNameEr(
        `${userDetails?.languagejson?.Edit_Profile?.Last_Name_err ||
          "Please enter Last name"}`
      );
      return;
    }

    if (!addressDetailsShow) {
      if (pno && pno.length !== 10) {
        if (!/^[6-9]\d{9}$/.test(pno.toString())) {
          formIsValid = false;
          setPnoEr(
            `${userDetails?.languagejson?.Edit_Profile?.phone_num_err ||
              "*Please enter valid phone number"}`
          );
          return;
        }
      }
    }

    if (firstName !== "") {
      if (
        !firstName.match(
          /^[^\d\u0021-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\u00A1-\u00BF\u00D7\u00F7]*$/
        )
      ) {
        formIsValid = false;
        setFirstNameEr(
          `${userDetails?.languagejson?.Edit_Profile?.name_syntax_err ||
            "*Numeric values and special characters are not allowed."}`
        );
        return;
      }
    }

    if (lastName !== "") {
      if (
        !lastName.match(
          /^[^\d\u0021-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\u00A1-\u00BF\u00D7\u00F7]*$/
        )
      ) {
        formIsValid = false;
        setLastNameEr(
          `${userDetails?.languagejson?.Edit_Profile?.name_syntax_err ||
            "*Numeric values and special characters are not allowed."}`
        );
        return;
      }
    }

    if (addressDetailsShow) {
      if (
        addr.addr1 === "" ||
        addr.addr1 === null ||
        (addr.addr1 !== undefined && addr.addr1.trim() === "")
      ) {
        formIsValid = false;
        setAddrOneErr(
          `${userDetails?.languagejson?.Edit_Profile.address_line_1_err ||
            "Please fill in address line 1"}`
        );
        return;
      }

      if (
        addr.addr2 === "" ||
        addr.addr2 === null ||
        (addr.addr2 !== undefined && addr.addr2.trim() === "")
      ) {
        formIsValid = false;
        setAddrTwoErr(
          `${userDetails?.languagejson?.Edit_Profile?.address_line_2_err ||
            "Please fill in address line 2"}`
        );
        return;
      }
      if (pincodeData.pincode === "") {
        formIsValid = false;
        setPincodeErr(
          `${userDetails?.languagejson?.Edit_Profile?.pincode_err ||
            "Please Enter pincode"}`
        );
        return;
      }
      if (companyName === "" || companyName === null) {
        formIsValid = false;
        setCompanyNameEr(
          `${userDetails?.languagejson?.Edit_Profile?.company_err ||
            "*Please enter company name."}`
        );
        return;
      }

      if (dob === null) {
        formIsValid = false;
        setDobEr(
          `${userDetails?.languagejson?.Edit_Profile?.DOB_err ||
            "*Please add date of birth."}`
        );
        return;
      }

      if (companyEmail === "" || companyEmail === null) {
        formIsValid = false;
        setCompanyEmailEr(
          `${userDetails?.languagejson?.Edit_Profile?.company_email_err ||
            "*Please enter company email."}`
        );
        return;
      }

      if (companyEmail !== "") {
        var pattern = new RegExp(
          /^(?:[\p{L}\w-]+(?:\.[\p{L}\w-]+)*|"[^"]*")@((?:[\p{L}\w-]+\.)*[\p{L}\w-]{0,66})\.([a-z\u007f-\uffff]{2,6}(?:\.[a-z\u007f-\uffff]{2})?)$/i
        );
        if (!pattern.test(companyEmail)) {
          formIsValid = false;
          setCompanyEmailEr(
            `${userDetails?.languagejson?.Edit_Profile?.valid_email_err ||
              "*Please enter valid email-ID."}`
          );
          return;
        }
      }
      if (workEmail !== "") {
        var pattern1 = new RegExp(
          /^(?:[\p{L}\w-]+(?:\.[\p{L}\w-]+)*|"[^"]*")@((?:[\p{L}\w-]+\.)*[\p{L}\w-]{0,66})\.([a-z\u007f-\uffff]{2,6}(?:\.[a-z\u007f-\uffff]{2})?)$/i
        );
        if (!pattern1.test(workEmail)) {
          formIsValid = false;
          setWorkEmailEr(
            `${userDetails?.languagejson?.Edit_Profile?.valid_email_err ||
              "*Please enter valid email-ID."}`
          );
          return;
        }
      }
      if (workPhone) {
        if (workPhone.length !== 13) {
          formIsValid = false;
          setWorkPhoneEr(
            `${userDetails?.languagejson?.Edit_Profile?.phone_num_err ||
              "*Please enter valid phone number"}`
          );
          return;
        }
        if (!isValidPhoneNumber(workPhone)) {
          formIsValid = false;
          setWorkPhoneEr(
            `${userDetails?.languagejson?.Edit_Profile?.phone_num_err ||
              "*Please enter valid phone number"}`
          );
          return;
        }
      }
      if (personalPhone && personalPhone.length !== 10) {
        if (!/^[6-9]\d{9}$/.test(personalPhone.toString())) {
          formIsValid = false;
          setPnoEr(
            `${userDetails?.languagejson?.Edit_Profile?.phone_num_err ||
              "*Please enter valid phone number"}`
          );
          return;
        }
      }
    }

    if (formIsValid === true) {
      /*  if (!finalAvt) return; */
      let addrrObject = {};

      addrrObject.dob = dob === null ? "" : new Date(dob);
      addrrObject.gender = gender;
      addrrObject.addressLine = addr;
      addrrObject.pincode = pincodeData;
      addrrObject.gst = gst;
      addrrObject.companyemail = companyEmail;
      addrrObject.companyname = companyName;
      addrrObject.workemail = workEmail;
      addrrObject.workphone = workPhone;
      addrrObject.employeeid = employeeID;

      updateProfile({
        oid: config.aws_org_id,
        ur_id: user?.ur_id,
        tenant: user?.oid,
        emailid: user?.emailid,
        first_name: firstName,
        last_name: lastName,
        dev: user?.dev,
        uid: user?.uid,
        gen: gender === "" ? parseInt(user?.gen) : parseInt(gender),
        address: country,
        schema: config.schema,
        cno: addressDetailsShow ? personalPhone : pno,
        avtid: finalAvt,
        type: "edit",
        atype: 1,
        addressDetails: addrrObject,
        addressShow: config.profileSet,
      });
    }
  }

  // async function UpdateProfile()

  function chooseAvatar() {
    if (editAvatar) {
      if (avtid === null) {
        return user?.avtid;
      }
      return avtid;
    } else if (finalAvt === null) {
      return user?.avtid;
    } else return finalAvt;
  }

  const [openAvatar, setopenAvatar] = React.useState(false);

  const handleClickopenAvatar = () => {
    setopenAvatar(true);
  };

  const handleCloseAvatar = () => {
    setopenAvatar(false);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddr((prevObject) => ({
      ...prevObject,
      [name]: value,
    }));
  };

  return (
    <div>
      <div>
        <Dialog
          open={openAvatar}
          onClose={handleCloseAvatar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.dialogContainer}
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
            <h3>{`${userDetails?.languagejson?.Edit_Profile?.Edit_Avatar ||
              "Edit Avatar"}`}</h3>
            <CloseIcon
              className={classes.dialogCloseIcon}
              onClick={() => {
                handleCloseAvatar();
              }}
            />
          </DialogTitle>
          <DialogTitle
            style={{
              fontSize: "1.8rem",
              fontFamily: `myfontregular`,
              fontWeight: "600",
            }}
          >
            {`${userDetails?.languagejson?.Edit_Profile?.Pick_an_avatar ||
              "Pick an avatar"}`}
          </DialogTitle>
          <DialogContent className={classes.myProfileSectionImages}>
            <div>
              <Grid
                container
                spacing={3}
                style={{ margin: "auto", width: "100%" }}
              >
                {[...Array(21).fill(null)].map((item, i) => {
                  i = i + 1;
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={4}
                      lg={3}
                      key={i}
                      style={{
                        padding: "1rem",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={`https://${
                          config.DOMAIN
                        }/${config.aws_org_id.toLowerCase()}-resources/images/profile-images/${i}.png`}
                        sx={{ width: 80, height: 80 }}
                        className={
                          i === avtid
                            ? User.selectedAvt
                            : i === user?.avtid
                            ? User.selectedAvt
                            : null
                        }
                        onClick={() => {
                          setAvtid(i);
                          mutate({
                            ...user,
                            avtid: i,
                          });
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center", padding: "20px" }}>
            <button
              type="button"
              onClick={() => {
                setEditAvatar(false);
                setFinalAvt(avtid);
                handleCloseAvatar();
                mutate({
                  ...user,
                  avtid: avtid,
                });
              }}
              className={User.btn_color}
            >
              {`${userDetails?.languagejson?.Edit_Profile?.Save_Avatar ||
                "Save Avatar"}`}
            </button>
          </DialogActions>
        </Dialog>
      </div>

      {!spin && !isLoadingPin ? (
        <>
          <h2
            variant="h3"
            className={classes.mainHeader}
            style={{
              margin: "0rem 1rem 2.5rem",
            }}
          >
            {`${userDetails?.languagejson?.My_Profile?.Edit_profile ||
              "Edit profile"}`}
          </h2>
          <div className={classes.myProfileSection}>
            <Box sx={{ flexGrow: 1 }}>
              <div
                className={classes.mainField}
                style={{ width: "100%", justifyContent: "center" }}
              >
                <label className={classes.inputLabel} for="name" required>
                  {`${userDetails?.languagejson?.Edit_Profile
                    ?.Profile_picture || "Profile picture"}`}
                </label>
                <div
                  className={classes.profileActionSection}
                  style={{ alignItems: "center" }}
                >
                  <div
                    style={{
                      margin: "0px 0px 10px 0px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {spin ? (
                      <Avatar sx={{ width: 100, height: 100 }} />
                    ) : (
                      <Avatar
                        alt=""
                        src={`https://${
                          config.DOMAIN
                        }/${config.aws_org_id.toLowerCase()}-resources/images/profile-images/${chooseAvatar()}.png`}
                        sx={{ width: 100, height: 100 }}
                      />
                    )}
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      className={classes.EditAVTButton}
                      onClick={() => {
                        setEditAvatar(true);
                        handleClickopenAvatar();
                      }}
                    >
                      {`${userDetails?.languagejson?.Edit_Profile
                        ?.Edit_Avatar || "Edit Avatar"}`}
                    </Button>
                  </div>
                </div>
              </div>
              <Grid container>
                <Grid
                  item
                  sm={12}
                  md={4}
                  className={classes.ONDCEditFieldResponsive}
                >
                  <div>
                    <div className={User.form}>
                      <div className={classes.mainField}>
                        <label
                          className={classes.inputLabel}
                          for="name"
                          required
                        >
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.First_Name || "First Name"}`}
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              padding: "2px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            id="name"
                            //onChange={onChangeHandlerUser}
                            onChange={handleChange("firstName")}
                            value={values?.firstName || ""}
                            required
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {firstNameEr}
                          </div>
                        </div>
                      </div>

                      <div className={classes.mainField}>
                        <label
                          className={classes.inputLabel}
                          for="name"
                          required
                        >
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Last_Name || "Last Name"}`}
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              padding: "2px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            id="name"
                            //onChange={onChangeHandlerUser}
                            onChange={handleChange("lastName")}
                            value={values?.lastName || ""}
                            required
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {lastNameEr}
                          </div>
                        </div>
                      </div>

                      {!addressDetailsShow && (
                        <div className={classes.mainField}>
                          <label
                            className={classes.inputLabel}
                            for="name"
                            required
                            style={{ whiteSpace: "pre" }}
                          >
                            {`${userDetails?.languagejson?.Edit_Profile?.ID ||
                              "ID"}`}
                          </label>
                          <div className={classes.inputFieldContainer}>
                            <input
                              className={classes.inputField}
                              type="text"
                              id="name"
                              disabled
                              value={user?.uid || ""}
                            />
                          </div>
                        </div>
                      )}
                      {addressDetailsShow && (
                        <div className={classes.mainField}>
                          <label
                            className={classes.inputLabel}
                            for="name"
                            required
                            style={{ whiteSpace: "pre" }}
                          >
                            {`${userDetails?.languagejson?.Edit_Profile
                              ?.Employee_ID || "Employee ID"}`}
                          </label>
                          <div className={classes.inputFieldContainer}>
                            <input
                              className={classes.inputField}
                              type="text"
                              id="name"
                              onChange={(e) => setEmployeeID(e.target.value)}
                              value={employeeID}
                            />
                          </div>
                        </div>
                      )}

                      <div className={classes.mainField}>
                        <label
                          className={classes.inputLabel}
                          for="email"
                          required
                        >
                          {`${userDetails?.languagejson?.Edit_Profile?.Email ||
                            "Email-ID"}`}
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              padding: "2px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="email"
                            id="email"
                            disabled
                            value={user?.emailid || ""}
                            required
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {/*  {emailErr} */}
                          </div>
                        </div>
                      </div>

                      <div className={classes.mainField}>
                        <label
                          className={classes.inputLabel}
                          for="email"
                          required
                        >
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Password || "Password"}`}
                        </label>
                        <div className={classes.inputFieldContainerPassword}>
                          <input
                            className={classes.inputField}
                            type="password"
                            id="password"
                            disabled
                            value="*********"
                            required
                          />
                        </div>
                        <div className={classes.editIconStyle}>
                          <Edit
                            onClick={() => {
                              setOpen(true);
                            }}
                            style={{ fill: "var(--color-primary)" }}
                          />
                        </div>
                      </div>
                      {!addressDetailsShow && (
                        <>
                          <div className={classes.mainField}>
                            <label className={classes.inputLabel} for="address">
                              {`${userDetails?.languagejson?.Edit_Profile
                                ?.Ph_No || "Ph.No"}`}
                            </label>
                            <div className={classes.inputFieldContainer}>
                              <input
                                className={classes.inputField}
                                type="text"
                                id="name"
                                //onChange={onChangeHandlerUser}
                                onChange={handleChange("pno")}
                                value={values?.pno || ""}
                                required
                              />
                              <div
                                style={{
                                  color: "#cc0000",
                                  marginBottom: "12px",
                                  textAlign: "left",
                                }}
                              >
                                {pnoEr}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {!addressDetailsShow && (
                        <div className={classes.mainField}>
                          <label className={classes.inputLabel} for="address">
                            {`${userDetails?.languagejson?.Edit_Profile
                              ?.Country || "Country"}`}
                          </label>
                          <ReactFlagsSelect
                            className={classes.inputFieldCountry}
                            // sx={inputFieldCountry}
                            selected={country}
                            onSelect={(code) => setCountry(code)}
                            fullWidth={false}
                            searchable
                            searchPlaceholder="Search countries"
                          />
                        </div>
                      )}
                      {/*     {!addressDetailsShow && (
                        <div>
                          <div className={classes.mainField}>
                            <label className={classes.inputLabel} for="address">
                              Address
                            </label>
                            <input
                              className={classes.inputField}
                              type="number"
                              id="contact"
                              onChange={handleChange("pno")}
                              value={values?.pno || ""}
                            />
                          </div>
                        </div>
                      )} */}
                      {addressDetailsShow && (
                        <>
                          <div className={classes.mainField}>
                            <label className={classes.inputLabel} for="contact">
                              {`${userDetails?.languagejson?.Edit_Profile
                                ?.Personal_Ph_No || "Personal Ph. No"}`}
                            </label>
                            <div className={classes.inputFieldContainer}>
                              <input
                                className={classes.inputField}
                                type="number"
                                id="contact"
                                onChange={(e) => {
                                  setPersonalPhone(e.target.value);
                                }}
                                value={personalPhone}
                              />
                              <div
                                style={{
                                  color: "#cc0000",
                                  marginBottom: "12px",
                                  textAlign: "left",
                                }}
                              >
                                {pnoEr}
                              </div>
                            </div>
                          </div>

                          <div className={classes.mainField}>
                            <label className={classes.inputLabel} for="address">
                              {`${userDetails?.languagejson?.Edit_Profile
                                ?.DOB || "DOB"}`}
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "15px",
                                  padding: "2px",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <div className={classes.inputFieldContainer}>
                              <input
                                className={classes.inputField}
                                type="date"
                                id="contact"
                                placeholder="dd-mm-yyyy"
                                max={maxDate()}
                                onChange={(e) => handledob(e)}
                                value={dob}
                              />
                              <div
                                style={{
                                  color: "#cc0000",
                                  marginBottom: "12px",
                                  textAlign: "left",
                                }}
                              >
                                {dobEr}
                              </div>
                            </div>
                          </div>

                          <div className={classes.mainField}>
                            <label
                              className={classes.inputLabel}
                              for="address"
                              style={{ height: "6rem" }}
                            >
                              {`${userDetails?.languagejson?.Edit_Profile
                                ?.Gender || "Gender"}`}
                            </label>
                            <div
                              style={{
                                margin: "15px 0px 15px 10px",
                                width: "60%",
                                display: "grid",
                                textAlign: "left",
                                gap: "2rem",
                              }}
                            >
                              <label style={{ display: "flex", gap: "1rem" }}>
                                <input
                                  type="radio"
                                  value={"0"}
                                  checked={gender === "0"}
                                  onChange={handleGenderChange}
                                />
                                {`${userDetails?.languagejson?.Edit_Profile
                                  ?.Male || "Male"}`}
                              </label>

                              <label style={{ display: "flex", gap: "1rem" }}>
                                <input
                                  type="radio"
                                  value={"1"}
                                  checked={gender === "1"}
                                  onChange={handleGenderChange}
                                />
                                {`${userDetails?.languagejson?.Edit_Profile
                                  ?.Female || "Female"}`}
                              </label>
                            </div>
                          </div>
                          <div className={classes.mainField}>
                            <label className={classes.inputLabel} for="address">
                              {`${userDetails?.languagejson?.Edit_Profile
                                ?.GST_Code || "GST Code"}`}
                            </label>
                            <div className={classes.inputFieldContainer}>
                              <input
                                className={classes.inputField}
                                type="text"
                                id="contact"
                                onChange={(e) => {
                                  setGst(e.target.value);
                                }}
                                value={gst}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Grid>

                {addressDetailsShow && (
                  <Grid
                    item
                    sm={12}
                    md={4}
                    className={classes.ONDCEditFieldResponsive}
                  >
                    {" "}
                    <div
                      style={{
                        padding: "20px",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      <div className={classes.mainField}>
                        <label className={classes.inputLabel} for="address">
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Address_line_1 || "Address line 1"}`}
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              padding: "2px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            name="addr1"
                            placeholder="Flat #/Apartment/Street"
                            value={addr.addr1}
                            onChange={handleInputChange}
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {addrOneErr}
                          </div>
                        </div>
                      </div>

                      <div
                        className={classes.mainField}
                        style={{ marginBottom: "1.2rem" }}
                      >
                        <label className={classes.inputLabel} for="address">
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Address_line_2 || "Address line 2"}`}
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              padding: "2px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            name="addr2"
                            placeholder="Area"
                            value={addr.addr2}
                            onChange={handleInputChange}
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {addrTwoErr}
                          </div>
                        </div>
                      </div>

                      <div className={classes.mainField}>
                        <label className={classes.inputLabel} for="address">
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Pincode || "Pincode"}`}
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              padding: "2px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <Autocomplete
                            value={pincodeData}
                            onChange={handleSelectChange}
                            options={pincodes}
                            placeholder="Select Pincode"
                            getOptionLabel={(option) =>
                              option.pincode.toString()
                            } // Convert to string
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Pincode"
                                variant="outlined"
                                className={classes.pincodeStyle}
                              />
                            )}
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {pincodeErr}
                          </div>
                        </div>
                      </div>

                      <div className={classes.mainField}>
                        <label className={classes.inputLabel} for="address">
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.City_District || "City / District"}`}
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            id="contact"
                            disabled
                            //onChange={handleChange("pno")}
                            value={pincodeData?.district_name || ""}
                          />
                        </div>
                      </div>

                      <div className={classes.mainField}>
                        <label className={classes.inputLabel} for="address">
                          {`${userDetails?.languagejson?.Edit_Profile?.State ||
                            "State"}`}
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            id="contact"
                            // onChange={handleChange("pno")}
                            disabled
                            value={pincodeData?.state_name || ""}
                          />
                        </div>
                      </div>

                      <div className={classes.mainField}>
                        <label className={classes.inputLabel} for="address">
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Country || "Country"}`}
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            id="contact"
                            disabled
                            // onChange={handleChange("pno")}
                            value={"India"}
                          />
                        </div>
                      </div>
                      <div className={classes.mainField}>
                        <label className={classes.inputLabel} for="address">
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Company_Name || "Company Name"}`}
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              padding: "2px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            id="contact"
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                            value={companyName}
                            disabled={dontAllowEdit ? true : false}
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {companyNameEr}
                          </div>
                        </div>
                      </div>

                      <div className={classes.mainField}>
                        <label
                          className={classes.inputLabel}
                          for="address"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Company_Email || "Company Email-ID"}`}
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              padding: "2px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            id="contact"
                            onChange={(e) => {
                              setCompanyEmail(e.target.value);
                            }}
                            value={companyEmail}
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {companyEmailEr}
                          </div>
                        </div>
                      </div>

                      <div className={classes.mainField}>
                        <label className={classes.inputLabel} for="address">
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Work_Email || "Work Email-ID"}`}
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <input
                            className={classes.inputField}
                            type="text"
                            id="contact"
                            onChange={(e) => {
                              setWorkEmail(e.target.value);
                            }}
                            value={workEmail}
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {workEmailEr}
                          </div>
                        </div>
                      </div>

                      <div className={classes.mainField}>
                        <label className={classes.inputLabel} for="address">
                          {`${userDetails?.languagejson?.Edit_Profile
                            ?.Work_Ph || "Work Ph.no"}`}
                        </label>
                        <div className={classes.inputFieldContainer}>
                          <PhoneInput
                            className={classes.inputField}
                            international
                            defaultCountry="IN"
                            value={workPhone}
                            onChange={setWorkPhone}
                            error={
                              workPhone
                                ? isValidPhoneNumber(workPhone)
                                  ? undefined
                                  : `${userDetails.languagejson.Edit_Profile
                                      .phone_num_err || "Invalid phone number"}`
                                : `${userDetails.languagejson.Edit_Profile
                                    .phone_num_err2 || "Phone number required"}`
                            }
                          />
                          <div
                            style={{
                              color: "#cc0000",
                              marginBottom: "12px",
                              textAlign: "left",
                            }}
                          >
                            {workPhoneEr}
                          </div>
                        </div>
                        {/*  <input
                          className={classes.inputField}
                          type="number"
                          id="contact"
                          onChange={(e) => {
                            setWorkPhone(e.target.value);
                          }}
                          value={`91+${workPhone}`}
                        /> */}
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
              {!dontAllowEdit && config.aws_org_id === "NSECORP" && (
                <div style={{ textAlign: "center" }}>
                  <label
                    htmlFor="terms"
                    style={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    {" "}
                    {`${userDetails?.languagejson?.Edit_Profile
                      ?.On_click_of_Save_Profile_you_agree_to_the ||
                      "On click of 'Save Profile' you agree to the"}`}{" "}
                  </label>
                  <a
                    href={`${
                      config.aws_cloudfront_url
                    }${config.aws_org_id.toLowerCase()}-resources/documents/Terms.pdf`}
                    className="CheckBoxLinks"
                    target="_blank"
                    // style={{ textDecoration: "none" }}
                  >
                    {`${userDetails?.languagejson?.Edit_Profile
                      ?.Terms_and_Conditions || "Terms and Conditions"}`}
                  </a>
                </div>
              )}
              <div className={classes.buttonSection}>
                <button
                  type="button"
                  onClick={() => onsubmit()}
                  className={User.btn_color}
                  disabled={isLoading}
                >
                  {isLoading
                    ? "Loading..."
                    : `${userDetails?.languagejson?.Edit_Profile
                        ?.Save_profile || "Save profile"}`}
                </button>
                {location.pathname.split("/").indexOf("editProfile") > -1 && (
                  <button
                    type="button"
                    onClick={() =>
                      history.push({
                        pathname: "/profile",
                      })
                    }
                    className={User.btn_colorClose}
                    //disabled={isLoading}
                  >
                    {`${userDetails?.languagejson?.Edit_Profile?.Cancel ||
                      "Cancel"}`}
                  </button>
                )}
              </div>
            </Box>
            {open ? (
              <ChangePassword
                open={open}
                handleClose={handleClose}
                handleOpen={handleOpen}
                userId={user?.eid || ""}
              />
            ) : null}
          </div>
        </>
      ) : (
        <Skeleton variant="rectangular" width="100%">
          <div style={{ paddingTop: "57%" }} />
        </Skeleton>
      )}
    </div>
  );
};

export default EditProfile;
