import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Slick from "../../components/Carousel/Slick";
import { SwiperSlide } from "swiper/react";
import { Link, useHistory } from "react-router-dom";
import { Breadcrumbs, makeStyles } from "@material-ui/core";
import ExploreFilter from "./ExploreFilter/ExploreFilter";
//React router
import { useParams } from "react-router-dom";
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CourseExploreSort from "./ExploreSort/CourseExploreSort";
import "./Explore.scss";
import ContentCard from "../../components/Cards/ContentCard/ContentCard";
import noThingIsHere from "../../assets/Nothing here yet.gif";
import LearningPaths from "../../pages/Learning/LearningPaths";
const useStyles = makeStyles((theme) => ({
  GifDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      display: "contents",
    },
  },
  headerLinks: {
    marginLeft: "2rem",
    marginBottom: "2.5rem",
    "@media (max-width: 500px)": {
      marginLeft: 0,
      marginBottom: 0,
    },
  },
  mainHeader: {
    fontWeight: "600",
    marginLeft: "2rem",
    "@media (max-width: 500px)": {
      marginLeft: 0,
    },
  },
  noThingIsHereImg: {
    width: "30rem",
    "@media (max-width: 500px)": {
      width: "100%",
      height: "100%",
    },
  },
}));
function Explore() {
  const classes = useStyles();
  const userDetails = useSelector(authData);
  const [open, setOpen] = useState(false);
  const [copen, setCOpen] = useState(false);
  const [adProductF, setAdProductF] = useState("");
  const [oObjectData, setOObjectData] = useState([]);
  const [mrToolsF, setMrToolsF] = useState("");
  const [durF, setdurF] = useState("");
  const [ctype, setctypeF] = useState("");
  const [stype, setStypeF] = useState("CON");
  const [stypeV, setStypeFV] = useState("Newest");
  const [openS, setOpenS] = useState(false);
  const [anchorCEl, setAnchorCEl] = useState(null);
  const [anchorElS, setAnchorElS] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  //course setstate
  const [cdurF, setCDurF] = useState("");
  const [cctype, setCctypeF] = useState("");
  const [cstype, setCStypeF] = useState("CON");
  const [cstypeV, setCStypeFV] = useState("Newest");
  const [pstype, setPStypeF] = useState("CON");
  const [crOpenS, setCrOpenS] = useState(false);
  const [canchorElS, setCAnchorElS] = useState(null);
  const [cadProductF, setCAdProductF] = useState("");
  const [cmrToolsF, setCMrToolsF] = useState("");
  const [courseData, setCourseData] = useState([]);
  const [oCourseData, setOCourseData] = useState([]);
  const [objectData, setObjectData] = useState([]);
  const [crOpen, setCrOpen] = useState(false);
  let { name, id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const [visibleCourse, setvisibleCourse] = useState(false);
  const show = () => setvisible(!visible);
  const hide = () => setvisible(false);
  const showCourse = () => setvisibleCourse(!visibleCourse);
  const [isLoading, setIsLoading] = useState(true);
  const [learningDataRes, setLearningDataRes] = useState([]);
  const [learnLoad, setLearnLoad] = useState(false);
  const [objectType, setObjectType] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [initialCourseData, setInitialCourseData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryType, setCategoryType] = useState(["all"]);
  const [duration, setDuration] = useState(null);
  const [progressType, setProgressType] = useState([]);
  const [conditiontype, setConditionType] = useState({
    courses: true,
    objects: true,
    learningPath: true,
  });
  let courseName = name.replace("-", " ");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!userDetails || userDetails === 0) {
      if (config.aws_org_id == "AXIATA") {
        window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=Cognito User Pool"`;
      } else {
        window.location.href = config.aws_cloudfront_lurl;
      }
    } else {
      if (id === "search") {
        searchData();
      } else {
        const storedSelectedOptions =
          JSON.parse(localStorage.getItem("selectedOptions")) || [];
        const storedCategoryType = JSON.parse(
          localStorage.getItem("categoryType")
        ) || ["all"];
        const storedProgressType =
          JSON.parse(localStorage.getItem("progressType")) || [];
        setSelectedOptions(storedSelectedOptions);
        setCategoryType(storedCategoryType);
        setProgressType(storedProgressType);
        getCategoryData({
          storedSelectedOptions,
          storedCategoryType,
          storedProgressType,
        });
      }
    }
  }, []);
  function isEmptyArray(arr) {
    return Array.isArray(arr) && arr.length === 0;
  }
  async function getCategoryData(param) {
    setLearnLoad(true);
    if (param.funcCall === "apply") {
      localStorage.setItem("progressType", JSON.stringify(progressType));
      localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
      localStorage.setItem("categoryType", JSON.stringify(categoryType));
    }
    setIsLoading(true);
    const jsonData = {
      limit: 50,
      page: 1,
      type:
        param.funcCall === "clear"
          ? ["all"]
          : (param.funcCall !== "apply"
            ? param.storedCategoryType.length !== 0
            : null)
          ? param.storedCategoryType
          : categoryType,
      schema: config.schema,
      tid: id,
      oid: config.aws_org_id,
      tenant: userDetails?.uData?.oid,
      admin: false,
      ur_id: userDetails?.uData?.ur_id,
      groups: [16],
      user_lang: userDetails?.uData?.lang,
      progtypes:
        param.funcCall === "clear"
          ? []
          : (param.funcCall !== "apply"
            ? param.storedProgressType.length !== 0
            : null)
          ? param.storedProgressType
          : progressType,
      objtypes:
        param.funcCall === "clear"
          ? []
          : (param.funcCall !== "apply"
            ? param.storedSelectedOptions.length !== 0
            : null)
          ? param.storedSelectedOptions
          : selectedOptions,
      dur: param.funcCall === "clear" ? null : parseInt(duration),
    };
    // Convert JSON to a string and encode it
    const jsonString = encodeURIComponent(JSON.stringify(jsonData));
    // Append the encoded string to the base URL
    const baseUrl = `/getCategoryDetails`; // Replace with your actual URL
    const fullUrl = `${baseUrl}?queryjson=${jsonString}`;
    try {
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        fullUrl,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken
          },
        }
      );
      if (response !== undefined) {
        let {
          objects,
          courses,
          learningPaths,
          objectFormat,
        } = response;
        setConditionType({
          courses: !isEmptyArray(response.courses),
          objects: !isEmptyArray(response.objects),
          learningPath: !isEmptyArray(response.learningPaths),
        });
        setInitialData(objects);
        setLearningDataRes(learningPaths);
        let copyObject = [...objects];
        let temp = [];
        let OCourse = [];
        for (let i = 0; i < courses?.length; i++) {
          if (
            courses[i].pid != undefined &&
            courses[i].pid !== 0 &&
            courses[i].pid.replace(/\s/g, "").length != 0
          ) {
            temp.push(courses[i]);
          } else {
            OCourse.push(courses[i]);
          }
        }
        OCourse?.sort((a, b) => b["con"] - a["con"]);
        setCourseData(OCourse);
        setOCourseData(OCourse);
        setInitialCourseData(OCourse);
        copyObject?.sort((a, b) => b["con"] - a["con"]);
        setObjectData(copyObject);
        setOObjectData(copyObject);
        if (param.funcCall === undefined) {
          if (
            param.storedSelectedOptions.length === 0 &&
            copyObject.length !== 0
          ) {
            let sdata = { ...userDetails };
            let filterObj = [];
            copyObject.forEach((obj) => {
              // Find the corresponding entry in the objectformat array
              const formatEntry = objectFormat.find(
                (entry) => entry.type === obj.otype
              );
              // If a matching entry is found, set the disabled property
              if (formatEntry) {
                formatEntry.disabled = true; // You can set this based on your condition
              }
            });
            filterObj.push(objectFormat);
            sdata.filterObj = filterObj;
            setObjectType(objectFormat);
            dispatch(awsSignIn(sdata));
          }
        }
        if (userDetails.filterObj) {
          setObjectType(userDetails.filterObj[0]);
        }
        setIsLoading(false);
        setLearnLoad(false);
      }
    } catch (error) {
      setIsLoading(false);
      setLearnLoad(false);
      console.error(error);
    }
  }
  async function filterset() {
    if (
      userDetails.filter !== undefined &&
      userDetails.filter.setCStypeF !== undefined
    ) {
      setCStypeF(userDetails.filter.setCStypeF);
      setctypeF(userDetails.filter.setctypeF);
      if (
        (userDetails.filter.setCAdProductF != "" ||
          userDetails.filter.setCMrToolsF != "" ||
          userDetails.filter.setCDurF != "") &&
        userDetails.filter.cdata !== undefined
      ) {
        filter(
          "",
          userDetails.filter.setCAdProductF,
          userDetails.filter.setCMrToolsF,
          userDetails.filter.setCDurF,
          "course"
        );
      } else if (
        userDetails.filter.setCStypeF != "" &&
        userDetails.filter.cdata !== undefined
      ) {
        sortFun(
          userDetails.filter.setCStypeF,
          userDetails.filter.cdata,
          "course"
        );
        commansetsort(userDetails.filter.setCStypeF, "course");
      } else {
        commansetsort("con", "course");
      }
      if (id == "search" && userDetails.filter.setCStypeF !== "AZ") {
        setCStypeFV("Select");
      }
    } else if (
      userDetails.filter !== undefined &&
      userDetails.filter.setCStypeF !== undefined &&
      userDetails.filter.setCStypeF != "" &&
      userDetails.filter.cdata !== undefined
    ) {
      sortFun(
        userDetails.filter.setCStypeF,
        userDetails.filter.cdata,
        "course"
      );
    }
    if (
      id == "search" &&
      (userDetails.filter === undefined ||
        userDetails.filter.setCStypeF === undefined ||
        userDetails.filter.setCStypeF !== "AZ")
    ) {
      setCStypeFV("Select");
    } else if (userDetails.filter !== undefined) {
      commansetsort(userDetails.filter.setCStypeF, "course");
    }
    if (
      userDetails.filter !== undefined &&
      userDetails.filter.setPStypeF != undefined &&
      userDetails.filter.pdata !== undefined
    ) {
      setPStypeF(userDetails.filter.setPStypeF);
      sortFun(
        userDetails.filter.setPStypeF,
        userDetails.filter.pdata,
        "program"
      );
      commansetsort(userDetails.filter.setPStypeF, "program");
    }
    if (
      userDetails.filter !== undefined &&
      userDetails.filter.setAdProductF !== undefined
    ) {
      setAdProductF(userDetails.filter.setAdProductF);
      setMrToolsF(userDetails.filter.setMrToolsF);
      setdurF(userDetails.filter.setdurF);
      setCDurF(userDetails.filter.setCDurF);
      setStypeF(userDetails.filter.setStypeF);
      setStypeF(userDetails.filter.setStypeF);
      if (
        (userDetails.filter.setAdProductF != "" ||
          userDetails.filter.setMrToolsF != "" ||
          userDetails.filter.setdurF != "" ||
          userDetails.filter.setctypeF != "") &&
        userDetails.filter.odata !== undefined
      ) {
        filter(
          userDetails.filter.setctypeF,
          userDetails.filter.setAdProductF,
          userDetails.filter.setMrToolsF,
          userDetails.filter.setdurF,
          "content"
        );
      } else if (
        userDetails.filter.setStypeF != "" &&
        userDetails.filter.odata !== undefined
      ) {
        sortFun(
          userDetails.filter.setStypeF,
          userDetails.filter.odata,
          "content"
        );
      }
      commansetsort(userDetails.filter.setStypeF, "content");
    } else {
      setAdProductF("");
      setMrToolsF("");
      setdurF("");
      setCDurF("");
      setctypeF("");
      setStypeF("con");
      setCMrToolsF("");
      setCAdProductF("");
      setCctypeF("");
      setPStypeF("con");
      commansetsort("con", "program");
      commansetsort("con", "content");
      if (id == "search") {
        setStypeFV("Select");
      }
    }
  }
  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }
  const handlecontentLocation = (OD, OBJID) => {
    const breadcrumbs = { ...userDetails };
    breadcrumbs.location = {
      from: breadcrumbs.location.from,
      topicName: breadcrumbs.location.topicName,
      topicId: breadcrumbs.location.topicId,
      contentName: OD.ON,
    };
    dispatch(awsSignIn(breadcrumbs));
    history.push(`/content?id=${OBJID}`);
  };
  const handleCourseLocation = (tid, title) => {
    const courseLocation = { ...userDetails };
    courseLocation.location = {
      from: courseLocation.location.from,
      topicName: courseLocation.location.topicName,
      topicId: courseLocation.location.topicId,
      courseName: title,
    };
    dispatch(awsSignIn(courseLocation));
    history.push(`/course?id=${tid}`);
  };
  async function searchData() {
    try {
      const requestBody = {
        searchTerm: name,
        tenant: userDetails.locale,
        schema: config.schema,
        urid: userDetails?.uData?.ur_id,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/searchTopics?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      setIsLoading(true);
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	    Accept: "application/json",	
      Authorization: userDetails?.accessToken	
          },
        }
      );
      let OCourse = [];
      const uniqueId = new Set();
      const uniqueSession = response.search.filter((el) => {
        const isDuplicate = uniqueId.has(el.sid);
        uniqueId.add(el.sid);
        if (!isDuplicate) {
          return true;
        }
        return false;
      });
      let apiData = uniqueSession;
      if (apiData !== undefined && apiData.length > 0) {
        let { objects, courses, learning } = convertSearch(apiData);
        setLearningDataRes(learning);
        let temp = [];
        if (courses.length > 0) {
          for (let i = 0; i < courses.length; i++) {
            if (
              courses[i].pid != undefined &&
              courses[i].pid != 0 &&
              courses[i].pid[0].replace(/\s/g, "").length != 0
            ) {
              temp.push(courses[i]);
            } else {
              OCourse.push(courses[i]);
            }
          }
          OCourse.sort((a, b) => {
            if (a.tn < b.tn) {
              return -1;
            }
            if (a.tn > b.tn) {
              return 1;
            }
            return 0;
          });
          setCourseData(OCourse);
          setOCourseData(OCourse);
        }
        let result = groupByKey(temp, "pid");
        let program = [];
        for (const [value] of Object.entries(result)) {
          let obj = {};
          obj.pid = value[0].pid;
          obj.pname = value[0].pname;
          obj.courses = value;
          program.push(obj);
        }
        objects.sort((a, b) => b["con"] - a["con"]);
        setObjectData(objects);
        setOObjectData(objects);
      } else {
        setCourseData([]);
        setOCourseData([]);
      }
      filterset();
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }
  function convertSearch(data) {
    let courses = [];
    let objects = [];
    let learning = [];
    let res = {};
    data.forEach((val) => {
      let v = val;
      if (v.itype !== undefined && v.itype === 1) {
        let obj = {};
        obj.tid = val.sid;
        obj.dur = v.dur !== undefined ? v.dur : 0;
        obj.nugcnt = v.nugcnt !== undefined ? v.nugcnt : 0;
        obj.nav = v.nav !== undefined ? v.nav : "";
        obj.ctype = v.ctype !== undefined ? v.ctype : "";
        obj.cert = v.cert !== undefined ? v.cert : "";
        obj.tn = v.tn !== undefined ? v.tn : "";
        obj.pid = v.pid !== undefined ? v.pid : undefined;
        obj.pname = v.pname !== undefined ? v.pname : undefined;
        obj.sub_date = v.sub_date !== undefined ? v.sub_date : undefined;
        obj.comp_date = v.comp_date !== undefined ? v.comp_date : undefined;
        courses.push(obj);
      }
      if (v.itype !== undefined && v.itype === 2) {
        let obj = {};
        let od = {};
        obj.con = v.con !== undefined ? v.con : "";
        obj.dur = v.dur !== undefined ? v.dur : 0;
        obj.objid = v.sid;
        obj.otype = v.otype !== undefined ? v.otype : 0;
        obj.oname = v.tn !== undefined ? v.tn : 0;
        obj.sortdt = v.sortdt !== undefined && v.sortdt;
        obj.OD = od;
        objects.push(obj);
      }
      if (v.itype !== undefined && v.itype === 3) {
        let obj = {};
        obj.lname = v.tn;
        obj.bookmark = v.sortdt?.bookmark;
        obj.lp_id = v.sid;
        obj.dur = v.dur;
        learning.push(obj);
      }
    });
    res.courses = courses;
    res.objects = objects;
    res.learning = learning;
    return res;
  }
  function contentviewAll() {
    let sdata = { ...userDetails };
    sdata.curExploreData = {
      courseName: courseName,
      from: id == "search" ? "search" : "explore",
      type: "content",
      data: oObjectData,
      topicName: name,
      topicId: id,
    };
    sdata.location = {
      from: sdata.location.from,
      topicName: sdata.location.topicName,
      topicId: sdata.location.topicId,
      viewAll: true,
    };
    if (sdata.filter !== undefined) {
      let filters = { ...sdata.filter };
      filters.cdata = oObjectData;
      sdata.filter = filters;
    } else {
      sdata.filter = {};
      sdata.filter.cdata = oObjectData;
    }
    dispatch(awsSignIn(sdata));
    history.push("/ExploreViewAll");
  }
  function courseViewAll() {
    let sdata = { ...userDetails };
    sdata.curExploreProgramData = {
      from: id === "search" ? "search" : "explore",
      pname: "View all",
      type: "Courses",
      courses: oCourseData,
      tnAME: name,
      TOPICID: id,
      viewall: false,
    };
    sdata.location = {
      from: sdata.location.from,
      topicName: sdata.location.topicName,
      topicId: sdata.location.topicId,
      viewAll: true,
      viewAllId: sdata.location.topicId,
    };
    dispatch(awsSignIn(sdata));
    history.push(`/ExploreCourseViews`);
  }
  function filter(otype, adprod, mrtool, dur, vtype) {
    let data;
    if (vtype == "course") {
      data =
        oCourseData === undefined || oCourseData == ""
          ? userDetails.filter.cdata
          : oCourseData;
    } else {
      data =
        oObjectData === undefined || oObjectData == ""
          ? userDetails.filter.odata
          : oObjectData;
    }
    let filteredData = [];
    filteredData = data.filter(function(e) {
      let obj;
      if (vtype == "content") {
        obj = { ...e.OD };
      } else {
        obj = { ...e };
      }
      let res = false;
      let o = false;
      let a = false;
      let m = false;
      let d = false;
      if (otype != "" && vtype == "content") {
        if (otype == e.otype) {
          o = true;
        }
        if (
          otype == "video" &&
          (e.otype == "scorm" || e.otype == "youtube" || e.otype == "vimeo")
        ) {
          o = true;
        }
      }
      if (adprod != "") {
        if (vtype == "content") {
          if (obj.ADPRODUCTS != undefined) {
            for (let i = 0; i < obj.ADPRODUCTS.length; i++) {
              if (adprod == obj.ADPRODUCTS[i]) {
                a = true;
                break;
              }
            }
          }
        } else {
          if (obj.adproducts != undefined) {
            for (let i = 0; i < obj.adproducts.length; i++) {
              if (adprod == obj.adproducts[i]) {
                a = true;
                break;
              }
            }
          }
        }
      }
      if (mrtool != "") {
        if (vtype == "content") {
          if (obj.MRTOOLS != undefined) {
            for (let i = 0; i < obj.MRTOOLS.length; i++) {
              if (mrtool == obj.MRTOOLS[i]) {
                m = true;
                break;
              }
            }
          }
        } else {
          if (obj.mrtools != undefined) {
            for (let i = 0; i < obj.mrtools.length; i++) {
              if (mrtool == obj.mrtools[i]) {
                m = true;
                break;
              }
            }
          }
        }
      }
      if (vtype == "content") {
        if (dur != "") {
          if (dur == "5") {
            if (e.dur < 300) {
              d = true;
            }
          } else if (dur == "15") {
            if (e.dur < 900) {
              d = true;
            }
          } else if (dur == "30") {
            if (e.dur < 1800) {
              d = true;
            }
          } else if (dur == "30_60") {
            if (e.dur >= 1800 && e.dur <= 3600) {
              d = true;
            }
          } else if (dur == "60") {
            if (e.dur > 3600) {
              d = true;
            }
          }
        }
      } else {
        if (dur != "") {
          if (dur == "5") {
            if (e.dur < 300) {
              d = true;
            }
          } else if (dur == "15") {
            if (e.dur < 900) {
              d = true;
            }
          } else if (dur == "30") {
            if (e.dur < 1800) {
              d = true;
            }
          } else if (dur == "30_60") {
            if (e.dur >= 1800 && e.dur <= 3600) {
              d = true;
            }
          } else if (dur == "60") {
            if (e.dur > 3600) {
              d = true;
            }
          }
        }
      }
      // All Value
      if (otype != "" && adprod != "" && mrtool != "" && dur != "") {
        if (o && a && m && d) {
          res = true;
        }
      }
      // otype adprod mrtool
      if (otype != "" && adprod != "" && mrtool != "" && dur == "") {
        if (o && a && m) {
          res = true;
        }
      }
      // otype adprod  dur
      if (otype != "" && adprod != "" && mrtool == "" && dur != "") {
        if (o && a && d) {
          res = true;
        }
      }
      // otype  mrtool dur
      if (otype != "" && adprod == "" && mrtool != "" && dur != "") {
        if (o && m && d) {
          res = true;
        }
      }
      //  adprod mrtool dur
      if (otype == "" && adprod != "" && mrtool != "" && dur != "") {
        if (a && m && d) {
          res = true;
        }
      }
      // otype adprod
      if (otype != "" && adprod != "" && mrtool == "" && dur == "") {
        if (o && a) {
          res = true;
        }
      }
      //  adprod mrtool
      if (otype == "" && adprod != "" && mrtool != "" && dur == "") {
        if (a && m) {
          res = true;
        }
      }
      //   mrtool dur
      if (otype == "" && adprod == "" && mrtool != "" && dur != "") {
        if (m && d) {
          res = true;
        }
      }
      // otype  dur
      if (otype != "" && adprod == "" && mrtool == "" && dur != "") {
        if (o && d) {
          res = true;
        }
      }
      // otype
      if (otype != "" && adprod == "" && mrtool == "" && dur == "") {
        if (o) {
          res = true;
        }
      }
      // adprod
      if (otype == "" && adprod != "" && mrtool == "" && dur == "") {
        if (a) {
          res = true;
        }
      }
      // mrtool
      if (otype == "" && adprod == "" && mrtool != "" && dur == "") {
        if (m) {
          res = true;
        }
      }
      // dur
      if (otype == "" && adprod == "" && mrtool == "" && dur != "") {
        if (d) {
          res = true;
        }
      }
      if (otype == "" && adprod == "" && mrtool == "" && dur == "") {
        res = true;
      }
      return res;
    });
    if (vtype == "course") {
      setCourseData(filteredData);
    } else {
      setObjectData(filteredData);
    }
    let valstype = stype;
    if (userDetails.filter !== undefined) {
      valstype = userDetails.filter.setStypeF;
      if (vtype == "course") {
        valstype = userDetails.filter.setCStypeF;
      }
    } else if (valstype == "") {
      valstype = "con";
    }
    sortFun(valstype, filteredData, vtype);
  }
  function sortFun(val, obdata, codata, vtype) {
    let data = obdata == undefined ? [...objectData] : [...obdata];
    let cdata = codata == undefined ? [...courseData] : [...obdata];
    if (vtype == "course") {
      if (val == "AZ") {
        cdata.sort((a, b) => {
          if (a.tn < b.tn) {
            return -1;
          }
          if (a.tn > b.tn) {
            return 1;
          }
          return 0;
        });
      } else if (val == "TV") {
        cdata.sort((a, b) => {
          return b.sortdt.tv - a.sortdt.tv;
        });
      } else if (val == "BKMD") {
        cdata.sort((a, b) => {
          return b?.sortdt?.bookmark - a?.sortdt?.bookmark;
        });
      } else if (val == "SHRD") {
        cdata.sort((a, b) => {
          return b.sortdt.share - a.sortdt.share;
        });
      } else if (val == "STAR") {
        cdata.sort((a, b) => {
          return b.sortdt.rate - a.sortdt.rate;
        });
      } else if (val == "CON") {
        cdata.sort((a, b) => {
          return b["con"] - a["con"];
        });
      } else {
        cdata.sort((a, b) => b[val] - a[val]); 
      }
      setCourseData(cdata);
    } else if (vtype == "program") {
      data.sort((a, b) => b[val] - a[val]); 
    } else {
      if (val == "AZ") {
        data.sort((a, b) => {
          if (a.oname < b.oname) {
            return -1;
          }
          if (a.oname > b.oname) {
            return 1;
          }
          return 0;
        });
      } else if (val == "TV") {
        data.sort((a, b) => {
          return b.sortdt.tv - a.sortdt.tv;
        });
      } else if (val == "BKMD") {
        data.sort((a, b) => {
          return b?.sortdt?.bookmark - a?.sortdt?.bookmark;
        });
      } else if (val == "SHRD") {
        data.sort((a, b) => {
          return b.sortdt.share - a.sortdt.share;
        });
      } else if (val == "STAR") {
        data.sort((a, b) => {
          return b.sortdt.rate - a.sortdt.rate;
        });
      } else if (val == "CON") {
        data.sort((a, b) => {
          return b["con"] - a["con"];
        });
      } else {
        data.sort((a, b) => b[val] - a[val]); 
      }
      setObjectData(data);
    }
  }
  const commansetsort = (val, vtype) => {
    if (vtype == "content") {
      if (val == "tv") {
        setStypeFV("Most Viewed");
      }
      if (val == "STAR") {
        setStypeFV("Rating");
      }
      if (val == "BKMD") {
        setStypeFV("Most Bookmarked");
      }
      if (val == "SHRD") {
        setStypeFV("Most Shared");
      }
      if (val == "con") {
        setStypeFV("Newest");
      }
      if (val == "AZ") {
        setStypeFV("A-Z");
      }
    } else if (vtype == "course") {
      if (val == "tv") {
        setCStypeFV("Most Viewed");
      }
      if (val == "STAR") {
        setCStypeFV("Rating");
      }
      if (val == "BKMD") {
        setCStypeFV("Most Bookmarked");
      }
      if (val == "SHRD") {
        setCStypeFV("Most Shared");
      }
      if (val == "con") {
        setCStypeFV("Newest");
      }
      if (val == "AZ") {
        setCStypeFV("A-Z");
      }
    } else {
      if (val == "tv") {
      }
      if (val == "con") {
      }
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    show();
  };
  const handleCourseClick = (event) => {
    setAnchorCEl(event.currentTarget);
    setCOpen(!copen);
    setvisibleCourse(false);
    showCourse();
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };
  const contentTypeChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setdurF = durF;
    filterstoredata.filter.setCDurF = cdurF;
    if (ctype == event.target.value) {
      setctypeF("");
      val = "";
      filterstoredata.filter.setctypeF = "";
    } else {
      setctypeF(event.target.value);
      filterstoredata.filter.setctypeF = event.target.value;
    }
    filterstoredata.filter.odata = oObjectData;
    filterstoredata.filter.cdata = oCourseData;
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setCStypeF = cstype;
    filterstoredata.filter.setPStypeF = pstype;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    dispatch(awsSignIn(filterstoredata));
    filter("", cadProductF, cmrToolsF, cdurF, "course");
    filter(val, adProductF, mrToolsF, durF, "content");
  };
  function clear() {
    setCategoryType(["all"]);
    setSelectedOptions([]);
    setProgressType([]);
    setDuration(null);
    setObjectData(oObjectData);
    setctypeF("");
    setAdProductF("");
    setMrToolsF("");
    setdurF("");
    setAnchorEl(null);
    setOpen(!open);
    setvisible(false);
    setStypeF(stype);
    setStypeFV(stypeV);
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    sortFun(stype, oObjectData, "content");
    localStorage.removeItem("selectedOptions");
    localStorage.removeItem("categoryType");
    localStorage.removeItem("progressType");
    getCategoryData({ funcCall: "clear" });
  }
  function sclear() {
    setCctypeF("");
    setCAdProductF("");
    setCMrToolsF("");
    setCDurF("");
    setAnchorCEl(null);
    setCrOpenS(false);
    setCrOpen(false);
    setCStypeF(stype);
    setCStypeFV(stypeV);
    setvisibleCourse(false);
    setCourseData(oCourseData);
    setCategoryType(["all"]);
    setSelectedOptions([]);
    setProgressType([]);
    setDuration(null);
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    sortFun(stype, oCourseData, "course");
    localStorage.removeItem("selectedOptions");
    localStorage.removeItem("categoryType");
    localStorage.removeItem("progressType");
    getCategoryData({ funcCall: "clear" });
  }
  const AdHandleChange = (event, vtype) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setdurF = durF;
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setctypeF = ctype;
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setPStypeF = pstype;
    filterstoredata.filter.setCStypeF = cstype;
    filterstoredata.filter.vtype = vtype;
    if (vtype == "course") {
      if (cadProductF == event.target.value) {
        setCAdProductF("");
        filterstoredata.filter.setCAdProductF = "";
        val = "";
      } else {
        setCAdProductF(event.target.value);
        filterstoredata.filter.setCAdProductF = event.target.value;
      }
      filterstoredata.filter.setAdProductF = adProductF;
    } else {
      if (adProductF == event.target.value) {
        setAdProductF("");
        filterstoredata.filter.setAdProductF = "";
        val = "";
      } else {
        setAdProductF(event.target.value);
        filterstoredata.filter.setAdProductF = event.target.value;
      }
      filterstoredata.filter.setCAdProductF = cadProductF;
    }
    filterstoredata.filter.odata = oObjectData;
    filterstoredata.filter.cdata = oCourseData;
    dispatch(awsSignIn(filterstoredata));
    if (vtype == "course") {
      filter("", val, cmrToolsF, cdurF, vtype);
    } else {
      filter(ctype, val, mrToolsF, durF, vtype);
    }
  };
  const ToolsHandleChange = (event, vtype) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setdurF = durF;
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setctypeF = ctype;
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setPStypeF = pstype;
    filterstoredata.filter.setCStypeF = cstype;
    filterstoredata.filter.vtype = vtype;
    if (vtype == "course") {
      if (cmrToolsF == event.target.value) {
        setCMrToolsF("");
        filterstoredata.filter.setCMrToolsF = "";
        val = "";
      } else {
        setCMrToolsF(event.target.value);
        filterstoredata.filter.setCMrToolsF = event.target.value;
      }
      filterstoredata.filter.setMrToolsF = mrToolsF;
    } else {
      if (mrToolsF == event.target.value) {
        setMrToolsF("");
        val = "";
        filterstoredata.filter.setMrToolsF = "";
      } else {
        setMrToolsF(event.target.value);
        filterstoredata.filter.setMrToolsF = event.target.value;
      }
      filterstoredata.filter.setCMrToolsF = cmrToolsF;
    }
    filterstoredata.filter.cdata = oCourseData;
    filterstoredata.filter.odata = oObjectData;
    dispatch(awsSignIn(filterstoredata));
    if (vtype == "course") {
      filter("", cadProductF, val, cdurF, vtype);
    } else {
      filter(ctype, adProductF, val, durF, vtype);
    }
  };
  const DurHandleChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setctypeF = ctype;
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setPStypeF = pstype;
    filterstoredata.filter.setCStypeF = cstype;
    let vtype;
    if (vtype == "course") {
      if (cdurF == event.target.value) {
        setCDurF("");
        val = "";
        filterstoredata.filter.setCDurF = "";
      } else {
        setCDurF(event.target.value);
        filterstoredata.filter.setCDurF = event.target.value;
      }
      filterstoredata.filter.setdurF = durF;
    } else {
      if (durF == event.target.value) {
        setdurF("");
        val = "";
        filterstoredata.filter.setdurF = "";
      } else {
        setdurF(event.target.value);
        filterstoredata.filter.setdurF = event.target.value;
      }
      filterstoredata.filter.setCDurF = cdurF;
    }
    if (cdurF == event.target.value) {
      setCDurF("");
      val = "";
      filterstoredata.filter.setCDurF = "";
    } else {
      setCDurF(event.target.value);
      filterstoredata.filter.setCDurF = event.target.value;
    }
    dispatch(awsSignIn(filterstoredata));
    filter("", cadProductF, cmrToolsF, val, "course");
    filter(ctype, adProductF, mrToolsF, val, "content");
  };
  const handleClickS = (event) => {
    setAnchorElS(event.currentTarget);
    setOpenS(!openS);
  };
  const chandleClickS = (event) => {
    setCAnchorElS(event.currentTarget);
    setCrOpenS(!crOpenS);
  };
  const handleCloseS = () => {
    setAnchorElS(null);
    setOpenS(!openS);
  };
  const chandleCloseS = () => {
    setCAnchorElS(null);
    setCrOpenS(!crOpenS);
  };
  const sortTypeChange = (event) => {
    let data, cdata;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setctypeF = ctype;
    filterstoredata.filter.setdurF = durF;
    filterstoredata.filter.setCDurF = cdurF;
    setCStypeF(event.target.value);
    let val = event.target.value;
    sortFun(val, data, cdata, "course");
    sortFun(val, data, cdata, "content");
    commansetsort(val);
    //for redex changes
    dispatch(awsSignIn(filterstoredata));
  };
  async function addAndRemoveBookmark(val, id, idx) {
    try {
      const databody = {oid: config.aws_org_id,
        tid: id,
        type: "topics",
        eid: userDetails.username,
        userfullname: userDetails.name,
        emailid: userDetails?.email,
        tenant: userDetails?.locale,
        ur_id: userDetails?.uData?.ur_id,
        bookmark: val === 1 ? true : false,
        schema: config.schema}
      if (val === 1) {
        databody.bookmark_date = 1;
      }
      const jsonString = JSON.stringify(databody);
      const base64EncodedString = btoa(jsonString);
      const bodyParam = {
        body: {
          json:base64EncodedString
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken	
        },
      };
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );
      let updateBookmark = courseData ? [...courseData] : [...oCourseData];
      updateBookmark[idx].bk = val === 1 ? true : false;
      if (courseData && courseData?.length > 0) {
        setCourseData(updateBookmark);
      } else {
        setOCourseData(updateBookmark);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const addAndRemoveBookmarkContent = async (val, id, idx) => {
    const databody = {
      objid: id,
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema
    }
    if (val === 0) {
      databody.bookmark = true;
      databody.bookmark_date = 1;
    } else {
      databody.bookmark = false;
    }
    const jsonString = JSON.stringify(databody);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
      let updateBookmark = objectData ? [...objectData] : [...oObjectData];
      updateBookmark[idx].bk = val === 0 ? true : false;
      if (objectData && objectData?.length > 0) {
        setObjectData(updateBookmark);
      } else {
        setOObjectData(updateBookmark);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div>
      {userDetails !== 0 && (
        <>
          {userDetails.location.from !== undefined &&
            (userDetails.location.from === "Home" ||
              userDetails.location.from === "Explore" ||
              userDetails.location.from === "Search") && (
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="large"
                    style={{ fill: "var(--color-primary)" }}
                  />
                }
                aria-label="breadcrumb"
                className={classes.headerLinks}
              >
                <Link
                  color="inherit"
                  to={
                    userDetails.location.from === "Home"
                      ? "/home"
                      : "/exploreTopics"
                  }
                  style={{
                    color: `${
                      userDetails.location.from === "Home" ||
                      userDetails.location.from === "Explore"
                        ? "#1974b5"
                        : "#777"
                    }`,
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: `${
                      userDetails.location.from === "Home" ||
                      userDetails.location.from === "Explore"
                        ? "pointer"
                        : "context-menu"
                    }`,
                  }}
                >
                  {userDetails.location.from}
                </Link>
                {userDetails.location.topicName &&
                userDetails.location.topicId ? (
                  <Link
                    style={{
                      color: "#777",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: "context-menu",
                    }}
                    color="inherit"
                    to={`/explore/${userDetails.location.topicName}/${userDetails.location.topicId}`}
                  >
                    {userDetails.location.topicName}
                  </Link>
                ) : null}
              </Breadcrumbs>
            )}
        </>
      )}
      <div className="flex-between">
        <div
          className="flex-container"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 component="h1" variant="h4" className={classes.mainHeader}>
            {userDetails?.location?.topicName}
          </h3>
          <div
            style={{
              display: "flex",
            }}
          >
            <CourseExploreSort
              sortTypeChange={sortTypeChange}
              cstype={cstype}
              sorttp={userDetails}
            />
            <ExploreFilter
              handleClick={handleClick}
              anchorEl={anchorEl}
              handleClose={handleClose}
              contentTypeChange={contentTypeChange}
              AdHandleChange={AdHandleChange}
              adProductF={adProductF}
              DurHandleChange={DurHandleChange}
              open={open}
              ctype={ctype}
              clear={clear}
              ToolsHandleChange={ToolsHandleChange}
              mrToolsF={mrToolsF}
              durF={durF}
              visible={visible}
              hide={hide}
              objectType={objectType}
              initialCourseData={initialCourseData}
              initialData={initialData}
              setInitialData={setInitialData}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              setProgressType={setProgressType}
              progressType={progressType}
              getCategoryData={getCategoryData}
              categoryType={categoryType}
              setCategoryType={setCategoryType}
              duration={duration}
              setDuration={setDuration}
            />
          </div>
        </div>
      </div>
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            marginTop: "30px",
          }}
        >
          {!isLoading &&
          courseData?.length === 0 &&
          objectData?.length === 0 &&
          learningDataRes?.length === 0 ? (
            <>
              <div className={classes.GifDiv}>
                <img
                  src={noThingIsHere}
                  alt="Nothing is here yet"
                  className={classes.noThingIsHereImg}
                  title="Nothing is here yet"
                />
              </div>
            </>
          ) : (
            !isLoading && (
              <>
                {!conditiontype.courses ? (
                  <></>
                ) : (
                  <>
                    <div>
                      <Slick
                        heading={`${userDetails?.languagejson?.Landing_Page
                          ?.Courses || "Courses"}`}
                        viewall={courseViewAll}
                        handleClick={handleCourseClick}
                        anchorEl={anchorCEl}
                        handleClose={handleClose}
                        contentTypeChange={contentTypeChange}
                        AdHandleChange={AdHandleChange}
                        cadProductF={cadProductF}
                        DurHandleChange={DurHandleChange}
                        crOpen={crOpen}
                        cctype={cctype}
                        sclear={sclear}
                        ToolsHandleChange={ToolsHandleChange}
                        cmrToolsF={cmrToolsF}
                        cdurF={cdurF}
                        filter="true"
                        viewtype="course"
                        chandleClickS={chandleClickS}
                        canchorElS={canchorElS}
                        chandleCloseS={chandleCloseS}
                        sortTypeChange={sortTypeChange}
                        cstypeV={cstypeV}
                        cropenS={crOpenS}
                        cstype={cstype}
                        hctype={false}
                        visible={visibleCourse}
                        userDetails={userDetails.location.from}
                        data={courseData}
                        oCData={oCourseData}
                        loading={isLoading}
                      >
                        {courseData.map(
                          (
                            {
                              ttitle,
                              tid,
                              tnuggets,
                              tn,
                              nugcnt,
                              sortdt,
                              bk,
                              sub_date,
                              comp_date,
                              cobj,
                              tobj,
                            },
                            idx
                          ) => (
                            <SwiperSlide
                              key={idx}
                              className="slider-container"
                              tabIndex={1}
                            >
                              <CardLarge
                                tid={tid}
                                title={tn}
                                type={"topic"}
                                count={nugcnt}
                                bookmark={bk}
                                sub_date={sub_date}
                                comp_date={comp_date}
                                cobj={cobj}
                                tobj={tobj}
                                handleBookmark={addAndRemoveBookmark}
                                handleLocation={() =>
                                  handleCourseLocation(tid, tn)
                                }
                                index={idx}
                                star={sortdt?.rate}
                              />
                            </SwiperSlide>
                          )
                        )}
                      </Slick>
                    </div>
                  </>
                )}
                {!conditiontype.objects ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <div>
                      <Slick
                        heading={`${userDetails?.languagejson?.general_terms
                          ?.Content_Repository || "Content Repository"}`}
                        viewall={contentviewAll}
                        handleClick={handleClick}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        contentTypeChange={contentTypeChange}
                        AdHandleChange={AdHandleChange}
                        adProductF={adProductF}
                        DurHandleChange={DurHandleChange}
                        open={open}
                        ctype={ctype}
                        clear={clear}
                        ToolsHandleChange={ToolsHandleChange}
                        mrToolsF={mrToolsF}
                        durF={durF}
                        filter="true"
                        viewtype="content"
                        handleClickS={handleClickS}
                        anchorElS={anchorElS}
                        handleCloseS={handleCloseS}
                        sortTypeChange={sortTypeChange}
                        stypeV={stypeV}
                        openS={openS}
                        stype={stype}
                        visible={visible}
                        show={show}
                        hide={hide}
                        userDetails={userDetails.location.from}
                        data={objectData}
                        oCData={oObjectData}
                        loading={isLoading}
                      >
                        {objectData.map(
                          ({ objid, otype, oname, dur, sortdt, bk }, idx) => {
                            let OD = {
                              OTYPE: otype,
                              ON: oname,
                              DUR: dur,
                            };
                            return (
                              <SwiperSlide
                                key={idx}
                                className="slider-container"
                                tabIndex={1}
                              >
                                <ContentCard
                                  key={objid + "ASDF"}
                                  otype={otype}
                                  star={sortdt?.rate}
                                  title={oname}
                                  bookmark={bk}
                                  tid={objid}
                                  handleBookmark={addAndRemoveBookmarkContent}
                                  handleLocation={() =>
                                    handlecontentLocation(OD, objid)
                                  }
                                  index={idx}
                                  dur={dur}
                                />
                              </SwiperSlide>
                            );
                          }
                        )}
                      </Slick>
                    </div>
                  </>
                )}
              </>
            )
          )}
          <div>
            {config.learningpath &&
              conditiontype.learningPath &&
              learningDataRes &&
              learningDataRes.length !== 0 && (
                <LearningPaths
                  page="Explore"
                  isLoading={learnLoad}
                  learningData={learningDataRes}
                />
              )}
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}
export default Explore;
