import { Breadcrumbs, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { HeadingPrimary } from "../../components/Styles/Heading";
import CardPrimary from "../../components/Cards/Card.Primary";
import { useSelector, useDispatch } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import config, { Theme } from "../../aws-exports";
import API from "@aws-amplify/api";
// import { Skeleton } from "@mui/material";s
import { session } from "../../redux/Session/Session";
import moment from "moment";
import { useHistory } from "react-router-dom";
import emptysearch from "../../assets/Nothing here yet.gif";

const Wishlist = () => {
  // const interests = useSelector((state) => state.interests.value);
  const history = useHistory();
  const userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [myInterests, setMyInterests] = useState([]);

  useEffect(() => {
    getMyWishlist();
  }, []);

  async function getMyWishlist() {
    setLoading(true);
    // try {
    //   const bodyParam = {
    //     body: {
    //       oid: config.aws_org_id,
    //       tenant: userDetails.locale,
    //       eid: userDetails.username,
    //       ur_id: userDetails?.uData?.ur_id,
    //       schema: config.schema,
    //     },
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //     },
    //   };
    //   const response = await API.post(
    //     config.aws_cloud_logic_custom_name,
    //     "/getMyInterests",
    //     bodyParam
    //   );

    try {
      const requestBody = {
        oid: config.aws_org_id,
          tenant: userDetails.locale,
          eid: userDetails.username,
          ur_id: userDetails?.uData?.ur_id,
          schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getMyInterests?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	    Accept: "application/json",	
      Authorization: userDetails?.accessToken	
          },
        }
      );
      setMyInterests(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const breadcrumbs = [
    <Typography key="3" style={{fontSize: "1.5rem", textDecoration: "none", cursor: "context-menu", fontFamily: "myfontregular"}}>
      Wishlist
    </Typography>,
  ];
  return (
    <>
      <Stack spacing={2} margin={{ xs: "1rem 0rem", sm: "3rem 0rem" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" style={{color:"var(--color-primary)"}} />}
          aria-label="breadcrumb"
          // sx={{ color: Theme.color.text }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HeadingPrimary> My Wishlist</HeadingPrimary>
        </Stack>
        {isLoading ? (
          <>{/* <SkeletonData /> */}</>
        ) : (
          <>
            <Grid container spacing={2}>
              {myInterests?.items?.map(
                (item, idx) =>
                  item.type === 3 && (
                    <Grid xs={12} sm={6} md={4} lg={3} style={{display: "flex"}}>
                      <CardPrimary
                        key={idx}
                        img={
                          item?.tid &&
                          `${
                            config.aws_cloudfront_url
                          }${config.aws_org_id.toLowerCase()}-resources/images/${
                            item.type === 1 ? "topic" : "session"
                          }-images/${item?.tid}.png`
                        }
                        Auth={true}
                        title={item.tname[0].title}
                        addToFavorite={true}
                        sessionDate={
                          item?.tname[0]?.date_list !== undefined &&
                          item?.tname[0]?.date_list !== null &&
                          item.tname[0].date_list[0].combineStartTime !==
                            undefined
                            ? moment(
                                new Date(
                                  item.tname[0].date_list[0].combineStartTime
                                ).getTime()
                              ).format("DD MMM YYYY")
                            : item?.startdate
                            ? moment(parseInt(item?.startdate)).format(
                                "DD MMM YYYY"
                              )
                            : "yet to be scheduled"
                        }
                        handleLocation={() => {
                          history.push(`/session?sid=${item.tid}`);
                          dispatch(session({ ...item, path: "wishlist" }));
                        }}
                      />
                    </Grid>
                  )
                // : item.type === 1 && (
                //   <Grid xs={12} sm={6} md={4} lg={3}>
                //     <CardPrimary
                //       key={idx}
                //       img={
                //         item?.tid &&
                //         `https://${
                //           config.aws_cloudfront_url
                //         }/${config.aws_org_id.toLowerCase()}-resources/images/${
                //           "topic"
                //         }-images/${item?.tid}.png`
                //       }
                //       Auth={true}
                //       title={item.tname[0].title}
                //       addToFavorite={true}

                //       handleLocation={() => {
                //         history.push(`/course?id=${item.tid}`);
                //         dispatch(session({ ...item, path: "wishlist" }));
                //       }}
                //     />
                //   </Grid>
                // )
              )}
            </Grid>
            {!myInterests?.items?.find((item) => item.type === 3) && (
              <div
                style={{
                  height: "60vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img src={emptysearch} alt="emptysearch"
                title="Nothing is here yet" style={{width:"30rem"}} />
                {/* <h3>Nothing here yet!</h3> */}
              </div>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default Wishlist;
