import React, { useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";
import config from "../../aws-exports";
import API from "@aws-amplify/api";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { Button, Grid, Paper, Box, InputBase, IconButton } from "@mui/material";
import Person2Icon from "@mui/icons-material/Person2";
import PostFooterView from "../../components/SocialLearning/PostFooterView";
import PostHeaderView from "../../components/SocialLearning/PostHeaderView";
import SearchHeader from "../../components/SocialLearning/SearchHeader";
import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CardMedia, CircularProgress, Backdrop } from '@mui/material';
import { callAPI, } from "../../api/api";
import certificateImage from "../../assets/media/homepage.jpg";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelIcon from '@mui/icons-material/Cancel';
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import SendIcon from '@mui/icons-material/Send';
import Polling from "../../components/SocialLearning/Polling/Polling";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  imgSlider: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    alignItems: "center",
  },
  headerAction: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0rem 7rem",
  },
}));

function SocialLearning() {
  const history = useHistory();
  const userDetails = useSelector(authData);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const classes = useStyles();

    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
      <div>
        <div className={classes.imgSlider}>
          <Button onClick={prevSlide}><ArrowBackIosNewIcon style={{ fontSize: "4rem", color: "lightgray" }} /></Button>
          <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} style={{ width: "55%" }} />
          <Button onClick={nextSlide}><ArrowForwardIosIcon style={{ fontSize: "4rem", color: "lightgray" }} /></Button>
        </div>
        <p style={{ position: "absolute", top: 0, left: "50%", fontSize: "2rem", color: "lightgray", }}>
          {currentIndex + 1} / {images.length}
        </p>
      </div>
    );
  };

  const useStyles = makeStyles((themeStyle) => ({
    mainLayout: {
      margin: "0rem 12rem",
      "@media (max-width: 900px)": {
        margin: "0rem",
      },
    },
    loaderSkeletonSlider: {
      width: "100%",
      height: "25rem",
    },
    cmtloaderSkeletonSlider:{
      width: "100%",
      height: "5rem",
    },
    postSlider: {
      marginBottom: "3rem",
    },
    sliderData: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
    sliderImage: {
      width: "26rem",
      filter: "brightness(0.8)",
      borderRadius: "2rem",
      position: "relative",
      left: "3rem",
    },
    actionButtonFollow: {
      borderRadius: "3rem!important",
      border: "2px solid!important",
      borderColor: "var(--color-button-background)!important",
      backgroundColor: "transparent!important",
      margin: "0!important",
      padding: "0px 10px!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      "&:active": {
        color: "var(--color-button-background)!important",
      },
    },
    myPostButton: {
      display: "flex!important",
      justifyContent: "space-between!important",
      fontSize: "1.5rem!important",
      padding: "0!important",
      textTransform: "capitalize!important",
      background: "white!important",
      width: "100%",
      "& > div": {
        display: "flex",
        gap: "1rem",
        fontSize: "1.8rem",
        fontWeight: "700",
        color: "var(--color-button-background)",
        alignItems: "center",
      },
    },
    sliderImageFull: {
      width: "100%",
      objectFit: 'contain',
      height: '315px',
      cursor: "pointer",
    },
    showMoreSection: {
      padding: "1rem",
      backgroundColor:
        userDetails.themeDark === "dark" ? "transparent" : "#EBEBEB",
      borderTop: "1px solid #EBEBEB",
    },
    leftPaper: {
      width: "100%",
      margin: "1rem 0rem!important",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none!important"
          : "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
    },
    leftPaperBottom: {
      width: "100%",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none!important"
          : "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
      margin: "1rem 0!important",
      padding: "2rem",
    },
    rightPaper1: {
      width: "100%",
      margin: "0rem!important",
      padding: "0rem!important",
      overflow: "hidden",
      borderRadius: "1rem!important",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none!important"
          : "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
    },
    rightPaper: {
      width: "100%",
      margin: "1rem 0rem!important",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none!important"
          : "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
    },
    secondGridResponsive: {
      padding: "1rem 0rem 1rem 1rem",
      "@media (max-width: 900px)": {
        padding: "1rem 1rem 1rem 0rem!important",
      },
    },
    backdrop: {
      zIndex: themeStyle.zIndex.drawer + 1,
      color: "#fff",
    },
    personIcon: {
      fontSize: "3rem!important",
      boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3) !important",
      borderRadius: "3rem",
      padding: "4px",
    },
    imgSlider: {
      display: "flex",
      justifyContent: "center",
      gap: "1rem",
      alignItems: "center",
    },
    sliderDialogStyle: {
      "& > div": {
        background: "#000000cf",
        "& > div": {
          background: "transparent",
          boxShadow: "none",
          padding: "2rem",
        },
      },
    },
    userMenuButton: {
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      borderRadius: "0!important",
      gap: "0.5rem",
      color: "gray!important",
    },
    userMenuItems: {
      display: "flex",
      gap: "2rem",
      flexWrap: "wrap",
      width: "100%",
      margin: "1rem 0",
    },
    socialIcons: {
      fontSize: "2.5rem!important",
      color:
        userDetails.themeDark === "dark"
          ? "white!important"
          : "black!important",
    },
    footerActiveButton: {
      color: "var(--color-button-background) !important",
      borderRadius: "1.5rem !important",
      fontSize: "1.5rem !important",
      backgroundColor: "transparent!important",
      gap: "1rem !important",
      "& > svg": {
        fill: userDetails.themeDark === "dark" ? "white" : "var(--color-button-background)",
        width: "3rem"
      },
    },
    formStyle: {
      width: "80%",
      display: "grid",
      margin: "1rem 0",
      border: "2px solid #E7E7E7",
      borderRadius: "5px",
      "& > h4": {
        padding: "1rem 3rem",
        backgroundColor: "#F2F2F2",
      },
    },
  }));

  const classes = useStyles();
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({}); 
  const [mainLoader, setMainLoader] = useState(false);
  const [cmtLoad, setCmtLoad] = React.useState(false);
  
  const fetchPostbyidPosts = async () => {
    try {
      if (userDetails === 0) {
        window.location.href = config.aws_cloudfront_lurl;
      }
      const bodyParam = {
        queryStringParameters: {
          schema: config.schema,
          offset: offset,
          limit: 50,
          in_id: userDetails.postDataByid.postid,
          ur_id: userDetails?.uData?.ur_id
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      };

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        `/getpostbyid`,
        bodyParam
      );
           return response;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.data = err;
      return obj;
    }
  };
  const fetchComments = async () => {
    try {
      if (userDetails === 0) {
        window.location.href = config.aws_cloudfront_lurl;
      }
      const bodyParam = {
        queryStringParameters: {
          schema: config.schema,
          offset: offset,
          limit: 50,
          in_id: userDetails.postDataByid.postid,
          ur_id: userDetails?.uData?.ur_id
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      };

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        `/getcommentsbypostid`,
        bodyParam
      );
      console.log('response data:', response);
      
      return response;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.data = err;
      return obj;
    }
  };
  const callAPIFunction = async (buttonType, citem) => { 
    try {
      let obj = {};
      obj.method = 'post';
      obj.body = {};
      obj.body.ur_id = userDetails?.uData?.ur_id;
      obj.body.schema = config.schema;
      obj.body.in_postid = Number(data.body.postid);
      obj.apiName = `/createUpdateComment`;
       if (buttonType === 'comment') { 
         obj.body.in_content = values.comments; 
         obj.body.action = 'create';
         setValues(prevValues => ({
          ...prevValues, // Spread the previous state
          comments: '', // Update the age property
        }))
       } else if (buttonType === 'reply') {
        obj.apiName = `/userSavedPost`;
       } else if (buttonType === 'commentlike') {
        obj.apiName = `/updateCommentLike`;
        obj.body.commentid = Number(citem.cid); 
        obj.body.like = true;
       }
        const res = await callAPI(obj,userDetails);
      hotRefeth();
      commentRefeth();
      return res;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.data = err;
      return obj;
    }
  }
  const { isLoading, error, data, refetch: hotRefeth  } = useQuery({
    queryKey: ["getPostByIdPosts", userDetails?.uData?.ur_id],
    queryFn: fetchPostbyidPosts, // Provide your query function here
  });
  const { isLoading: commentLoad, error: commentErr, data: commentData, refetch: commentRefeth  } = useQuery({
    queryKey: ["getComment", userDetails?.uData?.ur_id],
    queryFn: fetchComments, // Provide your query function here
  });
  
  const handleButtonClick = async (buttonType, citem) => {
    // Define the common logic for button click here
    let res;
    switch (buttonType) {
      case 'comment':
        // Handle comments button click
        setMainLoader(true);
        setCmtLoad(true);
        res = await callAPIFunction(buttonType);
        setValues(prevValues => ({
          ...prevValues, // Spread the previous state
          comments: '', // Update the age property
        }))
       
        setCmtLoad(false);
        setMainLoader(false);
        console.log('comment button clicked');
        break; 
      case 'reply':
        // Handle reply button click
        console.log( "reply");
        res = await callAPIFunction(buttonType);
       
        console.log('reply button clicked');
        break; 
      case 'commentlike':
        // Handle reply button click 
        res = await callAPIFunction(buttonType, citem);
        
        console.log('commentlike button clicked');
        break; 
      default:
        break;
    }
  }; 
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    adaptiveHeight: true,

  };
  const handleSubmit = async (event) => {
    event.preventDefault();

  };

  const images = [
    certificateImage,
    certificateImage,
    certificateImage,
    certificateImage,
    // Add more image URLs as needed
  ];

  return (
    <>
      <SearchHeader />
      <div className={classes.mainLayout}>

        {mainLoader ? (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
        {loading ? (
          <Skeleton className={classes.loaderSkeletonSlider} />
        ) : (
          <div>
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2} style={{ margin: "0", padding: "0" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  style={{ padding: "1rem 1rem 1rem 0rem" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                        height: "auto",
                      },
                    }}
                  >
                    {!isLoading && data && data.body && userDetails.postDataByid.posttypeid === 3 ? (<Paper elevation={3}  className={classes.leftPaper}>
                        <PostHeaderView row={userDetails.postDataByid} classes={classes}   />
                        {userDetails.postDataByid?.json_attachment && <Slider className={classes.postSlider} {...settings1}>
                          {userDetails.postDataByid.json_attachment?.map((item) => (
                            <div className={classes.sliderData}>
                              {item.filetype.includes('video') || item.filetype.includes('pdf') &&
                                <CardMedia
                                  alt="GeeksforGeeks"
                                  component="iframe"
                                  width="100%"
                                  height="315px"
                                  title={item.filename}
                                  allowfullscreen
                                  src={item.filetype.includes('pdf') ? item.ourl + "#navpanes=0&page=1&view=FitH,top" : item.ourl}
                                  className={classes.documentSection}
                                />
                              }
                              {item.filetype.includes('image') &&
                                <img
                                  className={classes.sliderImageFull}
                                  src={item.ourl}
                                  alt=""
                                  onClick={handleClickOpen}
                                />
                              }
                            </div>
                          ))}
                        </Slider>}
                        <Paper
                          component="form"
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            margin: "2rem",
                            borderRadius: "4px",
                            boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
                            color: "gray",
                            fontSize: "1.5rem",
                          }}
                        >
                          <IconButton sx={{ p: "10px" }} aria-label="menu">
                            <Person2Icon
                              style={{ fontSize: "2.5rem", color: "gray" }}
                            />
                          </IconButton>
                         {!cmtLoad && <InputBase
                             multiline
                             rows={2}
                            sx={{ ml: 1, flex: 1, m: 0, fontSize: "1.5rem" }}
                            placeholder="Write your comment..."
                            inputProps={{ "aria-label": "search google maps" }} 
                            onChange={(e)=>  setValues(prevValues => ({
                              ...prevValues, // Spread the previous state
                              comments: e.target.value, // Update the age property
                            }))
                          }
                          />}
                          {cmtLoad && <Skeleton className={classes.cmtloaderSkeletonSlider} />}
                            <div style={{ marginTop: "5rem", }}>
                            <IconButton>
                              <Button
                              onClick={()=>handleButtonClick('comment')}
                                style={{
                                  backgroundColor: "var(--color-button-background)",
                                  borderRadius: "2rem",
                                  color: "white",
                                  padding: "5px 12px",
                                  fontWeight: "400",
                                  width: "100%",
                                  fontSize: "1.5rem",
                                  textTransform: "capitalize",
                                  justifyContent: "space-between",
                                  gap: "1rem",
                                }}
                              >
                                Post
                                <SendIcon className={classes.sendButton} />
                              </Button>
                            </IconButton>
                          </div>
                        </Paper>
                        <PostFooterView row={data?.body} classes={classes} 
                        hotRefeth={hotRefeth} userDetails={userDetails}/>
                      </Paper>) : userDetails.postDataByid.posttypeid === 2 ? (<Paper elevation={3} className={classes.leftPaperBottom}>
                        <PostHeaderView row={userDetails.postDataByid} classes={classes}   />
                        <div
                          style={{
                            display: "block",
                            margin: "2rem 0rem",
                            textDecoration: "none",
                          }}
                        >
                          <p style={{ marginBottom: "2rem" }}>
                            {userDetails.postDataByid.content}
                          </p>
                        </div>
                        <Paper
                          component="form"
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "4px",
                            boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
                            color: "gray",
                            fontSize: "1.5rem",
                          }}
                        >
                          <IconButton sx={{ p: "10px" }} aria-label="menu">
                            <Person2Icon
                              style={{ fontSize: "2.5rem", color: "gray" }}
                            />
                          </IconButton>
                          {!cmtLoad && <InputBase
                             multiline
                             rows={2}
                            sx={{ ml: 1, flex: 1, m: 0, fontSize: "1.5rem" }}
                            placeholder="Write your comment..."
                            inputProps={{ "aria-label": "search google maps" }}
                            onChange={(e)=>  setValues(prevValues => ({
                              ...prevValues, // Spread the previous state
                              comments: e.target.value, // Update the age property
                            }))
                          }
                          />}
                          {cmtLoad && <Skeleton className={classes.cmtloaderSkeletonSlider} />}
                          <div style={{ marginTop: "5rem", }}>
                            <IconButton>
                              <Button
                              onClick={()=>handleButtonClick('comment')}
                                style={{
                                  backgroundColor: "var(--color-button-background)",
                                  borderRadius: "2rem",
                                  color: "white",
                                  padding: "5px 12px",
                                  fontWeight: "400",
                                  width: "100%",
                                  fontSize: "1.5rem",
                                  textTransform: "capitalize",
                                  justifyContent: "space-between",
                                  gap: "1rem",
                                }}
                              >
                                Post
                                <SendIcon className={classes.sendButton} />
                              </Button>
                            </IconButton>
                          </div>
                        </Paper>
                        <PostFooterView row={data?.body} classes={classes}   hotRefeth={hotRefeth} userDetails={userDetails}
                        page='inDetails' />
                      </Paper>) : userDetails.postDataByid.posttypeid === 1 ? (<Paper elevation={3} className={classes.leftPaperBottom}>
                        <PostHeaderView row={data?.body} classes={classes}  userDetails={userDetails} 
                         hotRefeth={hotRefeth} refetch={hotRefeth} setMainLoader={setMainLoader} />
                        <p>{userDetails.postDataByid.content}</p>
                        <div className={classes.formStyle}>
                        
                          <Polling row={userDetails.postDataByid}   userDetails={userDetails} 
                          hotRefeth={hotRefeth} refetch={hotRefeth} setMainLoader={setMainLoader}/>
                        </div>
                        <Paper
                          component="form"
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            margin: "2rem",
                            borderRadius: "4px",
                            boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
                            color: "gray",
                            fontSize: "1.5rem",
                          }}
                        >
                          <IconButton sx={{ p: "10px" }} aria-label="menu">
                            <Person2Icon
                              style={{ fontSize: "2.5rem", color: "gray" }}
                            />
                          </IconButton>
                          {!cmtLoad && <InputBase
                             multiline
                             rows={2}
                            sx={{ ml: 1, flex: 1, m: 0, fontSize: "1.5rem" }}
                            placeholder="Write your comment..."
                            inputProps={{ "aria-label": "search google maps" }} 
                            onChange={(e)=>  setValues(prevValues => ({
                              ...prevValues, // Spread the previous state
                              comments: e.target.value, // Update the age property
                            }))
                          }
                          />}
                          {cmtLoad && <Skeleton className={classes.cmtloaderSkeletonSlider} />}
                            <div style={{ marginTop: "5rem", }}>
                            <IconButton>
                              <Button
                              onClick={()=>handleButtonClick('comment')}
                                style={{
                                  backgroundColor: "var(--color-button-background)",
                                  borderRadius: "2rem",
                                  color: "white",
                                  padding: "5px 12px",
                                  fontWeight: "400",
                                  width: "100%",
                                  fontSize: "1.5rem",
                                  textTransform: "capitalize",
                                  justifyContent: "space-between",
                                  gap: "1rem",
                                }}
                              >
                                Post
                                <SendIcon className={classes.sendButton} />
                              </Button>
                            </IconButton>
                          </div>
                        </Paper>
                        <PostFooterView row={data?.body} classes={classes} userDetails={userDetails}
                          hotRefeth={hotRefeth} history={history}/>
                        </Paper>) : (<></>)
                     }
                  </Box>

                  {/* Comment Section */}
                 {!commentLoad && commentData && commentData?.body.length > 0 && <div style={{ display: "flex", justifyContent: "space-between", margin: "2rem 0" }}>
                    <h3>Comments</h3>
                    <Button style={{ display: "flex", alignItems: "center", gap: "8px", color: "gray", fontSize: "1.5rem", textTransform: "none" }}>
                      <p>Most recent</p><ArrowDropDownIcon fontSize="large" />
                    </Button>
                  </div>}
                  {/* First Comment */}
                 {commentLoad && <Skeleton className={classes.loaderSkeletonSlider} />}
                 {!commentLoad && commentData && commentData?.body.length > 0 && <Paper elevation={3} className={classes.leftPaperBottom} style={{ padding: 0 }}>
                    { commentData && commentData?.body?.map((item)=>(
                    <>
                     <div style={{ padding: "2rem", borderTop: "3px solid #F2F2F2" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <Person2Icon style={{ fontSize: "2.5rem" }} />
                            <h4>{item.full_name}</h4>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <AccessTimeIcon />
                          <p>{item.commentage}d</p>
                        </div>
                      </div>
                      <p style={{ margin: "2rem 0" }}>{item.content}</p>
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "4px",
                          boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
                          color: "gray",
                          fontSize: "1.5rem",
                        }}
                      >
                        <IconButton sx={{ p: "10px" }} aria-label="menu">
                          <Person2Icon
                            style={{ fontSize: "2.5rem", color: "gray" }}
                          />
                        </IconButton>
                        <InputBase
                          sx={{ ml: 1, flex: 1, m: 0, fontSize: "1.5rem" }}
                          placeholder="Write your comment..."
                          inputProps={{ "aria-label": "search google maps" }}
                        />
                      </Paper>
                      <div className={classes.userMenuItems}>
                        <Button className={classes.userMenuButton}
                        onClick={()=>{
                          console.log("itemitem",item) 
                          handleButtonClick('commentlike', item);
                        }} >
                          <FavoriteBorderOutlinedIcon
                            className={classes.socialIcons}
                          />
                          {item.likes}k
                        </Button>
                        <div>
                          <Button className={classes.userMenuButton} style={{ borderRight: "1px solid gray" }}>
                            <ReplyOutlinedIcon
                              className={classes.socialIcons}
                            />
                            Reply
                          </Button>
                          <Button className={classes.userMenuButton}>
                             Reply
                          </Button>
                          
                        </div>
                      </div>
                    </div>
                     

                    </>
                   
                   )) }  

                  </Paper>}

                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  className={classes.secondGridResponsive}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                        height: "auto",
                      },
                    }}
                  >
                    <h4 style={{ margin: 0, }}>Topic :</h4>
                    <Paper elevation={3} className={classes.rightPaper1}>
                      <Button className={classes.myPostButton}>
                        <div>
                          <img src={certificateImage} style={{ width: "10rem", height: "8rem", }} />
                          <h5>{data?.body.topic_title}</h5>
                        </div>
                      </Button>
                    </Paper>
                    <h4 style={{ margin: "1rem 0 0", }}>Other posts by similar topics</h4>
                    <Paper elevation={3} className={classes.rightPaper}>
                      <div style={{ padding: "1rem" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: "1rem" }}>
                          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <Person2Icon className={classes.personIcon} />
                            <h4>User_Name 1</h4>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                            <AccessTimeOutlinedIcon /><p>1day</p>
                          </div>
                        </div>
                        <p>User_ID_Name</p>
                      </div>
                    </Paper>
                    <Paper elevation={3} className={classes.rightPaper}>
                      <div style={{ padding: "1rem" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: "1rem" }}>
                          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <Person2Icon className={classes.personIcon} />
                            <h4>User_Name 1</h4>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                            <AccessTimeOutlinedIcon /><p>1day</p>
                          </div>
                        </div>
                        <p>User_ID_Name</p>
                      </div>
                    </Paper>
                    <Paper elevation={3} className={classes.rightPaper}>
                      <div style={{ padding: "1rem" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: "1rem" }}>
                          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <Person2Icon className={classes.personIcon} />
                            <h4>User_Name 1</h4>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                            <AccessTimeOutlinedIcon /><p>1day</p>
                          </div>
                        </div>
                        <p>User_ID_Name</p>
                      </div>
                    </Paper>

                    <Paper elevation={3} className={classes.rightPaper}>
                      <h4 style={{ padding: "1rem" }}>similar to this post</h4>
                      <div style={{ padding: "1rem", borderBottom: "1px solid gray" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: "1rem" }}>
                          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <Person2Icon className={classes.personIcon} />
                            <h4>User_Name</h4>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                            <AccessTimeOutlinedIcon /><p>1d</p>
                          </div>
                        </div>
                        <div style={{ padding: "0rem 4rem" }}>
                          <h4>User_ID_Name</h4>
                          <img src={certificateImage} style={{ width: "100%", height: "8rem", borderRadius: "1rem" }} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <div className={classes.userMenuItems} style={{ width: "100%", justifyContent: "left" }}>
                            <Button className={classes.userMenuButton}>
                              <FavoriteBorderOutlinedIcon
                                className={classes.socialIcons}
                              />
                              10k
                            </Button>
                            <Button className={classes.userMenuButton}>
                              <ChatBubbleOutlineOutlinedIcon
                                className={classes.socialIcons}
                              />
                              1k
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div style={{ padding: "1rem", borderBottom: "1px solid gray" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: "1rem" }}>
                          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <Person2Icon className={classes.personIcon} />
                            <h4>User_Name</h4>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                            <AccessTimeOutlinedIcon /><p>1d</p>
                          </div>
                        </div>
                        <div style={{ padding: "0rem 4rem" }}>
                          <h4>User_ID_Name</h4>
                          <img src={certificateImage} style={{ width: "100%", height: "8rem", borderRadius: "1rem" }} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <div className={classes.userMenuItems} style={{ width: "100%", justifyContent: "left" }}>
                            <Button className={classes.userMenuButton}>
                              <FavoriteBorderOutlinedIcon
                                className={classes.socialIcons}
                              />
                              10k
                            </Button>
                            <Button className={classes.userMenuButton}>
                              <ChatBubbleOutlineOutlinedIcon
                                className={classes.socialIcons}
                              />
                              1k
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </div>
        )}


        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="xl"
          className={classes.sliderDialogStyle}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon style={{ fontSize: "4rem", color: "lightgray" }} />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom>
              <div>
                <ImageSlider images={images} />
               
              </div>
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
export default SocialLearning;
